import React from 'react';
import Sidebar from '../Components/Sidebar';
import TopCard from '../Components/TopCard';
import UpdateVersionHook from '../Hooks/UpdateVersionHook';
import { MdManageHistory } from 'react-icons/md';
import ButtonLoadingSpinner from '../Components/ButtonLoadingSpinner/ButtonLoadingSpinner';

const UpdateVersion = () => {
  const [
    inputValues,
    inputsOnChangeHandler,
    inputsOnKeyDownHandler,
    updateVersionOnclickHandler,
    currentVersionRef,
    requiredVersionRef,
    loading,
  ] = UpdateVersionHook();
  return (
    <section>
      <Sidebar />
      <main>
        <TopCard title="Update Version" />
        <div className="flex justify-center items-center py-6">
          <div className="divide-y-2 flex justify-center items-center md:w-1/2 w-fit bg-white border shadow-lg rounded-xl p-7 flex-col">
            <header className="flex justify-center items-center mb-2">
              <MdManageHistory className="text-6xl text-blue-500" />

              <h1 className="text-2xl font-bold text-gray-700">Update Version</h1>
            </header>

            <form className="pt-2 flex flex-wrap h-fit flex-col w-full">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="inline-current-version">
                  Current Version
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:bg-white focus:border-blue-500 border-2 border-gray-200"
                  id="inline-current-version"
                  type="text"
                  name="currentVersion"
                  placeholder="Current Version"
                  value={inputValues.currentVersion}
                  onChange={inputsOnChangeHandler}
                  onKeyDown={inputsOnKeyDownHandler}
                  ref={currentVersionRef}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="inline-required-version">
                  Required Version
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:bg-white focus:border-blue-500 border-2 border-gray-200"
                  id="inline-required-version"
                  type="text"
                  name="requiredVersion"
                  placeholder="Required Version"
                  value={inputValues.requiredVersion}
                  onChange={inputsOnChangeHandler}
                  onKeyDown={inputsOnKeyDownHandler}
                  ref={requiredVersionRef}
                />
              </div>
              <div className="flex items-center justify-center w-full">
                <button
                  className="relative shadow bg-blue-500 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                  type="button"
                  onClick={updateVersionOnclickHandler}
                  disabled={loading}
                >
                  <span className={`${loading && 'invisible'}`}>Update</span>
                  <ButtonLoadingSpinner loading={loading} topPosition='11px' leftPosition='31px' />
                </button>
              </div>
            </form>
          </div>
        </div>
      </main>
    </section>
  );
};

export default UpdateVersion;
