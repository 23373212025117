import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ToastContext from '../Context/ToastContext';
import { acceptLoanRequest, getLoanRequest, getPayments, rejectLoanRequest } from '../APIs/LoanAPIs';

const useLoanRequest = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [payments, setPayments] = useState([]);
  const { addToasts } = useContext(ToastContext);
  const {
    state: { id },
  } = useLocation();

  const onAccept = async () => {
    try {
      await acceptLoanRequest(id);
      getData();
    } catch (err) {
      addToasts({
        type: 'danger',
        body: 'unexpected error',
      });
    }
  };

  const onReject = async () => {
    try {
      await rejectLoanRequest(id, { cause: data.cause });
      getData();
    } catch (err) {
      addToasts({
        type: 'danger',
        body: 'unexpected error',
      });
    }
  };

  const getPayment = async () => {
    try {
      const { data } = await getPayments(id);
      setPayments(data?.data?.payments);
    } catch (error) {
      addToasts({
        type: 'danger',
        body: 'unexpected error',
      });
    }
  };

  const getData = async () => {
    try {
      setLoading(true);
      const { data } = await getLoanRequest(id);
      setData(data.data.loanRequest);
      setLoading(false);
    } catch (err) {
      addToasts({
        type: 'danger',
        body: 'unexpected error',
      });
    }
  };

  useEffect(() => {
    getData();
    getPayment();
  }, []);

  return {
    payments,
    loading,
    data,
    onAccept,
    onReject,
  };
};

export default useLoanRequest;
