import api from './_common/helper';

export async function searchProducts(data) {
  return api.get(`/product/search?skip=${data.skip}&search=${data.text}&isUsed=${data.isUsed}`);
}

export async function getProducts(data) {
  return api.get(`/product?skip=${data.skip}&isUsed=${data.isUsed}`);
}

export async function getProduct(data) {
  return api.get(`/product/${data}`);
}

export async function getProductReviews(id) {
  return api.get(`/product/${id}/review`);
}

export async function addProduct(data) {
  return api.post('/product', data);
}

export async function updateProduct(id, data) {
  return api.put(`/product/${id}`, data);
}
export async function acceptProduct(productId) {
  return api.put(`/product/${productId}/accepted`);
}

export async function rejectProduct(productId) {
  return api.put(`/product/${productId}/rejected`);
}

export async function deleteProduct(id) {
  return api.delete(`/product/${id}`);
}

export async function getProductOrders(data) {
  return api.get(`/product/order?skip=${data.skip}&status=${data.status}`);
}

export async function searchOrders(data) {
  return api.get(`/product/order/search?skip=${data.skip}&query=${data.text}&status=${data.status}`);
}

export async function getProductCategories() {
  return api.get(`/category`);
}

export async function addProductTags(data) {
  return api.post(`/tags`, data);
}
