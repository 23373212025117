import { useContext, useEffect, useState } from 'react';
import ToastContext from '../Context/ToastContext';
import { useLocation } from 'react-router-dom';
import { getOrderById } from '../APIs/OrderAPIs';

const OrderHook = () => {
  const { addToasts } = useContext(ToastContext);
  const {
    state: { id },
  } = useLocation();

  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState('To do');
  // const isValid = orderStatus !== order.status;

  const getOrderData = async () => {
    setLoading(true);
    getOrderById(id)
      .then(res => {
        if (res.status === 200) {
          const { order } = res.data.data;
          setOrder(order);
          setOrderStatus(order.status);
        }
      })
      .catch(err => {
        console.error(err);
        addToasts({
          body: `${err.response.data.msg || 'something went wrong, please try again later'}`,
          type: 'danger',
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getOrderData();
    // eslint-disable-next-line
  }, []);

  return [order, loading];
};

export default OrderHook;
