import { useContext, useEffect, useState, useCallback } from 'react';
import { getReels } from '../../APIs/ReelsAPI';
import ToastContext from '../../Context/ToastContext';

function useReels() {
  const { addToasts } = useContext(ToastContext);
  const [reels, setReels] = useState([]);
  const [fetchedReelsCount, setFetchedReelsCount] = useState(0); // New state
  const [isMaxReached, setIsMaxReached] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const popReel = reelId => {
    setReels(prevReels => {
      const updatedReels = prevReels.filter(reel => reel.id !== reelId);
      return updatedReels;
    });
  };

  const fetchReels = useCallback(async skip => {
    try {
      const reels = await getReels(skip);
      setIsFetching(false);
      if (reels.data.data.reels.length === 0) {
        setIsMaxReached(true);
        return;
      }
      setReels(prevReels => [...prevReels, ...reels.data.data.reels]);
      setFetchedReelsCount(skip + reels.data.data.reels.length); // Update the count
    } catch (error) {
      console.error(error);
      addToasts({
        type: 'danger',
        body: `${error.response.data?.msg || 'something went wrong, please try again later'}`,
      });
    }
  }, []);

  useEffect(() => {
    setIsFetching(true);
    fetchReels(0);
  }, [fetchReels]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && !isFetching && !isMaxReached) {
          setIsFetching(true);
          fetchReels(fetchedReelsCount); // Fetch starting from the total number of fetched reels
        }
      },
      { rootMargin: '300px' },
    );

    const sentinel = document.querySelector('#scroll-sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [fetchedReelsCount, isFetching, isMaxReached, fetchReels]); // Add fetchedReelsCount to the dependency array

  return { reels, isFetching, popReel };
}

export default useReels;
