function VideoEngagement({ children, count }) {
  return (
    <div className="flex items-center flex-col gap-0.5">
      {children}
      <span className="text-white text-sm">{count > 1000 ? (count / 1000).toFixed(1) + 'K' : count}</span>
    </div>
  );
}

export default VideoEngagement;
