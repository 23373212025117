import { useNavigate } from 'react-router-dom';
import { useContext, useState, useCallback, useEffect } from 'react';
import ToastContext from '../Context/ToastContext';
import { getCourses, searchCourses } from '../APIs/CourseAPIs';
import config from '../Config/Config';

const CoursesHook = () => {
  const navigate = useNavigate();
  const { addToasts } = useContext(ToastContext);

  const paidStatusOptions = [
    { key: 'Free', text: 'Free', value: 'Free' },
    { key: 'Paid', text: 'Paid', value: 'Paid' },
  ];
  const categoryOptions = [
    { key: 'Engineering', text: 'Engineering', value: 'Engineering' },
    { key: 'Medicine', text: 'Medicine', value: 'Medicine' },
    { key: 'Business', text: 'Business', value: 'Business' },
    { key: 'Computer Science', text: 'Computer Science', value: 'Computer Science' },
    { key: 'Art', text: 'Art', value: 'Art' },
    { key: 'Design', text: 'Design', value: 'Design' },
    { key: 'Marketing', text: 'Marketing', value: 'Marketing' },
    { key: 'Self Development', text: 'Self Development', value: 'Self Development' },
    { key: 'Languages', text: 'Languages', value: 'Languages' },
    { key: 'Others', text: 'Others', value: 'Others' },
  ];

  const [courseFilter, setCourseFilter] = useState('default');
  const [searchCoursesFilter, setSearchCoursesFilter] = useState({
    paidStatus: 'Free',
    category: 'Engineering',
  });
  const [input, setInput] = useState('');
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [maxCount, setMaxCount] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  const [timeoutId, setTimeoutId] = useState(null);

  const handleSelectCourse = e => {
    navigate('/courses/course', {
      state: { courseId: e.target.id },
    });
  };

  const addCourseHandler = () => {
    navigate('/courses/addcourse', {
      state: { sections: null, c: null },
    });
  };

  const handleInputChange = e => {
    setSearchData([]);
    setInput(e.target.value);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (e.target.value.length === 0) {
      fetchCoursesData();
      return;
    }

    const newTimeoutId = setTimeout(() => {
      searchCoursesData(e.target.value);
    }, 500);

    setTimeoutId(newTimeoutId);
  };

  const selectCourseFilterHandler = e => {
    if (e.target.id === courseFilter) return;

    // reset data and search data when changing requests type to avoid duplicated data
    setData([]);
    setSearchData([]);

    setCourseFilter(e.target.id);
  };

  const searchCoursesFilterHandler = (_, data) => {
    const { name, value } = data;
    setSearchData([]);
    setSearchCoursesFilter(prevState => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const fetchCoursesData = useCallback(async () => {
    const coursesQuery = {
      skip: data?.length || 0,
      filter: courseFilter,
    };
    if (!data.length) setLoading(true);

    setSearchData([]);
    setIsFetching(true);

    getCourses(coursesQuery)
      .then(res => {
        if (res.status === 200) {
          const { courses, numberOfCourses } = res.data.data;
          setMaxCount(numberOfCourses);
          if (!data.length) {
            setData(courses);
            return;
          }
          setData(prevState => [...prevState, ...courses]);
        }
      })
      .catch(err => {
        if (config.MODE === 'development') console.error(err);
        addToasts({
          type: 'danger',
          body: `${err.response.data?.msg || 'something went wrong, please try again later'}`,
        });
      })
      .finally(() => {
        setIsFetching(false);
        if (!data.length) setLoading(false);
      });
  }, [addToasts, data.length, courseFilter]);

  const searchCoursesData = useCallback(
    async searchText => {
      const searchQuery = {
        skip: searchData.length || 0,
        text: searchText || '',
        category: searchCoursesFilter.category,
        is_free: searchCoursesFilter.paidStatus === 'Free' ? true : false,
      };
      if (!searchData.length) setLoading(true);
      setData([]);
      setIsFetching(true);

      searchCourses(searchQuery)
        .then(res => {
          const { courses, numberOfCourses } = res.data.data;
          setMaxCount(numberOfCourses);
          if (searchData.length === 0) {
            setSearchData(courses);
            return;
          }
          setSearchData(prevState => [...prevState, ...courses]);
        })
        .catch(err => {
          if (config.MODE === 'development') console.error(err);
          addToasts({
            body: `${err.response.data.msg || 'something went wrong, please try again later'}`,
            type: 'danger',
          });
        })
        .finally(() => {
          setIsFetching(true);
          if (!searchData.length) setLoading(false);
        });
    },
    [addToasts, searchData.length, searchCoursesFilter],
  );

  // fetch data on page load and on search
  useEffect(() => {
    // fetch data on page load
    if (!input) {
      setSearchData([]);
      fetchCoursesData();
      return;
    }
    // fetch data on search
    setData([]);
    searchCoursesData(input);
    // eslint-disable-next-line
  }, [courseFilter]);

  // search data on dropdowns change
  useEffect(() => {
    searchCoursesData(input);
    // eslint-disable-next-line
  }, [searchCoursesFilter]);

  //  handle scroll
  useEffect(() => {
    const isReachedEndOfList = (dataLength, searchDataLength, maxCount) => {
      return dataLength >= maxCount || searchDataLength >= maxCount;
    };
    const handleScroll = () => {
      const windowInnerHeight = window.innerHeight;
      const documentElementScrollTop = document.documentElement.scrollTop;
      const documentElementOffsetHeight = document.documentElement.offsetHeight;
      const scroll = windowInnerHeight + documentElementScrollTop;
      const offset = documentElementOffsetHeight - 200;
      const dataLength = data.length;
      const searchDataLength = searchData.length;

      if (scroll >= offset && !isReachedEndOfList(dataLength, searchDataLength, maxCount) && !isFetching) {
        window.removeEventListener('scroll', handleScroll);
        // if there is no search input, fetch meals data
        if (!input) {
          fetchCoursesData();
          return;
        }
        // if there is search input, fetch search data
        searchCoursesData(input);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [data, searchData, maxCount, input, fetchCoursesData, searchCoursesData, isFetching]);

  return [
    input,
    data,
    searchData,
    loading,
    handleInputChange,
    searchCoursesFilter,
    searchCoursesFilterHandler,
    paidStatusOptions,
    categoryOptions,
    courseFilter,
    selectCourseFilterHandler,
    addCourseHandler,
    handleSelectCourse,
  ];
};

export default CoursesHook;
