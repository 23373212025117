import Sidebar from '../Components/Sidebar';
import '../Styles/ThePost.css';
import cursor from '../Assets/arrowframe.png';
import love from '../Assets/love.png';
import person from '../Assets/persons.png';
import deleteIcon from '../Assets/vector (5).png';
import comment from '../Assets/u_comment.png';
import { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPost, respondePost } from '../APIs/PostAPIs';
import ToastContext from '../Context/ToastContext';
import LoadingSpinner from '../Components/LoadingSpinner';

export default function ProfilePost() {
  const navigate = useNavigate();
  const {
    state: { id },
  } = useLocation();
  const [data, setData] = useState({});
  const handleBack = () => {
    navigate(-1); // Navigate back one page
  };
  const { addToasts } = useContext(ToastContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const post = await getPost(id);
        if (post.status !== 200) {
          addToasts({
            type: 'danger',
            body: 'network error',
          });
        }
        const data = await post.json();
        setData(data.post);
        console.log(data.post);
        setLoading(false);
      } catch (err) {
        addToasts({
          type: 'danger',
          body: 'unexpected error',
        });
      }
    })();
  }, []);

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <Sidebar></Sidebar>
          <div className="Card0">a</div>
          {
            <div className="thePostCards">
              <div className="thePostFirstCard">
                <div className="postAndBack">
                  <div>
                    <img
                      onClick={handleBack}
                      className="cursorIcon"
                      src={cursor}
                      height={45}
                      width={45}
                      alt="image"
                    ></img>
                    <div>Post</div>
                  </div>
                </div>
              </div>
              <div className="postCard">
                <div className="thePost">
                  <div>
                    <div
                      id={data.user.id}
                      onClick={e =>
                        navigate('/profiles/profile', {
                          state: { id: e.target.id },
                        })
                      }
                      style={{ cursor: 'pointer', width: '10%' }}
                      className="profileInTop"
                    >
                      <div id={data.user.id}>
                        <img
                          id={data.user.id}
                          src={data.user.avatar}
                          width={40}
                          height={40}
                          style={{ borderRadius: '50%' }}
                          alt="image"
                        ></img>{' '}
                      </div>
                      <div id={data.user.id}>
                        <div id={data.user.id}>{data.user?.name}</div>
                        <div id={data.user.id} className="text-dark_gray">
                          {data.user?.email}
                        </div>
                      </div>
                    </div>
                    <div style={{ textAlign: 'right' }} className="text-dark_gray">
                      {data.date}
                    </div>
                  </div>

                  <div className="caption">{data.text}</div>
                  <div className="postImages">
                    {data.images?.map(img => {
                      <img src={img} alt="text"></img>;
                    })}
                  </div>
                  <div className="reactionsEditDelete">
                    <div className="reactions">
                      <div>
                        <img src={love} alt="" height={20} width={20}></img>
                        <span>{data.likes.length}</span>
                      </div>

                      <div>
                        <img src={comment} alt="" height={20} width={20}></img>
                        <span>{data.comments.length}</span>
                      </div>

                      <div>
                        <img src={person} alt="" height={20} width={20}></img>
                        <span>{data.views.length} Views</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="likesCard">
                <div className="text-dark_gray">Likes ({data.likes.length})</div>
                <div className="thePostProfiles">
                  {data.likes?.map(like => {
                    return (
                      <div
                        id={like._id}
                        onClick={e =>
                          navigate('/profiles/profile', {
                            state: { id: e.target.id },
                          })
                        }
                        style={{ cursor: 'pointer' }}
                        className="thePostProfile"
                      >
                        <div id={like._id} className="profileInTop">
                          <div id={like._id}>
                            <img
                              id={like._id}
                              src={like.avatar}
                              width={40}
                              height={40}
                              alt="image"
                              style={{ borderRadius: '50%' }}
                            ></img>{' '}
                          </div>
                          <div id={like._id}>
                            <div id={like._id}>{like.name}</div>
                            <div id={like._id} className="text-dark_gray">
                              {like.email}
                            </div>
                          </div>
                        </div>
                        <div id={like._id} className="profileWord">
                          Profile...
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="commentsCard">
                <div className="text-dark_gray commentNumber">Comments ({data.comments.length})</div>
                {data.comments?.map(comment => {
                  return (
                    <div className="comment" style={{ marginTop: '10px' }}>
                      <div className="accountAndTime">
                        <div>
                          <div>
                            <img
                              src={comment.user.avatar}
                              width={40}
                              height={40}
                              alt="image"
                              style={{ borderRadius: '50%' }}
                            ></img>{' '}
                          </div>
                          <div>
                            <div>{comment.user.name}</div>
                            <div className="text-dark_gray">{comment.user.email}</div>
                          </div>
                        </div>
                        <div
                          className="text-dark_gray"
                          style={{
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '12px',
                          }}
                        >
                          {' '}
                          {comment.createdAt}
                        </div>
                      </div>
                      <div>{comment.text}</div>
                      <img src={data.comments.photo} alt="image"></img>
                      <div className="reactAndDelete">
                        <div>
                          <img src={love} alt="image"></img>
                          <div>(1)</div>
                        </div>
                        <div>
                          <img src={deleteIcon} alt="image"></img>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          }
        </div>
      )}
    </div>
  );
}
