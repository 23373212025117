import React from 'react';

function UserRoleBadge({ role, fontSize = 'md' }) {
  const fontSizeOptions = {
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-md',
    lg: 'text-lg',
    xl: 'text-xl',
  };
  const fontSizeClass = fontSizeOptions[fontSize];
  const defaultClass = `font-semibold me-2 px-2.5 py-0.5 rounded text-center`;
  const roleClasses = {
    STUDENT: `bg-green-100 text-green-800`,
    PROFESSOR: `bg-yellow-100 text-yellow-800`,
    ADMIN: `bg-red-100 text-red-800`,
    SUPPORT: `bg-lime-100 text-lime-800`,
    GRADUATE: `bg-cyan-100 text-cyan-800`,
    OTHER: `bg-blue-100 text-blue-800`,
  };

  const roleClass = roleClasses[role] || roleClasses.OTHER;

  return <span className={`${defaultClass} ${roleClass} ${fontSizeClass}`}>{role}</span>;
}

export default UserRoleBadge;
