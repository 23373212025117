import '../Styles/ChangeVccPrice.css';
import AsyncSelect from 'react-select/async';
import NotificationSenderHook from '../Hooks/NotificationSenderHook';
import Layout from '../Components/_common/Layout';
import UserRoleBadge from '../Components/_common/UserRoleBadge';
export default function NotificationSender() {
  const [
    setSelectedOptions,
    selectedUsers,
    text,
    onClick,
    arabicTextOnChangeHandler,
    englishTextOnChangeHandler,
    userSearchOptions,
  ] = NotificationSenderHook();

  return (
    <Layout title={'Send Notification'}>
      <main className="w-full md:w-1/2 bg-white rounded-3xl mx-auto my-10 p-5 flex flex-col gap-5">
        <label htmlFor="vccPrice">Send to</label>
        <div className="category">
          <AsyncSelect
            name="Users"
            isMulti
            cacheOptions
            loadOptions={userSearchOptions}
            defaultOptions={[{ value: 'All', label: 'All' }]}
            isSearchable={true}
            onChange={setSelectedOptions}
            value={selectedUsers}
            placeholder="Select an option"
            formatOptionLabel={option =>
              option.label === 'All' ? (
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <span className="mx-3">All Users</span>
                  </div>
                  <span className="text-gray-500">Send to all users</span>
                </div>
              ) : (
                <div className="flex items-center justify-between">
                  <div className="flex items-center justify-start">
                    <img
                      src={option.avatar}
                      alt={option.label}
                      className="w-12 h-12 rounded-full"
                      crossOrigin="anonymous"
                    />
                    <p className="mx-3 flex items-start justify-center flex-col">
                      <span className="text-lg font-semibold">{option.label}</span>
                      <span className="text-sm text-gray-500">@{option.username}</span>
                    </p>
                  </div>
                  <UserRoleBadge role={option.role} fontSize="sm" />
                </div>
              )
            }
          />
        </div>
        <label htmlFor="textAr">Arabic Text</label>
        <input
          className="border-2 p-2 rounded-xl"
          dir="rtl"
          value={text.ar}
          onChange={arabicTextOnChangeHandler}
          id="textAr"
          placeholder="نص الإشعار"
        />
        <label htmlFor="textEn">English Text</label>
        <input
          className="border-2 p-2 rounded-xl"
          value={text.en}
          onChange={englishTextOnChangeHandler}
          id="textEn"
          placeholder="Notification Text"
        />
        <button
          disabled={text.ar.length === 0 || text.en.length === 0 || selectedUsers.length === 0}
          onClick={onClick}
          className="addButton"
        >
          Send
        </button>
      </main>
    </Layout>
  );
}
