import React, { useContext } from 'react';
import './TopCard.css';
import cursor from '../Assets/arrowframe.png';
import { useNavigate } from 'react-router-dom';
import edit from '../Assets/edit.png';
import addIcon from '../Assets/Frame 48095608 (1).png';
export default function TopCard({ title, data, iconTwo, titleTwo, iconThree, navigateLink, className }) {
  const navigate = useNavigate();

  const handleEditEvent = () => {
    navigate(navigateLink, {
      state: { data },
    });
  };
  const handleBack = () => {
    navigate(-1);
  };


  return (
    <div className="firsts">
      <div className={`topCard ${className}`}>
        <div>
          <img onClick={handleBack} className="cursorIcon" src={cursor} height={45} width={45} alt="image"></img>
          <div>{title}</div>
        </div>
        <div>
          <div>{titleTwo}</div>
          {iconTwo && (
            <img
              src={edit}
              height={45}
              width={45}
              alt="image"
              style={{ cursor: 'pointer' }}
              onClick={handleEditEvent}
            ></img>
          )}
          {iconThree && (
            <img
              src={addIcon}
              height={45}
              width={45}
              alt="image"
              style={{ cursor: 'pointer' }}
              onClick={handleEditEvent}
            ></img>
          )}
          
        </div>
      </div>
    </div>
  );
}
