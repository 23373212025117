// TransactionAmount.jsx
function TransactionAmount({ transaction, user_id }) {
  return (
    <div className="flex justify-between items-center">
      <p className={`font-bold text-lg mb-2 ${transaction.sender_id === user_id ? 'text-red-500' : 'text-green-500'}`}>
        {transaction.sender_id === user_id ? '-' : '+'}
        {transaction.amount} EGP
      </p>
    </div>
  );
}

export default TransactionAmount;
