import { useState, useEffect } from 'react';

const useTimeCollapsed = message => {
  const [timeCollapsed, setTimeCollapsed] = useState('');

  useEffect(() => {
    const updateTimeCollapsed = () => {
      const currentTime = new Date();
      const messageTime = new Date(message.timestamp);
      const diffInMinutes = Math.floor((currentTime - messageTime) / 60000);
      if (diffInMinutes < 1) setTimeCollapsed('Just now');
      else if (diffInMinutes <= 60) setTimeCollapsed(`${diffInMinutes} min`);
      else if (diffInMinutes <= 1440) setTimeCollapsed(`${Math.floor(diffInMinutes / 60)} hr`);
      else setTimeCollapsed(`${Math.floor(diffInMinutes / 1440)} days`);
    };
    const interval = setInterval(() => {
      updateTimeCollapsed();
    }, 60000); // update every minute
    updateTimeCollapsed(); // initial update
    return () => clearInterval(interval); // cleanup on unmount
  });

  return timeCollapsed;
};

export default useTimeCollapsed;
