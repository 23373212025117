import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ToastContext from '../Context/ToastContext';
import { getWallet, getUser, editWalletBalance } from '../APIs/UserAPIs';
import { getUserNotifications } from '../APIs/NotificationAPIs';
import { getUserTransactions } from '../APIs/TransactionAPI';

function useProfileWallet() {
  const [wallet, setWallet] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [userData, setUserData] = useState({});
  const [addValue, setAddValue] = useState(0);
  const [withdrawValue, setWithdrawValue] = useState(0);
  const { addToasts } = useContext(ToastContext);
  const [loading, setLoading] = useState(true);
  const {
    state: { id },
  } = useLocation();
  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      setLoading(true);
      const [user, userWallet, userNotifications, userTransactions] = await Promise.all([
        getUser(id),
        getWallet(id),
        getUserNotifications({ id, skip: notifications.length }),
        getUserTransactions(id),
      ]);

      setUserData(user.data.data);
      setWallet(userWallet.data.data.wallet);
      setTransactions(userTransactions.data.data.transactions);
      setNotifications(userNotifications.data.notifications);
    } catch (err) {
      addToasts({
        type: 'danger',
        body: 'unexpected error',
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [id]);

  const handleAddBalance = async () => {
    try {
      const add = parseFloat(addValue);
      if (add <= 0) {
        addToasts({
          type: 'danger',
          body: 'Please enter a valid amount',
        });
        return;
      }
      const res = await editWalletBalance(id, add);
      setAddValue(0);
      addToasts({
        type: 'success',
        body: 'Balance updated successfully',
      });
      await fetchData();
    } catch (err) {
      console.error(err);
      addToasts({
        type: 'danger',
        body: 'unexpected error',
      });
    }
  };

  const handleWithdrawBalance = async () => {
    try {
      const withdraw = parseFloat(withdrawValue);
      if (withdraw <= 0) {
        addToasts({
          type: 'danger',
          body: 'Please enter a valid amount',
        });
        return;
      }
      if (withdraw > wallet.balance) {
        addToasts({
          type: 'danger',
          body: 'You do not have enough balance',
        });
        return;
      }
      const res = await editWalletBalance(id, -withdraw);
      setWithdrawValue(0);
      addToasts({
        type: 'success',
        body: 'Balance updated successfully',
      });
      await fetchData();
    } catch (err) {
      console.error(err);
      addToasts({
        type: 'danger',
        body: 'unexpected error',
      });
    }
  };

  return {
    wallet,
    notifications,
    userData,
    loading,
    handleAddBalance,
    handleWithdrawBalance,
    navigate,
    transactions,
    addValue,
    setAddValue,
    withdrawValue,
    setWithdrawValue,
  };
}

export default useProfileWallet;
