import React from 'react';

function ProductTransactionData({ transaction, user_id }) {
  const products = transaction.item.products;
  return (
    <>
      <p className="font-bold text-lg mb-1">
        {transaction.sender_id === user_id ? (
          'Paid for Product Order'
        ) : (
          <>
            Received from {transaction.sender.first_name} {transaction.sender.last_name} for {transaction.item.title}{' '}
            Product
          </>
        )}{' '}
      </p>
      <div className="space-y-2">
        {products?.map((product, index) => (
          <div key={index} className="border-b border-gray-200 py-2">
            <a className="font-semibold text-blue-500" href={product.share_link}>
              {product.product.title}
            </a>
            <span>
              {' '}
              - {product.price} EGP x {product.quantity} = {product.price * product.quantity} EGP
            </span>
          </div>
        ))}
      </div>
    </>
  );
}

export default ProductTransactionData;
