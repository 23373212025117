import api from './_common/helper';

export async function getInvests(data) {
  return api.get(`/invest?skip=${data.skip}`);
}

export async function getInvest(data) {
  return api.get(`/invest/${data}`);
}

export async function getAllMoney() {
  return api.get('/allMoney');
}

export async function getVCC(data) {
  return api.get(`/vcc?skip=${data}`);
}

export async function getLastVCC() {
  return api.get('/vcc/last');
}

export async function getRemainingCount() {
  return api.get('/remaining/invest');
}

export async function getVccStats(period) {
  return api.get(`/vcc/statistics?skip=0&period=${period}`);
}

export async function getSoldCount() {
  return api.get('/sold/invest');
}

export async function updateVCC(data) {
  return api.post('/vcc', data);
}
