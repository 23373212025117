import { useContext, useEffect, useState } from 'react';
import ToastContext from '../Context/ToastContext';
import { useNavigate } from 'react-router-dom';
import { deleteJob, getJob, getJobRequests } from '../APIs/JobAPIs';

const useJob = id => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [requests, setRequests] = useState([]);
  const { addToasts } = useContext(ToastContext);
  const [loading, setLoading] = useState(true);

  async function fetchData() {
    try {
      setLoading(true);
      const { data } = await getJob(id);
      const res = await getJobRequests(id);
      setRequests(res.data.data.jobRequest);
      setData(data?.data?.job);
      setLoading(false);
    } catch (err) {
      addToasts({
        type: 'danger',
        body: 'unexpected error',
      });
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  function handleEditJob() {
    navigate('/jobs/job/editjob', {
      state: { data },
    });
  }

  async function handleDeleteJob() {
    try {
      await deleteJob(id);
      addToasts({
        body: 'job updated successfully',
        type: 'success',
      });
    } catch (err) {
      addToasts({
        body: 'network error',
        type: 'danger',
      });
    }
    navigate(-1);
  }

  return {
    loading,
    data,
    requests,
    handleEditJob,
    handleDeleteJob,
  };
};

export default useJob;
