import api from '../APIs/_common/helper';
export async function searchUniversities(data) {
  return api.post('/search/university', data);
}

export async function getUniversities(data) {
  return api.get(`/universities/?skip=${data.skip}`);
}

export async function getUniversity(data) {
  return api.get(`/university/${data}`);
}

export async function getUniversityStudents(id, skip) {
  return api.get(`/university/${id}/students?skip=${skip}`);
}

export async function addUniversity(data) {
  return api.post('/university', data);
}

export async function updateUniversity(id, data) {
  return api.put(`/university/${id}`, data);
}

export async function deleteUniversity(id) {
  return api.delete(`/university/${id}`);
}
