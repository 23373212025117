import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ToastContext from '../Context/ToastContext';
import { getAllMealsByStatus, searchOrders } from '../APIs/OrderAPIs';

const OrdersToDoHook = () => {
  const navigate = useNavigate();
  const { addToasts } = useContext(ToastContext);

  const [input, setInput] = useState('');
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chefStatus, setChefStatus] = useState('pending');
  const [maxCount, setMaxCount] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  const [timeoutId, setTimeoutId] = useState(null);

  const handleSelectOrder = id => {
    navigate('/meals/order', {
      state: { id },
    });
  };

  const handleInputChange = e => {
    setSearchData([]);
    setInput(e.target.value);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (e.target.value.length === 0) {
      fetchOrdersData();
      return;
    }

    const newTimeoutId = setTimeout(() => {
      searchOrdersData(e.target.value);
    }, 500);

    setTimeoutId(newTimeoutId);
  };

  const ordersStatusNavigationSelectHandler = e => {
    if (e.target.id === chefStatus) return;
    setData([]);
    setSearchData([]);
    setChefStatus(e.target.id);
  };

  const fetchOrdersData = useCallback(async () => {
    const searchQuery = {
      skip: data.length || 0,
      chefStatus,
    };

    if (!data.length) setLoading(true);

    setSearchData([]);
    setIsFetching(true);

    getAllMealsByStatus(searchQuery)
      .then(res => {
        if (res.status === 200) {
          const { orders, totalOrders } = res.data.data;
          setMaxCount(totalOrders);
          if (!data.length) {
            setData(orders);
            return;
          }
          setData(prevState => [...prevState, ...orders]);
        }
      })
      .catch(err => {
        addToasts({
          type: 'danger',
          body: `${err.response.data?.msg || 'something went wrong, please try again later'}`,
        });
      })
      .finally(() => {
        setIsFetching(false);
        if (!data.length) setLoading(false);
      });
  }, [addToasts, data.length, chefStatus]);

  const searchOrdersData = useCallback(
    async searchText => {
      const searchQuery = {
        skip: searchData.length || 0,
        chefStatus,
        text: searchText,
      };

      if (!searchData.length) setLoading(true);
      setData([]);
      setIsFetching(true);

      searchOrders(searchQuery)
        .then(res => {
          if (res.status === 200) {
            const { orders, totalOrders } = res.data.data;
            setMaxCount(totalOrders);
            if (!searchData.length) {
              setSearchData(orders);
              return;
            }
            setSearchData(prevState => [...prevState, ...orders]);
          }
        })
        .catch(err => {
          addToasts({
            body: `${err.response.data.msg || 'something went wrong, please try again later'}`,
            type: 'danger',
          });
        })
        .finally(() => {
          setIsFetching(true);
          if (!searchData.length) setLoading(false);
        });
    },
    [addToasts, searchData.length, chefStatus],
  );

  useEffect(() => {
    // fetch data on page load
    if (!input) {
      setSearchData([]);
      fetchOrdersData();
      return;
    }
    // fetch data on search
    setData([]);
    searchOrdersData(input);
    // eslint-disable-next-line
  }, [chefStatus]);

  //  handle scroll
  useEffect(() => {
    const isReachedEndOfList = (dataLength, searchDataLength, maxCount) => {
      return dataLength >= maxCount || searchDataLength >= maxCount;
    };
    const handleScroll = () => {
      const windowInnerHeight = window.innerHeight;
      const documentElementScrollTop = document.documentElement.scrollTop;
      const documentElementOffsetHeight = document.documentElement.offsetHeight;
      const scroll = windowInnerHeight + documentElementScrollTop;
      const offset = documentElementOffsetHeight - 200;
      const dataLength = data.length;
      const searchDataLength = searchData.length;

      if (scroll >= offset && !isReachedEndOfList(dataLength, searchDataLength, maxCount) && !isFetching) {
        window.removeEventListener('scroll', handleScroll);
        // if there is no search input, fetch meals data
        if (!input) {
          fetchOrdersData();
          return;
        }
        // if there is search input, fetch search data
        searchOrdersData(input);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [data, searchData, maxCount, input, fetchOrdersData, searchOrdersData, isFetching]);

  return [
    input,
    chefStatus,
    loading,
    data,
    searchData,
    handleInputChange,
    ordersStatusNavigationSelectHandler,
    navigate,
    handleSelectOrder,
  ];
};

export default OrdersToDoHook;
