import '../Styles/Order.css';
import Sidebar from '../Components/Sidebar';
import TopCard from '../Components/TopCard';
import LoadingSpinner from '../Components/LoadingSpinner';
import convertDateToDateString from '../Utils/convertDateToDateString';
import convertTimeDecimalToTimeFormat from '../Utils/convertTimeDecimalToTimeFormat';
import { FaLocationDot } from 'react-icons/fa6';
import OrderHook from '../Hooks/OrderHook';

export default function Order() {
  const [order, loading] = OrderHook();

  return (
    <>
      <Sidebar></Sidebar>
      <TopCard title="Order"></TopCard>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {order.user && (
            <div className="orderContent">
              <div className="order-card">
                <div className="from-to">
                  <div>
                    <img src={order.user.avatar} width={100} alt="Sender Avatar" />
                    <div>
                      <div>From</div>
                      <div className="bold">{order.user.first_name}</div>
                    </div>
                  </div>
                  <div>
                    <img src={order.chef.avatar} width={100} alt="Sender Avatar" />
                    <div>
                      <div>To(chef)</div>
                      <div className="bold">{order.chef.first_name}</div>
                    </div>
                  </div>
                </div>
                <div className="my-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div className="bold primary">{order.receive_method}</div>
                  <div className="text-dark_gray">{convertDateToDateString(order.createdAt)}</div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div className="text-dark_gray">
                    Total: <span className="black">{order.total_price} EGP</span>
                  </div>
                  <div className="text-dark_gray">
                    Chef will take from this order:
                    <span className="black"> {order.seller_gain} EGP</span>
                  </div>
                </div>
                <div className="text-dark_gray">
                  Time to prepare order:
                  <span className="text-blue-500"> {convertTimeDecimalToTimeFormat(order.time_to_prepare)}</span>
                </div>
                <div className="text-dark_gray">
                  Time to deliver order:{' '}
                  <span className="text-blue-500"> {convertTimeDecimalToTimeFormat(order.time_to_deliver)}</span>
                </div>
                <div className="text-dark_gray">
                  Notes from customer: <span className="black"> {order.note}</span>.
                </div>
                <div className="text-dark_gray">
                  Costumer phone number: <span className="black">{order.user.phone_number}</span>
                </div>
                <div className="text-dark_gray">
                  Meal will be received after:{' '}
                  <span className="text-blue-500">
                    {convertTimeDecimalToTimeFormat(order.time_to_prepare + order.time_to_deliver)}
                  </span>
                </div>
                <div className="flex flex-row gap-2 mt-2">
                  <span className="text-blue-500 mt-1">
                    <FaLocationDot />{' '}
                  </span>
                  <ul className="text-sm text-gray-600">
                    <li className="font-bold">
                      {order.deliveryAddress.address} - {order.deliveryAddress.street},
                    </li>
                    <li>
                      Building: <span className="font-bold">{order.deliveryAddress.building}</span>,
                    </li>
                    <li>
                      Floor: <span className="font-bold">{order.deliveryAddress.floor}</span>,
                    </li>
                    <li>
                      Flat: <span className="font-bold">{order.deliveryAddress.flat}</span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* {order.status === 'preparing' ? (
                <div className="order-tracking-card">
                  <div className="bold">Track order</div>
                  <div className="tracking-buttons">
                    {['To do', 'Doing', 'Done']?.map(status => (
                      <button
                        key={status}
                        className={orderStatus === status ? 'active' : ''}
                        onClick={() => setOrderStatus(status)}
                      >
                        {status}
                      </button>
                    ))}
                  </div>
                  <button disabled={!isValid} onClick={onClick} className="update-button">
                    Update
                  </button>
                </div>
              ) : order.status === 'rejected' ? (
                <div className="doneOrder">
                  <div className="text-dark_gray">Track order</div>
                  <div className="bold">Order rejected from the chef </div>
                </div>
              ) : order.status === 'delivered' ? (
                <div className="doneOrder">
                  <div className="text-dark_gray">Track order</div>
                  <div className="bold">Order was done </div>
                </div>
              ) : (
                <div className="order-tracking-card">
                  <div className="tracking-buttons">
                    <BasicModalTwo
                      question="Are you sure you want to accept this order?"
                      buttonName="Accept"
                      onAccept={onAccept}
                    ></BasicModalTwo>
                    <BasicModalTwo
                      question="Are you sure you want to reject this order?"
                      buttonName="Reject"
                      onAccept={onReject}
                    ></BasicModalTwo>
                  </div>
                </div>
              )} */}
            </div>
          )}
        </div>
      )}
    </>
  );
}
