import React from 'react';

function MealRequest({ data, navigate }) {
  const handleMealClick = mealId => {
    navigate('/meals/meal', { state: { id: mealId } });
  };

  return (
    <div className="homePageFirstCard">
      <div className="homepagecard1contentline1">
        <div>Requests to add meals</div>
        <div className="seeAllMeals" onClick={() => navigate('/meals')}>
          See all
        </div>
      </div>

      <div className="homePageFirstCardContent">
        {data?.map(meal => (
          <div
            key={meal.id}
            id={meal.id}
            onClick={() =>
              navigate('/meals/meal', {
                state: { id: meal.id },
              })
            }
            style={{ cursor: 'pointer' }}
            className="mealcard"
          >
            <img
              id={meal.id}
              src={meal.image}
              alt="text"
              className="w-full h-auto object-cover"
              crossOrigin="anonymous"
            />
            <div id={meal.id} className="titleformeal">
              {meal.title}
            </div>
            <div id={meal.id} className="mealcardline3">
              <div id={meal.id} className="mealprice">
                {meal.price} EGP
              </div>
              <div id={meal.id}>{meal.deliveryFee} EGP delivery fee</div>
            </div>
            <div id={meal.id} className="imgname">
              <img id={meal.id} src={meal.chef.avatar} className="w-10 h-10 rounded-full" alt="text" />
              <span id={meal.id}>{meal.chef.username}</span>
            </div>
            <div id={meal.id} className="meallastline">
              {meal.address.street}, {meal.address.building}, {meal.address.flat}, {meal.address.floor}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MealRequest;
