import api from './_common/helper';

export async function getLoans(query) {
  const params = new URLSearchParams(query).toString();
  return api.get(`/loan?${params}`);
}

export async function getLoan(id) {
  return api.get(`/loan/${id}`);
}

export async function getLoanRequests({ skip, status }) {
  const statusParam = JSON.stringify(status);
  return api.get(`/loanRequest/all?skip=${skip}&status=${statusParam}`);
}

export async function getLoanRequest(id) {
  return api.get(`/loanRequest/${id}`);
}

export async function addLoan(data) {
  return api.post(`/loan`, data);
}

export async function updateLoan(id, data) {
  return api.put(`/loan/${id}`, data);
}

export async function deleteLoan(id) {
  return api.delete(`/loan/${id}`);
}

export async function acceptLoanRequest(id) {
  return api.put(`/loanRequest/${id}/accept`);
}

export async function rejectLoanRequest(id, data) {
  return api.put(`/loanRequest/${id}/reject`, data);
}

export async function getPayments(id) {
  return api.get(`/loanRequest/${id}/payments`);
}
