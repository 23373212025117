import { useContext, useEffect, useReducer, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ToastContext from '../Context/ToastContext';
import { deleteLoan, getLoan, updateLoan } from '../APIs/LoanAPIs';

const initialState = {
  amount: '',
  trusts: '',
  accountPeriod: '',
  preAmount: '',
  about: '',
  visitors: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_INIT':
      return { ...state, loading: true, error: null };
    case 'FETCH_FINISH':
      return { ...state, loading: false, error: null };
    case 'SET_AMOUNT':
      return { ...state, amount: action.payload };
    case 'SET_TRUSTS':
      return { ...state, trusts: action.payload };
    case 'SET_ACCOUNT_PERIOD':
      return { ...state, accountPeriod: action.payload };
    case 'SET_ABOUT':
      return { ...state, about: action.payload };
    case 'SET_PREAMOUNT':
      return { ...state, preAmount: action.payload };
    case 'SET_VISITORS':
      return { ...state, visitors: action.payload };
    case 'FETCH_FAILURE':
      return { ...state, loading: false };
    default:
      throw new Error('Error in useVerificationRequests reducer');
  }
}

const useLoan = () => {
  const {
    state: { id },
  } = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { addToasts } = useContext(ToastContext);
  const navigate = useNavigate();

  const onClick = async event => {
    dispatch({ type: 'FETCH_INIT' });
    event.preventDefault();
    try {
      const tmp = {
        amount: state.amount,
        preAmount: state.preAmount,
        trusts_required: state.trusts,
        visitors: state.visitors,
        accountPeriod: state.accountPeriod,
        about: state.about,
      };
      await updateLoan(id, tmp);
      dispatch({ type: 'FETCH_FINISH' });
      addToasts({
        body: 'event added successfully',
        type: 'success',
      });
      navigate(-1);
    } catch (err) {
      addToasts({
        body: 'unexpected error',
        type: 'danger',
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        dispatch({ type: 'FETCH_INIT' });
        const { data } = await getLoan(id);
        dispatch({ type: 'SET_ABOUT', payload: data.data.loan.about });
        dispatch({ type: 'SET_AMOUNT', payload: data.data.loan.amount });
        dispatch({ type: 'SET_ACCOUNT_PERIOD', payload: data.data.loan.accountPeriod });
        dispatch({ type: 'SET_PREAMOUNT', payload: data.data.loan.preAmount });
        dispatch({ type: 'SET_TRUSTS', payload: data.data.loan.trusts_required });
        dispatch({ type: 'SET_VISITORS', payload: data.data.loan.visitors });
        dispatch({ type: 'FETCH_FINISH' });
      } catch (err) {
        addToasts({
          type: 'danger',
          body: 'unexpected error',
        });
      }
    })();
  }, [id]);

  async function handleDelete(id) {
    try {
      dispatch({ type: 'FETCH_INIT' });
      await deleteLoan(id);
      addToasts({
        body: 'event deleted successfully',
        type: 'success',
      });
      dispatch({ type: 'FETCH_FINISH' });
      navigate('/loans');
    } catch (err) {
      addToasts({
        body: 'unexpected error',
        type: 'danger',
      });
    }
  }

  function handleAmountChange(data) {
    dispatch({ type: 'SET_AMOUNT', payload: data });
  }
  function handlePreAmountChange(data) {
    dispatch({ type: 'SET_PREAMOUNT', payload: data });
  }
  function handleTrustsChange(data) {
    dispatch({ type: 'SET_TRUSTS', payload: data });
  }
  function handleVisitorsChange(data) {
    dispatch({ type: 'SET_VISITORS', payload: data });
  }
  function handlePeriodChange(data) {
    dispatch({ type: 'SET_ACCOUNT_PERIOD', payload: data });
  }
  function handleAboutChange(data) {
    dispatch({ type: 'SET_ABOUT', payload: data });
  }

  return {
    id,
    loading: state.loading,
    amount: state.amount,
    preAmount: state.preAmount,
    trusts: state.trusts,
    accountPeriod: state.accountPeriod,
    about: state.about,
    visitors: state.visitors,
    valid:
      state.amount !== '' &&
      state.preAmount !== '' &&
      state.trusts !== '' &&
      state.accountPeriod !== '' &&
      state.about !== '' &&
      state.visitors !== '',
    onClick,
    handlePreAmountChange,
    handleVisitorsChange,
    handlePeriodChange,
    handleAboutChange,
    handleAmountChange,
    handleTrustsChange,
    handleDelete,
  };
};

export default useLoan;
