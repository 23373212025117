import AddressCard from '../../../_common/AddressCard';
import PriceAndDeliveryCard from '../../../_common/PriceAndDeliveryCard';
import ItemSellerCard from '../../../_common/ItemSellerCard';
const MealCard = ({ meal, handleSelectMeal, className }) => {
  return (
    <div
      key={meal.id}
      id={meal.id}
      onClick={handleSelectMeal}
      style={{ cursor: 'pointer' }}
      className={`flex flex-col gap-2 box-content ${className} bg-white rounded-lg pb-2`}
    >
      <img
        id={meal.id}
        src={meal.image}
        alt={meal.title}
        className="w-full h-64 object-contain m-0 p-0"
        crossOrigin="anonymous"
      />
      <h1 className="font-semibold text-xl text-gray-800 px-2" id={meal.id}>
        {meal.title}
      </h1>
      <PriceAndDeliveryCard item={meal} />
      <ItemSellerCard id={meal.id} user={meal.chef} />
      <AddressCard id={meal.id} address={meal.address} />
    </div>
  );
};

export default MealCard;
