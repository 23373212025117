import Sidebar from '../Components/Sidebar';
import TopCard from '../Components/TopCard';
import '../Components/AddPage.css';
import { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../Styles/EditCourse.css';
import { upload } from '../APIs/UploadAPIs';
import {
  updateCourse,
  deleteCourse,
  getCourse,
  getCourseContent,
  deleteArticle,
  deleteVideo,
  deleteExam,
  deleteWeekContent,
} from '../APIs/CourseAPIs';
import BasicModal from '../Components/Modal';
import ToastContext from '../Context/ToastContext';
import CourseContentCard from '../Components/CourseContentCard';
import CourseForm from '../Components/CourseForm';
import blankDefaultImage from '../Assets/blankDefaultImage.png';
import config from '../Config/Config';
import Layout from '../Components/_common/Layout';

export default function EditCourse() {
  const {
    state: { data },
  } = useLocation();

  const navigate = useNavigate();
  const { addToasts } = useContext(ToastContext);

  const courseID = data.id;

  const [courseContent, setCourseContent] = useState([]);

  const [deleteLoading, setDeleteLoading] = useState({ loading: false, id: null });

  const deleteCourseHandler = async () => {
    try {
      await deleteCourse(courseID);
      addToasts({
        type: 'success',
        body: `Course deleted successfully`,
      });
      navigate('/courses');
    } catch (err) {
      if (config.MODE === 'development') console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const course = await getCourse(courseID);
        const courseContentResponse = await getCourseContent(courseID);
        setCourseContent(courseContentResponse.data.data);
        const courseData = course.data.data;
        setFormInputs({
          title: courseData.course.title,
          about: courseData.course.about,
          is_free: courseData.course.is_free,
          price: courseData.course.price,
          discount: courseData.course.discount,
          professor: { label: courseData.course.professor.first_name, value: courseData.course.professor.user_id },
          category: { value: courseData.course.category, label: courseData.course.category },
          time: courseData.course.time,
          language: { value: courseData.course.language, label: courseData.course.language },
          level: { value: courseData.course.level, label: courseData.course.level },
          roles: courseData.course.roles?.map(role => {
            return { value: role, label: role };
          }),
        });
        setFilePreview(courseData.course.photo);
      } catch (err) {
        if (config.MODE === 'development') console.error(err);
        addToasts({
          type: 'danger',
          body: `${err.response.data?.msg || 'something went wrong, please try again later'}`,
        });
      }
    })();
    // eslint-disable-next-line
  }, []);

  const [filePreview, setFilePreview] = useState(blankDefaultImage);
  const [formInputs, setFormInputs] = useState({
    title: '',
    about: '',
    is_free: true,
    price: 0,
    discount: 0,
    photo: null,
    professor: { label: 'None', value: '' },
    category: { value: 'Engineering', label: 'Engineering' },
    time: 0,
    language: { value: 'English', label: 'English' },
    level: { value: 'Beginner', label: 'Beginner' },
    roles: [],
  });
  const inputsOnChangeHandler = e => {
    const { name, value } = e.target;
    setFormInputs({ ...formInputs, [name]: value });
  };

  const paymentTypeOnChangeHandler = e => {
    const { value } = e.target;
    if (value === 'free') {
      setFormInputs({ ...formInputs, is_free: true, price: 0, discount: 0 });
      return;
    }
    setFormInputs({ ...formInputs, is_free: false });
  };

  const fileOnChangeHandler = e => {
    const file = e.target.files[0];
    if (!file) return;
    if (!file.type.includes('image')) {
      addToasts({
        type: 'danger',
        body: `Only images are allowed`,
      });
      setFormInputs({ ...formInputs, photo: null });
      setFilePreview(blankDefaultImage);
      return;
    }
    if (file.size > 2 * 1024 * 1024) {
      addToasts({
        type: 'danger',
        body: `File size should be less than 2MB`,
      });
      setFormInputs({ ...formInputs, photo: null });
      setFilePreview(blankDefaultImage);
      return;
    }

    setFormInputs({ ...formInputs, photo: file });
    setFilePreview(URL.createObjectURL(file));
  };

  const professorOnChangeHandler = selectedOption => {
    setFormInputs({ ...formInputs, professor: selectedOption });
  };

  const categoryOnChangeHandler = selectedOption => {
    setFormInputs({ ...formInputs, category: selectedOption });
  };

  const languageOnChangeHandler = selectedOption => {
    setFormInputs({ ...formInputs, language: selectedOption });
  };

  const levelOnChangeHandler = selectedOption => {
    setFormInputs({ ...formInputs, level: selectedOption });
  };

  const roleOnChangeHandler = selectedOption => {
    setFormInputs({ ...formInputs, roles: selectedOption });
  };

  const onClick = async e => {
    e.preventDefault();
    const { title, about, professor, category, time, language, level, roles, discount } = formInputs;
    if (
      !title ||
      !about ||
      !professor.value ||
      !category.value ||
      !time ||
      !language.value ||
      !level.value ||
      !roles.length
    ) {
      addToasts({
        type: 'warning',
        body: `Please fill all fields and course content`,
      });
      return;
    }
    if (discount > 100) {
      addToasts({
        type: 'warning',
        body: `Discount cannot be more than 100%`,
      });
      return;
    }
    try {
      formInputs.professor_id = formInputs.professor.value;
      formInputs.professor = undefined;
      formInputs.roles = formInputs.roles?.map(r => r.value);
      formInputs.category = formInputs.category.value;
      formInputs.language = formInputs.language.value;
      formInputs.level = formInputs.level.value;

      if (formInputs.photo) {
        let formData = new FormData();
        formData.append('files', formInputs.photo);
        const res = await upload(formData);
        if (res.status === 200) {
          const { fileLinks } = await res.data;
          if (fileLinks) {
            formInputs.photo = fileLinks[0];
          }
        }
      }

      let res = await updateCourse(courseID, formInputs);
      let course = await res.data.data.result;
      addToasts({
        type: 'success',
        body: 'Course updated successfully',
      });
      navigate('/courses/course', { state: { courseId: course.id } });
    } catch (err) {
      if (config.MODE === 'development') console.error(err);
      addToasts({
        type: 'danger',
        body: `${err.response.data?.msg || 'something went wrong, please try again later'}`,
      });
    }
  };

  const addSectionsHandler = () => {
    navigate('/courses/addeditsection', {
      state: { data: { courseID: courseID } },
    });
  };

  const editSectionTitle = (title, index, id) => {
    navigate('/courses/addeditsection', {
      state: {
        data: {
          contentID: id,
          courseID: courseID,
          title: title,
        },
      },
    });
  };

  const addToSectionHandler = (sectionIndex, section) => {
    navigate('/courses/addlesson', {
      state: {
        contentID: section.id,
        courseID: courseID,
      },
    });
  };

  const deleteSectionHandler = async (sectionIndex, section) => {
    setDeleteLoading({ loading: true, id: section.id });
    try {
      await deleteWeekContent(section.id);
      const courseContentResponse = await getCourseContent(courseID);
      setCourseContent(courseContentResponse.data.data);
      setDeleteLoading({ loading: false, id: section.id });
      addToasts({
        type: 'success',
        body: 'Section deleted successfully',
      });
    } catch (err) {
      if (config.MODE === 'development') console.error(err);
      addToasts({
        type: 'danger',
        body: `${err.response.data?.msg || 'something went wrong, please try again later'}`,
      });
      setDeleteLoading({ loading: false, id: section.id });
    }
  };

  const editSectionContent = (sectionIndex, index, content) => {
    navigate('/courses/addlesson', {
      state: {
        editSectionContentType: content.TYPE === 'VIDEO' ? 'Video' : content.TYPE === 'EXAM' ? 'Exam' : 'Article',
        editSectionContentID: content.id,
        contentData: content,
        courseID: courseID,
      },
    });
  };

  const deleteSectionContent = async (sectionIndex, index, content) => {
    setDeleteLoading({ loading: true, id: content.id });
    try {
      if (content.TYPE === 'VIDEO') {
        await deleteVideo(content.id);
        addToasts({
          type: 'success',
          body: 'Video deleted successfully',
        });
      } else if (content.TYPE === 'EXAM') {
        await deleteExam(content.id);
        addToasts({
          type: 'success',
          body: 'Exam deleted successfully',
        });
      } else if (content.TYPE === 'ARTICLE') {
        await deleteArticle(content.id);
        addToasts({
          type: 'success',
          body: 'Article deleted successfully',
        });
      }
      const courseContentResponse = await getCourseContent(courseID);
      setCourseContent(courseContentResponse.data.data);
      setDeleteLoading({ loading: false, id: null });
    } catch (err) {
      if (config.MODE === 'development') console.error(err);
      addToasts({
        type: 'danger',
        body: `${err.response.data?.msg || 'something went wrong, please try again later'}`,
      });
      setDeleteLoading({ loading: false, id: null });
    }
  };

  return (
    <Layout title="Edit Course" back>
      <div className="editCoursePage">
        <div className="grid lg:grid-cols-3 gap-2 pt-3">
          <CourseForm
            formInputs={formInputs}
            inputsOnChangeHandler={inputsOnChangeHandler}
            paymentTypeOnChangeHandler={paymentTypeOnChangeHandler}
            professorOnChangeHandler={professorOnChangeHandler}
            categoryOnChangeHandler={categoryOnChangeHandler}
            languageOnChangeHandler={languageOnChangeHandler}
            levelOnChangeHandler={levelOnChangeHandler}
            roleOnChangeHandler={roleOnChangeHandler}
            fileOnChangeHandler={fileOnChangeHandler}
            filePreview={filePreview}
          />
          <div>
            <CourseContentCard
              courseContentsData={courseContent}
              addSectionsHandler={addSectionsHandler}
              editSectionTitle={editSectionTitle}
              addToSectionHandler={addToSectionHandler}
              editSectionContent={editSectionContent}
              deleteSectionContent={deleteSectionContent}
              deleteLoading={deleteLoading}
              deleteSectionHandler={deleteSectionHandler}
            />
            <div className="flex justify-center items-center">
              <button onClick={onClick} className="addButton my-5">
                Edit
              </button>
            </div>
            <div className="flex justify-center items-center">
              <div className="w-full">
                <BasicModal
                  question="are you sure to delete course ?"
                  buttonName="Delete course"
                  onAccept={deleteCourseHandler}
                ></BasicModal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
