import React from 'react';

function ReceivedMessage({ children, user, timeCollapsed }) {
  return (
    <div className="messageAndAvatarSender">
      <div>
        <img src={user.avatar} alt="Avatar" className="w-12 h-12 rounded-full object-cover" />
      </div>
      <p className="senderMessage p-2 flex-col bg-green-200 rounded-lg text-black shadow-md">
        {children}
        <span className="bg-green-600 text-white text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded border-green-400 mt-2 w-fit">
          <svg
            className="w-2.5 h-2.5 me-1.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm3.982 13.982a1 1 0 0 1-1.414 0l-3.274-3.274A1.012 1.012 0 0 1 9 10V6a1 1 0 0 1 2 0v3.586l2.982 2.982a1 1 0 0 1 0 1.414Z" />
          </svg>
          {timeCollapsed}
        </span>
      </p>
    </div>
  );
}

export default ReceivedMessage;
