import React from 'react';
import { useNavigate } from 'react-router-dom';
const InfoRowWithButton = ({ label, value, buttonText, navigateTo, userId }) => {
  const navigate = useNavigate();
  return (
    <div className="flex md:justify-between md:items-center w-full md:gap-4 md:flex-row flex-col gap-1">
      <p className="text-[#6b7280]">
        {label}:<span className="text-black"> {value}</span>
      </p>

      <button
        onClick={() =>
          navigate(navigateTo, {
            state: { id: userId },
          })
        }
        className="cursor-pointer text-blue-500 underline font-semibold"
      >
        {buttonText}
      </button>
    </div>
  );
};

export default InfoRowWithButton;
