import api from './_common/helper';
export async function searchPosts(data) {
  return api.get('/search/post', data);
}

export async function getPosts(data) {
  const queryParams = new URLSearchParams({
    filter: data.filter || 'date', // date, trending, university
    category: data.category,
    skip: data.skip,
  }).toString();

  return api.get(`/posts?${queryParams}`);
}

export async function getPost(data) {
  return api.get(`/post/${data}`);
}

export async function acceptPost(id) {
  return api.put('/accept/post/' + id);
}

export async function rejectPost(id) {
  return api.put('/reject/post/' + id);
}

export async function deletePost(id) {
  return api.delete('/post/' + id);
}
