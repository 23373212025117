import React from 'react';
import FullNameConcat from '../../../Utils/FullNameConcat';
function CourseTransactionData({ transaction, user_id }) {
  return (
    <p className="font-bold text-lg mb-2">
      {transaction.sender_id === user_id ? (
        <>
          Paid for{' '}
          <a className="text-blue-500" href={transaction.item.share_link}>
            {transaction.item.title}
          </a>{' '}
          Course
        </>
      ) : (
        <>
          Received from <span className="text-blue-500">{FullNameConcat(transaction.sender)}</span>
          for{' '}
          <a className="text-blue-500" href={transaction.item.share_link}>
            {transaction.item.title}
          </a>{' '}
          Course
        </>
      )}{' '}
    </p>
  );
}

export default CourseTransactionData;
