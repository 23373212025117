import { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { upload } from '../APIs/UploadAPIs';
import { addJob } from '../APIs/JobAPIs';
import Sidebar from '../Components/Sidebar';
import TopCard from '../Components/TopCard';
import uploadIcon from '../Assets/plus.png';
import ToastContext from '../Context/ToastContext';
import LoadingSpinner from '../Components/LoadingSpinner';

import Layout from '../Components/_common/Layout';

export default function AddJob() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [fileName, setFileName] = useState('No selected file');
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [title, setTitle] = useState('');
  const [location, setLocation] = useState('');
  const [about, setAbout] = useState('');
  const { addToasts } = useContext(ToastContext);
  const isValid = file && title.trim() && about.trim() && location.trim();

  const handleIconClick = event => {
    fileInputRef.current.click();
  };

  const handleFileChange = event => {
    setFile(event.target.files[0]);
    const fileSelected = event.target.files[0] && setFileName(event.target.files[0].name);
  };
  const onClick = async event => {
    setLoading(true);
    event.preventDefault();
    try {
      let temp = {
        title,
        about,
        location,
      };
      if (!file) {
        addToasts({
          body: 'Please select a file.',
          type: 'danger',
        });
        return;
      }
      const formData = new FormData();
      formData.append('files', file);
      let res = await upload(formData);
      if (res.status !== 200) {
        addToasts({
          type: 'danger',
          body: 'network error',
        });
      }
      if (res.data?.fileLinks.length === 0) {
        addToasts({
          body: 'error uploading the file',
          type: 'danger',
        });
      } else {
        temp.photo = res.data?.fileLinks[0];
      }
      await addJob(temp);
      addToasts({
        body: 'event added successfully',
        type: 'success',
      });
      setLoading(false);
      navigate(-1);
    } catch (err) {
      addToasts({
        body: 'unexpected error',
        type: 'danger',
      });
    }
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Layout title={'Add Job'}>
          <div className="flex flex-col w-full md:w-1/2 mx-auto my-2 p-5 gap-2 bg-white rounded-xl">
            <label>Title of Job</label>
            <input
              className="border-2 p-2 rounded-lg"
              placeholder="Title of Event"
              value={title}
              onChange={e => setTitle(e.target.value)}
            ></input>
            <label>Location</label>
            <input
              className="border-2 p-2 rounded-lg"
              placeholder="Location of Event"
              value={location}
              onChange={e => setLocation(e.target.value)}
            ></input>
            <label>About</label>
            <textarea
              className="border-2 p-2 rounded-lg"
              placeholder="About"
              value={about}
              onChange={e => setAbout(e.target.value)}
            ></textarea>
            <label>Job photo</label>
            <div className="photoUpload">
              <div className="uploadCustom" placeholder="hello">
                <span className="font-semibold text-lg text-cyan-900">{fileName}</span>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  ref={fileInputRef}
                  className="uploadInput"
                  placeholder="Photo"
                  onChange={handleFileChange}
                ></input>
              </div>
              <img src={uploadIcon} alt="image" onClick={handleIconClick}></img>
            </div>
            <button
              className="disabled:bg-gray-400 w-1/3 bg-blue-500 p-2 text-xl mx-auto rounded-md  cursor-pointer text-white"
              disabled={!isValid}
              onClick={onClick}
            >
              Add
            </button>
          </div>
        </Layout>
      )}
    </>
  );
}
