import { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { getSession } from '../Utils/SessionUtils';
import { getReports, updateReport } from '../APIs/ReportsAPIs';

export const ReportHooks = () => {
  const [content, setContent] = useState('post');
  const [reports, setReports] = useState([]);
  const [socket, setSocket] = useState(null);
  const [page, setPage] = useState(0);
  const [pageIndex, setIndex] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const handleClick = async (action, _id) => {
    const { data } = await updateReport(content, _id, action);
    setReports(reports.filter(report => report.id !== _id));
  };

  async function handleContent(content) {
    setContent(content);
    setPage(0);
  }
  async function handlePage(page) {
    setPage(page);
  }

  async function handleIndex(index) {
    setIndex(index);
  }
  async function fetchData(content, page) {
    const { data } = await getReports(content, page);
    setReports(data.data?.reports);
    setTotalPages(Math.ceil(data?.data?.totalReports / 20));
  }
  useEffect(() => {
    fetchData(content, page);
  }, [page, content]);

  return { content, handleContent, reports, page, handlePage, handleIndex, pageIndex, totalPages, handleClick };
};
