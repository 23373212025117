import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../Components/Sidebar';
import TopCard from '../Components/TopCard';
import '../Styles/Viewers.css';

export default function Students() {
  const {
    state: { data },
  } = useLocation();
  const navigate = useNavigate();
  return (
    <div>
      <Sidebar></Sidebar>
      <div className="viewersContent">
        <TopCard title="Students"></TopCard>
        <div className="trustsUsers">
          <div className="trustsProfiles">
            {console.log(data)}
            {data?.map(profile => (
              <div className="viewersProfile">
                <div>
                  <img style={{ width: 100 }} src={profile.avatar} alt="image"></img>
                  <div>{profile.name}</div>
                </div>
                <div
                  id={profile._id}
                  onClick={e => {
                    navigate('/profiles/profile', {
                      state: { userId: e.target.id },
                    });
                  }}
                  className="viewersProfileProfile"
                >
                  profile..
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
