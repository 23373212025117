import { FaComment, FaHeart, FaShare } from 'react-icons/fa6';
import VideoEngagement from './VideoEngagement';

function VideoEngagementCard({ reel }) {
  return (
    <div className="absolute bottom-36 right-4 flex items-center flex-col gap-1 text-lg z-10">
      <VideoEngagement count={reel.likes_count}>
        <FaHeart className={`${reel.view?.isLiked ? 'text-red-500' : 'text-white'}`} />
      </VideoEngagement>
      <VideoEngagement count={reel.comments_count}>
        <FaComment className="text-white" />
      </VideoEngagement>
      <VideoEngagement count={reel.reposts_count}>
        <FaShare className="text-white" />
      </VideoEngagement>
    </div>
  );
}

export default VideoEngagementCard;
