import React from 'react';

function PriceAndDeliveryCard({ item }) {
  return (
    <p id={item.id} className="flex flex-row gap-3 font-semibold text-lg text-gray-600 px-2">
      <span id={item.id} className="text-blue-700">
        {item.price} EGP
      </span>
      <span id={item.id}>{item.deliveryFee} EGP delivery fee</span>
    </p>
  );
}

export default PriceAndDeliveryCard;
