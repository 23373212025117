import React from 'react';
import FullNameConcat from '../Utils/FullNameConcat';

function LoanRequest({ data, navigate }) {
  const handleLoanClick = loanId => {
    navigate('/loans/loansrequests/loansrequest', { state: { id: loanId } });
  };

  return (
    <div className="relative bg-white rounded-2xl mt-3 w-full">
      <div className="flex justify-between items-center p-3">
        <h2 className="text-2xl font-bold">Loan requests</h2>
        <button
          className="text-xl font-bold text-[#6b7280]"
          onClick={() => navigate('/loans/loansrequests')}
          style={{ cursor: 'pointer' }}
        >
          See all
        </button>
      </div>
      <div className="homePageFirstCardContent">
        {data?.map(loan => (
          <div key={loan.id} className="flex justify-between items-center m-4">
            <div className="flex items-center gap-3">
              <img src={loan.user.avatar} className="w-10 h-10 rounded-full" alt="Profile" />
              <div className="fromandname">
                <p className="font-bold text-xl">{FullNameConcat(loan.user)}</p>
                <div>{loan.loan.amount} EGP</div>
              </div>
            </div>
            <button
              id={loan.id}
              className="details"
              onClick={e =>
                navigate('/loans/loansrequests/loansrequest', {
                  state: { id: e.target.id },
                })
              }
              style={{ cursor: 'pointer' }}
            >
              Details..
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default LoanRequest;
