import { useNavigate } from 'react-router-dom';
import ToastContext from '../Context/ToastContext';
import { useContext, useEffect, useState } from 'react';
import { deleteEvent, getEvent } from '../APIs/EventAPIs';

const useEvent = id => {
  const [data, setData] = useState({});
  const { addToasts } = useContext(ToastContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  async function fetchData() {
    try {
      setLoading(true);
      const { data } = await getEvent(id);
      setData(data.data.event);
      setLoading(false);
    } catch (err) {
      addToasts({
        type: 'danger',
        body: 'unexpected error',
      });
    }
  }

  function handleEditEvent() {
    navigate('/events/event/editevent', {
      state: { data },
    });
  }

  async function handleDeleteEvent() {
    try {
      await deleteEvent(id);
      addToasts({
        body: 'event updated successfully',
        type: 'success',
      });
    } catch (err) {
      addToasts({
        body: 'network error',
        type: 'danger',
      });
    }

    navigate(-1);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return {
    loading,
    data,
    handleEditEvent,
    handleDeleteEvent,
  };
};

export default useEvent;
