import { useContext, useEffect, useState } from 'react';
import ToastContext from '../Context/ToastContext';
import { getRequests, getTotalPayments, getTotalTransactions, getTotalWithdraw } from '../APIs/DashboardWalletAPIs';
import { useNavigate } from 'react-router-dom';

const useDashboardWallet = () => {
  const { addToasts } = useContext(ToastContext);
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [withdraw, setWithdraw] = useState([]);
  const [transaction, setTransaction] = useState([]);
  const [date, setDate] = useState(() => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return currentDate;
  });
  const [show, setShowPicker] = useState(false);
  const [content, setContent] = useState('today');
  const [status, setStatus] = useState('PENDING');
  const [requests, setRequests] = useState([]);

  async function fetchPayments() {
    try {
      setLoading(true);
      let { data } = await getTotalPayments(date);
      const pay = {
        'total payments': data.data.totalPayments ?? 0,
        instapay: 0,
        paypal: 0,
        'vodafone cash': 0,
        'bank account': 0,
      };
      data.data.paymentDetails.forEach(el => {
        if (el.source_data_type === 'INSTAPAY') {
          pay.instapay = parseFloat(el.total_amount) || 0;
        } else if (el.source_data_type === 'PAYPAL') {
          pay.paypal = parseFloat(el.total_amount) || 0;
        } else if (el.source_data_type === 'VODAFONECASH') {
          pay['vodafone cash'] = parseFloat(el.total_amount) || 0;
        } else if (el.source_data_type === 'BANKACCOUNT') {
          pay['bank account'] = parseFloat(el.total_amount) || 0;
        }
      });

      setPayments(pay);
      setLoading(false);
    } catch (err) {
      addToasts({
        type: 'danger',
        body: 'Internal server error',
      });
    }
  }

  async function fetchWithdraw() {
    try {
      setLoading(true);
      let { data } = await getTotalWithdraw(date);
      const withdraw = {
        'total withdraw': data.data.totalWithdraw ?? 0,
        instapay: 0,
        paypal: 0,
        'vodafone cash': 0,
        'bank account': 0,
      };
      data.data.withdraw.forEach(el => {
        if (el.method === 'INSTAPAY') {
          withdraw.instapay = parseFloat(el.total_amount) || 0;
        } else if (el.method === 'PAYPAL') {
          withdraw.paypal = parseFloat(el.total_amount) || 0;
        } else if (el.method === 'VODAFONECASH') {
          withdraw['vodafone cash'] = parseFloat(el.total_amount) || 0;
        } else if (el.method === 'BANKACCOUNT') {
          withdraw['bank account'] = parseFloat(el.total_amount) || 0;
        }
      });
      setWithdraw(withdraw);
      setLoading(false);
    } catch (err) {
      addToasts({
        type: 'danger',
        body: 'Internal server error',
      });
    }
  }

  async function fetchTransaction() {
    try {
      setLoading(true);
      let { data } = await getTotalTransactions(date);

      const transaction = {
        'total spending': data.data.totalTransactions ?? 0,
        Meal: 0,
        Store: 0,
        VCC: 0,
        Course: 0,
      };
      data.data.transaction.forEach(el => {
        if (el.transaction_type === 'MEAL') {
          transaction.Meal = parseFloat(el.total_amount) || 0;
        } else if (el.transaction_type === 'STORE') {
          transaction.Store = parseFloat(el.total_amount) || 0;
        } else if (el.transaction_type === 'VCC') {
          transaction.VCC = parseFloat(el.total_amount) || 0;
        } else if (el.transaction_type === 'COURSE') {
          transaction.Course = parseFloat(el.total_amount) || 0;
        }
      });
      setTransaction(transaction);
      setLoading(false);
    } catch (err) {
      addToasts({
        type: 'danger',
        body: 'Internal server error',
      });
    }
  }

  async function fetchRequest() {
    try {
      setLoading(true);
      let { data } = await getRequests(status, date);
      setRequests(data.data.withdraw_requests);
      setLoading(false);
    } catch (err) {
      addToasts({
        type: 'danger',
        body: 'Internal server error',
      });
    }
  }

  function handleStatus(data) {
    setStatus(data);
  }

  function handelToday() {
    setShowPicker(false);
    setContent('today');
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    setDate(currentDate);
  }
  function handleYesterday() {
    setShowPicker(false);
    setContent('yesterday');
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(24, 0, 0, 0);
    setDate(yesterday);
  }

  function handleLastWeek() {
    setShowPicker(false);
    setContent('lastWeek');
    const lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);
    lastWeek.setHours(0, 0, 0, 0);
    setDate(lastWeek);
  }

  function handleLastMonth() {
    setShowPicker(false);
    setContent('lastMonth');
    const lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    lastMonth.setHours(0, 0, 0, 0);
    setDate(lastMonth);
  }

  function handleLastYear() {
    setShowPicker(false);
    setContent('lastYear');
    const lastYear = new Date();
    lastYear.setFullYear(lastYear.getFullYear() - 1);
    lastYear.setHours(0, 0, 0, 0);
    setDate(lastYear);
  }

  function handleCustomDate(date) {
    setContent('custom');
    let custom = new Date(date);
    setDate(custom);
  }
  function showPicker() {
    setShowPicker(!show);
  }

  useEffect(() => {
    fetchPayments();
    fetchWithdraw();
    fetchTransaction();
    fetchRequest();
  }, [date]);

  useEffect(() => {
    fetchRequest();
  }, [status]);

  return {
    loading,
    date: date.toISOString().split('T')[0],
    payments,
    withdraw,
    transaction,
    handelToday,
    handleYesterday,
    handleLastWeek,
    handleLastMonth,
    handleLastYear,
    handleCustomDate,
    show,
    showPicker,
    content,
    requests,
    status,
    handleStatus,
  };
};

export default useDashboardWallet;
