import React from 'react';

const InfoCard = ({ src, alt, count, label }) => (
  <div className="flex flex-row gap-4 items-center">
    <img src={src} alt={alt} />
    <span>
      {count} {label}
    </span>
  </div>
);

export default InfoCard;
