import React from 'react';
import { FaPause, FaPlay, FaVolumeMute, FaVolumeUp, FaExpand, FaCompress } from 'react-icons/fa';
function VideoControls({
  handlePlayPause,
  isPlaying,
  handleMute,
  isMuted,
  handleSeek,
  currentTime,
  duration,
  handleFullscreen,
  isFullscreen,
}) {
  return (
    <div className={`absolute bottom-2 left-4 flex items-center space-x-4 z-20`} style={{ width: 'calc(100% - 2rem)' }}>
      <button onClick={handlePlayPause} className="text-white">
        {isPlaying ? <FaPause size={24} /> : <FaPlay size={24} />}
      </button>
      <button onClick={handleMute} className="text-white">
        {isMuted ? <FaVolumeMute size={24} /> : <FaVolumeUp size={24} />}
      </button>
      <div className="flex-grow">
        <input
          type="range"
          min="0"
          max="100"
          value={(currentTime / duration) * 100 || 0}
          onChange={handleSeek}
          className="w-full cursor-pointer accent-blue-600"
        />
      </div>
      <button onClick={handleFullscreen} className="text-white">
        {isFullscreen ? <FaCompress size={24} /> : <FaExpand size={24} />}
      </button>
    </div>
  );
}

export default VideoControls;
