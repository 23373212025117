import { useRef } from "react";
import Sidebar from "../Components/Sidebar";
import TopCard from "../Components/TopCard";
import uploadIcon from "../Assets/plus.png";
import deleteIcon from "../Assets/deleteIcon.svg";
import "../Styles/EditPost.css";
export default function EditPost() {
  const fileInputRef = useRef(null);

  const handleIconClick = () => {
    // Trigger a click event on the hidden file input
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    // Handle the selected file here (e.target.files[0])
    console.log("Selected file:", e.target.files[0].name);
  };

  return (
    <div>
      <Sidebar></Sidebar>
      <TopCard title="Edit post"></TopCard>
      <div className="editPost">
        <label>Photo 1</label>
        <div className="photoDeleteUpload">
          <input
            type="file"
            ref={fileInputRef}
            className="uploadInput"
            placeholder="Photo"
            onChange={handleFileChange}
          ></input>

          <div>
            <img src={deleteIcon} alt="image"></img>
            <img src={uploadIcon} alt="image" onClick={handleIconClick}></img>
          </div>
        </div>
        <label>Photo 2</label>
        <div className="photoDeleteUpload">
          <input
            type="file"
            ref={fileInputRef}
            className="uploadInput"
            placeholder="Photo"
            onChange={handleFileChange}
          ></input>

          <div>
            <img src={deleteIcon} alt="image"></img>
            <img src={uploadIcon} alt="image" onClick={handleIconClick}></img>
          </div>
        </div>
        <label>Photo 3</label>
        <div className="photoDeleteUpload">
          <input
            type="file"
            ref={fileInputRef}
            className="uploadInput"
            placeholder="Photo"
            onChange={handleFileChange}
          ></input>

          <div>
            <img src={deleteIcon} alt="image"></img>
            <img src={uploadIcon} alt="image" onClick={handleIconClick}></img>
          </div>
        </div>
        <label>Photo 4</label>
        <div className="photoDeleteUpload">
          <input
            type="file"
            ref={fileInputRef}
            className="uploadInput"
            placeholder="Photo"
            onChange={handleFileChange}
          ></input>
          <div>
            <img src={deleteIcon} alt="image"></img>
            <img src={uploadIcon} alt="image" onClick={handleIconClick}></img>
          </div>
        </div>
        <label>The post</label>
        <textarea className="thePostText"></textarea>
        <button className="addButton">Edit</button>
      </div>
    </div>
  );
}
