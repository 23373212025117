import uploadIcon from '../Assets/plus.png';
import { Accordion, Icon, Label, Loader } from 'semantic-ui-react';

const CourseContentCard = ({
  courseContentsData,
  addSectionsHandler,
  editSectionTitle,
  addToSectionHandler,
  editSectionContent,
  deleteSectionContent,
  deleteLoading,
  deleteSectionHandler,
}) => {
  return (
    <div className="editCourseContentCard">
      <div className="font-bold mb-5 flex justify-between items-center">
        Course Content{' '}
        <img src={uploadIcon} alt="upload Icon" onClick={addSectionsHandler} style={{ cursor: 'pointer' }}></img>
      </div>
      {courseContentsData?.map((section, sectionIndex) => {
        return (
          <details key={sectionIndex}>
            <summary className="mb-4 font-bold text-lg">
              <span
                className="mx-1"
                onClick={() => {
                  editSectionTitle(section.title, sectionIndex, section.id);
                }}
              >
                <Icon name="pencil" color="blue" />
              </span>
              {section.title}
              <span className="flex justify-end items-center">
                <Label
                  as="button"
                  content="Add"
                  icon="add"
                  color="teal"
                  onClick={() => {
                    addToSectionHandler(sectionIndex, section);
                  }}
                />
                <Label
                  as="button"
                  content="Delete"
                  icon="remove"
                  color="red"
                  onClick={() => {
                    deleteSectionHandler(sectionIndex, section);
                  }}
                />
              </span>
            </summary>
            <Accordion
              defaultActiveIndex={[0]}
              styled
              fluid
              exclusive={false}
              panels={section.content?.map((content, index) => {
                return {
                  key: index,
                  title: {
                    content: (
                      <>
                        {content.TYPE === 'EXAM' ? (
                          <Label icon="pencil alternate" color="pink" content="EXAM" />
                        ) : content.TYPE === 'VIDEO' ? (
                          <Label icon="video" color="blue" content="VIDEO" />
                        ) : (
                          <Label icon="file text" color="teal" content="ARTICLE" />
                        )}
                        <span className="ms-3">{content.title}</span>
                        <span
                          className="float-right"
                          onClick={() => {
                            deleteSectionContent(sectionIndex, index, content);
                          }}
                        >
                          <span
                            className={`${deleteLoading?.loading && deleteLoading?.id === content?.id ? 'hidden' : ''}`}
                          >
                            <Icon name="delete" color="red" />
                          </span>
                          <Loader
                            active={deleteLoading?.loading && deleteLoading?.id === content?.id}
                            inline
                            size="tiny"
                          />
                        </span>
                        <span
                          className="float-right"
                          onClick={() => {
                            editSectionContent(sectionIndex, index, content);
                          }}
                        >
                          <Icon name="pencil" color="blue" />
                        </span>
                      </>
                    ),
                  },
                  content: content.description,
                };
              })}
            />
          </details>
        );
      })}{' '}
    </div>
  );
};

export default CourseContentCard;
