import React from 'react';
import FullNameConcat from '../../Utils/FullNameConcat';
import love from '../../Assets/love.png';
import comment from '../../Assets/u_comment.png';
import convertDateToDateString from '../../Utils/convertDateToDateString';

export default function RoommatePostCard({ post, handleSelectPost }) {
  return (
    <article key={post.post_id} onClick={() => handleSelectPost(post.post_id)} className="bg-white p-5 rounded-md">
      <header className="flex items-center">
        <div>
          <img
            src={post.user.avatar}
            crossOrigin="anonymous"
            width={40}
            height={40}
            className="rounded-full"
            alt="User Avatar"
          />
        </div>
        <div className="ml-2">
          <h3 className="font-bold">{FullNameConcat(post.user)}</h3>
          <span className="text-gray-600">{post.user.username}</span>
        </div>
      </header>

      <span className="text-gray-600">{convertDateToDateString(post.createdAt)}</span>

      <p className="text-lg my-2">{post.text}</p>

      <div className="flex overflow-x-auto space-x-2 no-scrollbar w-full md:w-1/2 mx-auto justify-between">
        {post.images?.map((image, imgIndex) => (
          <img
            key={imgIndex}
            src={image}
            crossOrigin="anonymous"
            className="w-2/4 object-cover rounded-lg mb-2 mx-auto"
            alt={`${imgIndex + 1}`}
          />
        ))}
      </div>

      <div className="flex justify-between mt-4">
        <div className="flex items-center gap-2 text-gray-600">
          <div className="flex items-center gap-1">
            <img src={love} alt="Love Icon" height={20} width={20} />
            <span>{post.likes_count}</span>
          </div>
          <div className="flex items-center gap-1">
            <img src={comment} alt="Comment Icon" height={20} width={20} />
            <span>{post.comments_count}</span>
          </div>
        </div>
      </div>
    </article>
  );
}
