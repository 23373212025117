import Sidebar from '../Components/Sidebar';
import cursor from '../Assets/arrowframe.png';
import '../Styles/Supportchat.css';
import React, { useEffect, useContext } from 'react';
import messageSend from '../Assets/messagesend.png';
import { useParams } from 'react-router-dom';
import FullNameConcat from '../Utils/FullNameConcat';
import MessageCard from '../Components/Support/Chat/MessageCard';
import { BiSolidImageAdd } from 'react-icons/bi';
import useSupportChat from '../Hooks/Support/useSupportChat';

export default function SupportChat() {
  const { id } = useParams();

  const { data, handleChange, handleSendMessage, handleUploadImage, inputRef, windowRef, handleBack } = useSupportChat({
    id,
  });
  console.log(data);
  useEffect(() => {
    if (windowRef && windowRef.current) {
      windowRef.current.scrollTop = windowRef.current.scrollHeight;
    }
  }, [data.messages]);

  return (
    <div className="supportChat">
      <Sidebar></Sidebar>
      {!data.user ? (
        <div>Loading...</div>
      ) : (
        <div className="supportChatContent">
          <header className="flex justify-between items-center w-full">
            <div className="flex items-center bg-white rounded-lg justify-start p-4 gap-x-4 w-full">
              <img onClick={handleBack} className="cursor-pointer w-12 h-12" src={cursor} alt="cursor" />
              <div className="flex items-center gap-4">
                <img src={data.user.avatar} alt="profile" className="w-12 h-12 rounded-full" />
                <h1 className="text-3xl font-bold">{FullNameConcat(data.user)}</h1>
              </div>
            </div>
          </header>
          <div className="chat-container">
            <div ref={windowRef} className="messages">
              {data.messages?.map((message, index) => {
                return (
                  <MessageCard
                    key={index}
                    message={message}
                    previousMessage={index > 0 ? data.messages[index - 1] : null}
                    user={data.user}
                    support={data.support}
                  />
                );
              })}
            </div>
            <div className="w-4/6 bg-white flex items-center justify-between p-4 mx-auto rounded-2xl my-4">
              <div className="flex items-center gap-4 w-full">
                <BiSolidImageAdd
                  className="text-4xl cursor-pointer text-gray-700"
                  onClick={() => {
                    document.getElementById('file').click();
                  }}
                />
                <input type="file" id="file" className="hidden" onChange={handleUploadImage} />
                <input
                  className="bg-white w-full p-2 outline-none border-none text-xl"
                  ref={inputRef}
                  type="text"
                  value={data.newMessage}
                  onChange={handleChange}
                  placeholder="Message.."
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      handleSendMessage();
                    }
                  }}
                />
              </div>
              <button className="outline-none border-none cursor-pointer" onClick={handleSendMessage}>
                {' '}
                <img src={messageSend} alt="Send" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
