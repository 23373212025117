import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import './ModalTwo.css';

export default function BasicModalTwo({ question, buttonName, onAccept, bgColor }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="ModalTwo">
      <button className="ModalTwoButton" style={bgColor ? { backgroundColor: bgColor } : null} onClick={handleOpen}>
        {buttonName}
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal">
          <Typography id="modal-modal-title" component="h2">
            {question}
          </Typography>
          <div className="yesNoButtons">
            <button
              onClick={() => {
                onAccept();
                handleClose();
              }}
            >
              Yes
            </button>
            <button onClick={handleClose}>No</button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
