import { useState, useEffect, useContext, useReducer } from 'react';
import { getUsers, searchUsers } from '../APIs/UserAPIs';
import ToastContext from '../Context/ToastContext';
const initialState = {
  data: [],
  searchData: [],
  maxCount: 0,
  loading: true,
  role: 'STUDENT',
  input: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_DATA':
      return { ...state, data: action.payload, searchData: [] };
    case 'SET_SEARCH_DATA':
      return { ...state, searchData: action.payload, data: [] };
    case 'SET_MAX_COUNT':
      return { ...state, maxCount: action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_ROLE':
      return { ...state, role: action.payload };
    case 'SET_INPUT':
      return { ...state, input: action.payload };
    default:
      throw new Error();
  }
}

const useProfiles = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { addToasts } = useContext(ToastContext);

  const handleSearch = async () => {
    if (state.input) {
      try {
        const searchQuery = { skip: 0, role: state.role, text: state.input };
        const usersResponse = await searchUsers(searchQuery);
        const { users, numberOfUsers } = usersResponse.data.data;
        dispatch({ type: 'SET_MAX_COUNT', payload: numberOfUsers });
        dispatch({ type: 'SET_SEARCH_DATA', payload: users });
      } catch (err) {
        addToasts({
          role: 'danger',
          heading: err.response.data.msg || 'Something went wrong',
        });
      }
    } else {
      dispatch({ type: 'SET_SEARCH_DATA', payload: [] });
      fetchMoreData();
    }
  };

  const handleMoreSearch = async () => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const searchQuery = { skip: state.searchData.length, role: state.role, text: state.input };
      const usersResponse = await searchUsers(searchQuery);
      const { users, numberOfUsers } = usersResponse.data.data;
      dispatch({ type: 'SET_MAX_COUNT', payload: numberOfUsers });
      dispatch({ type: 'SET_SEARCH_DATA', payload: [...state.searchData, ...users] });
      dispatch({ type: 'SET_LOADING', payload: false });
    } catch (err) {
      addToasts({
        role: 'danger',
        heading: err.response.data.msg || 'Something went wrong',
      });
    }
  };

  const fetchMoreData = async () => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      let searchQuery = { skip: state.data.length, role: state.role };
      if (state.data.length && state.data[0].role !== state.role) {
        searchQuery = { skip: 0, role: state.role };
      }
      const user = await getUsers(searchQuery);
      const { users, numberOfUsers } = user.data.data;
      dispatch({ type: 'SET_MAX_COUNT', payload: numberOfUsers });
      if (searchQuery.skip === 0) {
        dispatch({ type: 'SET_DATA', payload: users });
      } else {
        dispatch({ type: 'SET_DATA', payload: [...state.data, ...users] });
      }
      dispatch({ type: 'SET_LOADING', payload: false });
    } catch (err) {
      console.error(err);
      addToasts({
        role: 'danger',
        heading: err.response.data.msg || 'Something went wrong',
      });
    }
  };

  const setRole = role => {
    dispatch({ type: 'SET_ROLE', payload: role });
  };

  const handleInputChange = e => {
    dispatch({ type: 'SET_INPUT', payload: e.target.value });
  };

  //  handle scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
      if (state.searchData.length < state.maxCount && state.input) {
        handleMoreSearch();
      } else if (state.data.length < state.maxCount) {
        fetchMoreData();
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [state.input, state.role, state.data, state.searchData, state.maxCount]);

  useEffect(() => {
    handleSearch();
  }, [state.input, state.role]);

  useEffect(() => {
    fetchMoreData();
  }, [state.role]);

  return {
    data: state.data,
    searchData: state.searchData,
    maxCount: state.maxCount,
    loading: state.loading,
    role: state.role,
    input: state.input,
    dispatch,
    handleSearch,
    fetchMoreData,
    handleInputChange,
    setRole,
  };
};

export default useProfiles;
