import { useState, useEffect, useContext } from 'react';
import { searchUniversities, getUniversities } from '../APIs/UniversityAPIs';
import ToastContext from '../Context/ToastContext';

const useSearchUniversity = initialInput => {
  const { addToasts } = useContext(ToastContext);
  const [input, setInput] = useState(initialInput);
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [data, setData] = useState([]);
  const [maxCount, setMaxCount] = useState(0);

  const handleSearch = async i => {
    try {
      setLoading(true);
      let searchQuery = { skip: 0, text: i };
      const university = await searchUniversities(searchQuery);
      const { universities, numberOfUniversities } = university.data.data;
      setMaxCount(numberOfUniversities);
      setSearchData(universities);
    } catch (err) {
      addToasts({
        type: 'danger',
        body: 'Unexpected error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = e => {
    setInput(e.target.value);
  };

  useEffect(() => {
    if (input) {
      handleSearch(input);
    } else {
      setSearchData([]);
      fetchMoreData();
    }
  }, [input]);

  const fetchMoreData = async () => {
    try {
      setLoading(true);
      let searchQuery = { skip: data.length };
      const university = await getUniversities(searchQuery);
      if (university.status !== 200) {
        addToasts({
          type: 'danger',
          body: 'Network error',
        });
      }
      const { universities, numberOfUniversities } = university.data.data;
      setMaxCount(numberOfUniversities);
      setData(prevData => [...prevData, ...universities]);
    } catch (err) {
      addToasts({
        type: 'danger',
        body: 'Unexpected error',
      });
    } finally {
      setLoading(false);
    }
  };

  return { loading, searchData, data, maxCount, fetchMoreData, handleSearch, handleInputChange };
};

export default useSearchUniversity;
