import Sidebar from '../Components/Sidebar.js';
import '../Styles/Meals.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { getUserMealsType } from '../APIs/UserAPIs.js';
import ToastContext from '../Context/ToastContext.js';
import LoadingSpinner from '../Components/LoadingSpinner.js';

export default function Chef() {
  const {
    state: { userId },
  } = useLocation();
  const [data, setData] = useState([]);
  const { addToasts } = useContext(ToastContext);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('Requests');
  const [maxCount, setMaxCount] = useState(0);
  const navigate = useNavigate();

  const handleSelectMeal = e => {
    navigate('/meals/meal', {
      state: { id: e.target.id },
    });
  };

  //  handle fetch data
  const fetchMoreData = async () => {
    try {
      setLoading(true);
      let searchQuery;
      if (type == 'Requests') {
        searchQuery = {
          skip: data.length,
          accepted: false,
          rejected: false,
        };
      } else if (type == 'Acceptable requests') {
        searchQuery = {
          skip: data.length,
          accepted: true,
          rejected: false,
        };
      } else {
        searchQuery = {
          skip: data.length,
          accepted: false,
          rejected: true,
        };
      }
      searchQuery.creator = userId;
      if (data.length && data[0].type != type) {
        searchQuery.skip = 0;
      }

      const mealsResponse = await getUserMealsType(searchQuery);
      const meals = mealsResponse.data.data?.meals;
      const numberOfMeals = mealsResponse.data.data?.numberOfMeals;
      setMaxCount(numberOfMeals);
      if (searchQuery.skip === 0) {
        setData([...meals]);
      } else {
        setData([...data, ...meals]);
      }
      setLoading(false);
    } catch (err) {
      addToasts({
        type: 'danger',
        body: 'unexpected error',
      });
    }
  };

  //  handle fetch data
  useEffect(() => {
    fetchMoreData();
  }, [type]);

  //  handle scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
        if (loading || data.length >= maxCount) return;
        fetchMoreData();
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, data, maxCount]);

  return (
    <div>
      {' '}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div id="courses">
          <Sidebar></Sidebar>
          <div id="coursescontent">
            <div className="eventcard1">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div className="eventslink">
                  <span className={`${type == 'Requests' ? 'selected' : ''}`} onClick={() => setType('Requests')}>
                    Requests
                  </span>
                  <span
                    className={`${type == 'Acceptable requests' ? 'selected' : ''}`}
                    onClick={() => setType('Acceptable requests')}
                  >
                    Acceptable requests
                  </span>
                  <span
                    className={`${type == 'Rejected requests' ? 'selected' : ''}`}
                    onClick={() => setType('Rejected requests')}
                  >
                    Rejected requests
                  </span>
                </div>
                <div
                  className="primary"
                  onClick={() => navigate('/meals/chef/orders', { state: { userId } })}
                  style={{ cursor: 'pointer' }}
                >
                  Orders
                </div>
              </div>

              <div>
                <span>Meals</span>
              </div>
            </div>
            <div className="mealscards">
              {data?.map(meal => {
                return (
                  <div
                    key={meal._id}
                    id={meal._id}
                    onClick={handleSelectMeal}
                    style={{ cursor: 'pointer' }}
                    className="mealcard2"
                  >
                    <img id={meal._id} src={meal.photo} alt="" className="mealsphoto"></img>
                    <div id={meal._id}>{meal.title}</div>
                    <div id={meal._id}>
                      <span id={meal._id}>{meal.price} EGP</span>
                      <span id={meal._id}>{meal.deliveryFee} EGP delivery fee</span>
                    </div>
                    <div id={meal._id}>
                      <img id={meal._id} src={meal.creator.avatar} alt="text" width={40} height={40}></img>
                      <span id={meal._id}>{meal.creator.name}</span>
                    </div>
                    <div id={meal._id}>{meal.address}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
