import Sidebar from '../Components/Sidebar';
import '../Styles/Meals.css';
import LoadingSpinner from '../Components/LoadingSpinner';
import '../Components/Searchbar.css';
import MealCard from '../Components/Pages/MealPage/MealCard/MealCard.js';
import MealsHook from '../Hooks/MealsHook.js';
import Layout from '../Components/_common/Layout.jsx';
export default function Meals() {
  const [
    input,
    type,
    loading,
    data,
    searchData,
    handleInputChange,
    mealsNavigationSelectHandler,
    navigate,
    handleSelectMeal,
  ] = MealsHook();

  return (
    <Layout
      title="Meals"
      Action={
        <div className="SAS">
          <div className="content">
            <div className="search">
              <div className="searchbutton"></div>
              <input placeholder="Search" value={input} onChange={handleInputChange}></input>
            </div>
          </div>
        </div>
      }
    >
      <div id="w-full flex">
        <div className="eventcard1">
          <div className="flex justify-between items-center">
            <ul className="mealLinks list-none flex">
              <li
                className={`${type === 'Requests' ? 'selected' : ''}`}
                id="Requests"
                onClick={mealsNavigationSelectHandler}
              >
                New Requests
              </li>
              <li
                className={`${type === 'Acceptable requests' ? 'selected' : ''}`}
                id="Acceptable requests"
                onClick={mealsNavigationSelectHandler}
              >
                Accepted requests
              </li>
              <li
                className={`${type === 'Rejected requests' ? 'selected' : ''}`}
                id="Rejected requests"
                onClick={mealsNavigationSelectHandler}
              >
                Rejected requests
              </li>
            </ul>
            <div className="primary" onClick={() => navigate('/meals/orderstodo')} style={{ cursor: 'pointer' }}>
              Orders
            </div>
          </div>
        </div>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div className="mealscards">
            {input
              ? searchData?.map(meal => {
                  return <MealCard key={meal.id} meal={meal} handleSelectMeal={handleSelectMeal} />;
                })
              : data?.map(meal => {
                  return <MealCard key={meal.id} meal={meal} handleSelectMeal={handleSelectMeal} />;
                })}
          </div>
        )}
      </div>
    </Layout>
  );
}
