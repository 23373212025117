import { useContext, useEffect, useRef, useState } from 'react';
import Sidebar from '../Components/Sidebar';
import '../Styles/EditCourse.css';
import '../Styles/AddLessonVideo.css';
import uploadIcon from '../Assets/plus.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { upload } from '../APIs/UploadAPIs';
import addIcon from '../Assets/Frame 48095608 (1).png';
import cursor from '../Assets/arrowframe.png';
import { Label } from 'semantic-ui-react';
import {
  addArticle,
  addExam,
  addVideo,
  createSingleQuestion,
  deleteSingleQuestion,
  getExamQuestionsAndAnswers,
  updateArticle,
  updateExam,
  updateSingleQuestion,
  updateWeekContent,
} from '../APIs/CourseAPIs';
import config from '../Config/Config';
import ToastContext from '../Context/ToastContext';

export default function AddLesson() {
  const {
    state: {
      sections,
      index,
      course,
      sectionIndex,
      contentData,
      contentIndex,
      contentType,
      contentID,
      courseID,
      editSectionContentType,
      editSectionContentID,
    },
  } = useLocation();

  const { addToasts } = useContext(ToastContext);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('No selected file');
  const fileInputRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState('Article');
  const [newQuestion, setNewQuestions] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [questionOption, setQuestionOption] = useState('option1');
  const [questionType, setQuestionType] = useState('One answer');
  const [questionAnswer, setQuestionAnswer] = useState('answerOne');
  const [questionTitle, setQuestionTitle] = useState('');
  const [answerOne, setAnswerOne] = useState('');
  const [answerTwo, setAnswerTwo] = useState('');
  const [answerThree, setAnswerThree] = useState('');
  const [answerFour, setAnswerFour] = useState('');
  const [questionId, setQuestionId] = useState(null);

  const editQuestionHandler = (event, questionID) => {
    setQuestionId(questionID);
    setNewQuestions(false);
    setSelectedQuestion(event.target.id + 1);
    setQuestionTitle(contentData?.id ? questions[event.target.id]?.question : questions[event.target.id]?.title);
    setAnswerOne(contentData?.id ? questions[event.target.id]?.options[0] : questions[event.target.id]?.answerOne);
    setAnswerTwo(contentData?.id ? questions[event.target.id]?.options[1] : questions[event.target.id]?.answerTwo);
    setAnswerThree(contentData?.id ? questions[event.target.id]?.options[2] : questions[event.target.id]?.answerThree);
    setAnswerFour(contentData?.id ? questions[event.target.id]?.options[3] : questions[event.target.id]?.answerFour);

    if (contentData) {
      let ans = [];
      if (questions[event.target.id]?.correct_answer.includes(questions[event.target.id].options[0])) {
        ans.push('option1');
      }
      if (questions[event.target.id]?.correct_answer.includes(questions[event.target.id].options[1])) {
        ans.push('option2');
      }
      if (questions[event.target.id]?.correct_answer.includes(questions[event.target.id].options[2])) {
        ans.push('option3');
      }
      if (questions[event.target.id]?.correct_answer.includes(questions[event.target.id].options[3])) {
        ans.push('option4');
      }
      setQuestionOption(ans);
      setQuestionType(questions[event.target.id]?.multiple_choice ? 'More than 1 answer' : 'One answer');
      return;
    }

    let ans = [];
    if (questions[event.target.id].correctAnswer.includes(questions[event.target.id].answerOne)) {
      ans.push('option1');
    }
    if (questions[event.target.id].correctAnswer.includes(questions[event.target.id].answerTwo)) {
      ans.push('option2');
    }
    if (questions[event.target.id].correctAnswer.includes(questions[event.target.id].answerThree)) {
      ans.push('option3');
    }
    if (questions[event.target.id].correctAnswer.includes(questions[event.target.id].answerFour)) {
      ans.push('option4');
    }
    setQuestionOption(ans);
    setQuestionType(ans.length === 1 ? 'One answer' : 'More than 1 answer');
  };

  const deleteQuestionHandler = async questionID => {
    if (contentData?.id) {
      try {
        await deleteSingleQuestion(questionID);
        await getExamQuestionsAndAnswers(contentData?.id).then(res => {
          if (res.status === 200) {
            setQuestions(res.data.data.questions);
            setQuestionTitle(res.data.data.title);
            addToasts({
              type: 'success',
              body: 'Question deleted successfully',
            });
          }
        });
      } catch (err) {
        if (config.MODE === 'development') console.error(err);
      }
      return;
    }
  };
  const lessonValid =
    ((selectedOption === 'Video' && file && description.trim()) ||
      (selectedOption === 'Article' && description.trim()) ||
      (selectedOption === 'Exam' && questions && description.trim() && questions.length)) &&
    title.trim();

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleQuestionOption = e => {
    if (questionType === 'One answer') {
      setQuestionOption([e.target.value]);
    } else {
      questionOption.includes(e.target.value)
        ? setQuestionOption(questionOption.filter(op => op !== e.target.value))
        : setQuestionOption([...questionOption, e.target.value]);
    }
  };

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (!file) return;
    if (!file.type.includes('video')) {
      addToasts({
        type: 'warning',
        body: `Only video files are allowed`,
      });
      setFile(null);
      return;
    }
    if (file.size > config.MAX_VIDEO_SIZE) {
      addToasts({
        type: 'warning',
        body: `File size should be less than 2MB`,
      });
      setFile(null);
      return;
    }
    setFile(file);
    file && setFileName(file.name);
  };

  const navigate = useNavigate();

  const handleOptionChange = event => {
    if (selectedOption === event.target.value) return;
    setSelectedOption(event.target.value);
    setTitle('');
    setDescription('');
    setFile(null);
    setFileName('');
  };

  const handleQuestionType = event => {
    setQuestionType(event.target.value);
    if (event.target.value === 'One answer') {
      setQuestionOption(['option1']);
    } else {
      setQuestionOption([]);
    }
  };

  useEffect(() => {
    if ((contentType && contentData) || (editSectionContentType && editSectionContentID)) {
      if (contentType === 'Video' || editSectionContentType === 'Video') {
        setSelectedOption('Video');
        setTitle(contentData?.title);
        setDescription(contentData?.description);
      } else if (contentType === 'Article' || editSectionContentType === 'Article') {
        setSelectedOption('Article');
        setTitle(contentData?.title);
        setDescription(contentData?.description);
      } else if (contentType === 'Exam' || editSectionContentType === 'Exam') {
        setSelectedOption('Exam');
        setTitle(contentData?.title);
        setDescription(contentData?.description);
        getExamQuestionsAndAnswers(contentData?.id).then(res => {
          if (res.status === 200) {
            setQuestions(res.data.data.questions);
            setQuestionTitle(res.data.data.title);
          }
        });
      }
    }
  }, []);

  return (
    <div className="AddLesson">
      <Sidebar></Sidebar>
      <div className="firsts">
        <div className="topCard">
          <div>
            <img
              onClick={e => {
                e.preventDefault();
                if (contentID || editSectionContentID) {
                  navigate('/courses/course/editcourse', {
                    state: {
                      data: { id: courseID },
                    },
                  });
                  return;
                }
                navigate('/courses/addcourse', {
                  state: {
                    sections: sections,
                    course,
                  },
                });
              }}
              className="cursorIcon"
              src={cursor}
              height={45}
              width={45}
              alt="back icon"
            ></img>
            <div>Add Course Lesson</div>
          </div>
          <div></div>
        </div>
      </div>
      <div className="AddLessonContent grid lg:grid-cols-2 pb-5">
        <div className="columnOne lg:col-span-1 max-lg:me-[40px]">
          <div className="lessonInfo">
            <label>Type</label>
            <div className="typeOfLesson my-3">
              <label
                className={
                  (selectedOption == 'Video' ? 'radio-label selected' : 'radio-label') +
                  (contentType && contentType !== 'Video' ? ' hidden' : '')
                }
              >
                <input
                  type="radio"
                  name="options"
                  value="Video"
                  checked={selectedOption === 'Video'}
                  onChange={handleOptionChange}
                  disabled={
                    contentType ? contentType !== 'Video' : editSectionContentType && editSectionContentType !== 'Video'
                  }
                />
                Video
              </label>
              <label
                className={
                  selectedOption == 'Article'
                    ? 'radio-label selected'
                    : 'radio-label' + (contentType && contentType !== 'Article' ? ' hidden' : '')
                }
              >
                <input
                  type="radio"
                  name="options"
                  value="Article"
                  checked={selectedOption === 'Article'}
                  onChange={handleOptionChange}
                  disabled={
                    contentType
                      ? contentType !== 'Article'
                      : editSectionContentType && editSectionContentType !== 'Article'
                  }
                />{' '}
                Article
              </label>
              <label
                className={
                  selectedOption == 'Exam'
                    ? 'radio-label selected'
                    : 'radio-label' + (contentType && contentType !== 'Exam' ? ' hidden' : '')
                }
              >
                <input
                  type="radio"
                  name="options"
                  value="Exam"
                  checked={selectedOption === 'Exam'}
                  onChange={handleOptionChange}
                  disabled={
                    contentType ? contentType !== 'Exam' : editSectionContentType && editSectionContentType !== 'Exam'
                  }
                />{' '}
                Exam
              </label>
            </div>
            <label htmlFor="titleOfLesson"> Title</label>
            <input
              value={title}
              onChange={e => {
                setTitle(e.target.value);
              }}
              id="titleOfLesson"
              placeholder="Title of lesson"
            ></input>
            {(selectedOption === 'Video' || selectedOption === 'Article' || selectedOption == 'Exam') && (
              <>
                <label htmlFor="description">Description</label>
                <input
                  value={description}
                  onChange={e => {
                    setDescription(e.target.value);
                  }}
                  id="description"
                  placeholder="Description"
                ></input>
              </>
            )}
            {selectedOption === 'Video' && (
              <>
                {' '}
                <label htmlFor="description">Video</label>
                <div className="photoUpload">
                  <div className="uploadCustom" placeholder="hello">
                    <span style={{ color: '#116ACC' }}>{fileName}</span>
                    <input
                      style={{ display: 'none' }}
                      type="file"
                      ref={fileInputRef}
                      className="uploadInput"
                      placeholder="Photo"
                      onChange={handleFileChange}
                    ></input>
                  </div>
                  <img src={uploadIcon} alt="upload icon" onClick={handleIconClick}></img>
                </div>
              </>
            )}
            <button
              disabled={!lessonValid}
              onClick={async e => {
                let tmp = { title };
                if (selectedOption === 'Video') {
                  let formData = new FormData();
                  formData.append('files', file);
                  let res = await upload(formData);
                  if (res.status === 200) {
                    const { fileLinks } = await res.data;
                    if (fileLinks) {
                      if (contentID) {
                        try {
                          // add video to existing course
                          const res = await addVideo(contentID, {
                            title: title,
                            description: description,
                            video_link: fileLinks,
                          });
                          addToasts({
                            type: 'success',
                            body: 'Video content added successfully',
                          });
                          navigate('/courses/course/editcourse', {
                            state: {
                              data: { id: courseID },
                            },
                          });
                          return;
                        } catch (err) {
                          if (config.MODE === 'development') console.error(err);
                        }
                      }
                      // update video content in existing course
                      if (editSectionContentID) {
                        try {
                          const res = await updateWeekContent(editSectionContentID, {
                            title: title,
                            description: description,
                            video_link: fileLinks,
                          });
                          addToasts({
                            type: 'success',
                            body: 'Video content updated successfully',
                          });
                          navigate('/courses/course/editcourse', {
                            state: {
                              data: { id: courseID },
                            },
                          });
                          return;
                        } catch (err) {
                          if (config.MODE === 'development') console.error(err);
                        }
                      }
                      tmp.link = fileLinks[0];
                    }
                  }
                  // add video while creating new course
                  tmp.description = description;
                } else if (selectedOption === 'Article') {
                  // add article to existing course
                  if (contentID) {
                    try {
                      const res = await addArticle(contentID, {
                        title: title,
                        description: description,
                      });
                      addToasts({
                        type: 'success',
                        body: 'Article content added successfully',
                      });
                      navigate('/courses/course/editcourse', {
                        state: {
                          data: { id: courseID },
                        },
                      });
                      return;
                    } catch (err) {
                      if (config.MODE === 'development') console.error(err);
                    }
                  }
                  // update article content in existing course
                  if (editSectionContentID) {
                    try {
                      await updateArticle(editSectionContentID, {
                        title: title,
                        description: description,
                      });
                      addToasts({
                        type: 'success',
                        body: 'Article content updated successfully',
                      });
                      navigate('/courses/course/editcourse', {
                        state: {
                          data: { id: courseID },
                        },
                      });
                      return;
                    } catch (err) {
                      if (config.MODE === 'development') console.error(err);
                    }
                  }
                  // add article while creating new course
                  tmp.description = description;
                } else {
                  // add exam to existing course
                  if (contentID) {
                    try {
                      const allQuestions = questions?.map(q => {
                        return {
                          question: q.title,
                          correct_answer: q.correctAnswer,
                          options: [q.answerOne, q.answerTwo, q.answerThree, q.answerFour],
                          multiple_choice: q.correctAnswer.length > 1,
                        };
                      });
                      await addExam(contentID, {
                        title: title,
                        description: description,
                        questions: allQuestions,
                      });
                      addToasts({
                        type: 'success',
                        body: 'Exam content added successfully',
                      });
                      navigate('/courses/course/editcourse', {
                        state: {
                          data: { id: courseID },
                        },
                      });
                      return;
                    } catch (err) {
                      if (config.MODE === 'development') console.error(err);
                    }
                  }
                  // update exam content in existing course
                  if (editSectionContentID) {
                    try {
                      await updateExam(editSectionContentID, {
                        title: title,
                        description: description,
                      });
                      addToasts({
                        type: 'success',
                        body: 'Exam content updated successfully',
                      });
                      navigate('/courses/course/editcourse', {
                        state: {
                          data: { id: courseID },
                        },
                      });
                      return;
                    } catch (err) {
                      if (config.MODE === 'development') console.error(err);
                    }
                  }
                  // add exam while creating new course
                  tmp.questions = questions;
                  tmp.description = description;
                }
                if (contentData) {
                  sections[sectionIndex].content[contentIndex] = tmp;
                  navigate('/courses/addcourse', {
                    state: { sections: sections, course },
                  });
                  return;
                }
                sections[Number(index)].content.push(tmp);
                navigate('/courses/addcourse', {
                  state: { sections: sections, course },
                });
              }}
              className="addButton"
            >
              {contentData ? 'Save' : 'Add'}
            </button>
          </div>
          {selectedOption === 'Exam' && (selectedQuestion || newQuestion) && (
            <div className="typeOfQeustion">
              <label>Type of question</label>
              <div className="typeOfQuestionOptions">
                <label className="me-5">
                  <input
                    type="radio"
                    name="typeOptions"
                    value="One answer"
                    checked={questionType === 'One answer'}
                    onChange={handleQuestionType}
                    className="usedRadio"
                  />{' '}
                  One answer
                </label>
                <label>
                  <input
                    type="radio"
                    name="typeOptions"
                    value="More than 1 answer"
                    checked={questionType === 'More than 1 answer'}
                    onChange={handleQuestionType}
                    className="usedRadio"
                  />{' '}
                  More than 1 answer
                </label>
              </div>
              <label>The question</label>
              <input
                className="questionInput"
                value={questionTitle}
                onChange={e => {
                  setQuestionTitle(e.target.value);
                }}
                placeholder="The question"
              ></input>
              <label>Answer 1</label>
              <input
                className="questionInput"
                placeholder="The question"
                value={answerOne}
                onChange={e => {
                  setAnswerOne(e.target.value);
                }}
              ></input>
              <label>
                <input
                  type={questionType === 'One answer' ? 'radio' : 'checkbox'}
                  name="َquestionAnswers"
                  value="option1"
                  checked={questionOption.includes('option1')}
                  onChange={handleQuestionOption}
                  className="usedRadio"
                />{' '}
                Mark this as Correct Answer
              </label>
              <label>Answer 2</label>
              <input
                className="questionInput"
                placeholder="The question"
                value={answerTwo}
                onChange={e => {
                  setAnswerTwo(e.target.value);
                }}
              ></input>
              <label>
                <input
                  type={questionType === 'One answer' ? 'radio' : 'checkbox'}
                  name="َquestionAnswers"
                  value="option2"
                  checked={questionOption.includes('option2')}
                  onChange={handleQuestionOption}
                  className="usedRadio"
                />{' '}
                Mark this as Correct Answer
              </label>
              <label>Answer 3</label>
              <input
                className="questionInput"
                placeholder="The question"
                value={answerThree}
                onChange={e => {
                  setAnswerThree(e.target.value);
                }}
              ></input>
              <label>
                <input
                  type={questionType === 'One answer' ? 'radio' : 'checkbox'}
                  name="َquestionAnswers"
                  value="option3"
                  checked={questionOption.includes('option3')}
                  onChange={handleQuestionOption}
                  className="usedRadio"
                />{' '}
                Mark this as Correct Answer
              </label>
              <label>Answer 4</label>
              <input
                className="questionInput"
                placeholder="The question"
                value={answerFour}
                onChange={e => {
                  setAnswerFour(e.target.value);
                }}
              ></input>
              <label>
                <input
                  type={questionType === 'One answer' ? 'radio' : 'checkbox'}
                  name="َquestionAnswers"
                  value="option4"
                  checked={questionOption.includes('option4')}
                  onChange={handleQuestionOption}
                  className="usedRadio"
                />{' '}
                Mark this as Correct Answer
              </label>
              <button
                onClick={async () => {
                  if (newQuestion) {
                    let ans = [];
                    if (questionOption.includes('option4')) {
                      ans.push(answerFour);
                    }
                    if (questionOption.includes('option3')) {
                      ans.push(answerThree);
                    }
                    if (questionOption.includes('option2')) {
                      ans.push(answerTwo);
                    }
                    if (questionOption.includes('option1')) {
                      ans.push(answerOne);
                    }
                    if (contentData?.id) {
                      try {
                        const res = await createSingleQuestion({
                          question: questionTitle,
                          options: [answerOne, answerTwo, answerThree, answerFour],
                          multiple_choice: questionType === 'More than 1 answer',
                          correct_answer: ans,
                          exam_id: editSectionContentID,
                        });
                        await getExamQuestionsAndAnswers(contentData?.id).then(res => {
                          if (res.status === 200) {
                            setQuestions(res.data.data.questions);
                            setQuestionTitle(res.data.data.title);

                            setNewQuestions(false);
                            setSelectedQuestion(0);
                            setQuestionTitle('');
                            setAnswerOne('');
                            setAnswerTwo('');
                            setAnswerThree('');
                            setAnswerFour('');
                            setQuestionOption(['option1']);
                            setQuestionType('One answer');

                            addToasts({
                              type: 'success',
                              body: `Question added successfully`,
                            });
                          }
                        });
                      } catch (err) {
                        if (config.MODE === 'development') console.error(err);
                      }
                      return;
                    }
                    setQuestions([
                      ...questions,
                      {
                        title: questionTitle,
                        answerOne,
                        answerTwo,
                        answerThree,
                        answerFour,
                        correctAnswer: ans,
                      },
                    ]);
                  } else {
                    let tmp = [];
                    for (let i in questions) {
                      if (selectedQuestion !== i + 1) {
                        tmp.push(questions[i]);
                      } else {
                        let ans = [];
                        if (questionOption.includes('option4')) {
                          ans.push(answerFour);
                        }
                        if (questionOption.includes('option3')) {
                          ans.push(answerThree);
                        }
                        if (questionOption.includes('option2')) {
                          ans.push(answerTwo);
                        }
                        if (questionOption.includes('option1')) {
                          ans.push(answerOne);
                        }
                        if (contentData?.id) {
                          try {
                            const res = await updateSingleQuestion(questionId, {
                              question: questionTitle,
                              options: [answerOne, answerTwo, answerThree, answerFour],
                              multiple_choice: questionType === 'More than 1 answer',
                              correct_answer: ans,
                              exam_id: editSectionContentID,
                            });
                            await getExamQuestionsAndAnswers(contentData?.id).then(res => {
                              if (res.status === 200) {
                                setQuestions(res.data.data.questions);
                                setQuestionTitle(res.data.data.title);

                                setNewQuestions(false);
                                setSelectedQuestion(0);
                                setQuestionTitle('');
                                setAnswerOne('');
                                setAnswerTwo('');
                                setAnswerThree('');
                                setAnswerFour('');
                                setQuestionOption(['option1']);
                                setQuestionType('One answer');

                                addToasts({
                                  type: 'success',
                                  body: `Question edited successfully`,
                                });
                              }
                            });
                          } catch (err) {
                            if (config.MODE === 'development') console.error(err);
                          }
                          return;
                        }
                        tmp.push({
                          title: questionTitle,
                          answerOne,
                          answerTwo,
                          answerThree,
                          answerFour,
                          correctAnswer: ans,
                        });
                      }
                    }
                    setQuestions(tmp);
                  }
                  setNewQuestions(false);
                  setSelectedQuestion(0);
                  setQuestionTitle('');
                  setAnswerOne('');
                  setAnswerTwo('');
                  setAnswerThree('');
                  setAnswerFour('');
                  setQuestionOption(['option1']);
                  setQuestionType('One answer');
                }}
                className="addButton"
              >
                {newQuestion ? 'Add' : 'Edit'}
              </button>
            </div>
          )}
        </div>
        {selectedOption === 'Exam' && (
          <div className="questionsColumn max-lg:ms-[90px]">
            <div>
              <div className="text-dark_gray font-bold">Questions</div>
              <img
                src={addIcon}
                alt="add icon"
                width={40}
                height={40}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setNewQuestions(true);
                  setSelectedQuestion(0);
                  setQuestionTitle('');
                  setAnswerOne('');
                  setAnswerTwo('');
                  setAnswerThree('');
                  setAnswerFour('');
                  setQuestionOption('option1');
                }}
              ></img>
            </div>
            {questions?.map((q, index) => (
              <div key={index} id={index} className="question flex justify-between items-center">
                <span className="ms-3 font-bold" id={index}>
                  {editSectionContentType === 'Exam' ? q.question : q.title}
                </span>
                <div className="55">
                  <Label
                    as="button"
                    content="Edit"
                    icon="edit"
                    color="blue"
                    id={index}
                    onClick={e => {
                      editQuestionHandler(e, q.id);
                    }}
                  />
                  <Label
                    as="button"
                    content="Delete"
                    icon="remove"
                    color="red"
                    onClick={() => deleteQuestionHandler(q.id)}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
