import React, { useState, useContext, useEffect } from 'react';
import LoadingSpinner from '../Components/LoadingSpinner';
import { useLocation } from 'react-router-dom';
import { getProduct, rejectProduct, acceptProduct, getProductReviews } from '../APIs/ProductAPIs';
import ToastContext from '../Context/ToastContext';
import starIcon from '../Assets/vector (2).png';
import friendIcon from '../Assets/vector (3).png';
import filledStar from '../Icons/star-fill.svg';
import '../Styles/Product.css';
import ReviewCard from '../Components/ReviewCard';
import Layout from '../Components/_common/Layout';
import location from '../Assets/location.svg';

const Product = () => {
  // const navigate = useNavigate();
  const { addToasts } = useContext(ToastContext);
  const [data, setData] = useState({});
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    state: { id },
  } = useLocation();

  const approveProductReq = async () => {
    try {
      const res = await acceptProduct(id);
      console.log(res);
      addToasts({
        type: 'success',
        body: res.data.data.msg || 'product approved successfully',
      });
    } catch (err) {
      addToasts({
        type: 'danger',
        body: err.message || 'unexpected error',
      });
    }

    console.log(data);
  };

  const rejectProductReq = async () => {
    try {
      const res = await rejectProduct(id);
      console.log(res);
      addToasts({
        type: 'success',
        body: res.data.data.msg || 'product rejected successfully',
      });
    } catch (err) {
      addToasts({
        type: 'danger',
        body: err.message || 'unexpected error',
      });
    }
  };

  const getData = async () => {
    try {
      setLoading(true);
      const product = await getProduct(id);
      const reviews = await getProductReviews(id);
      if (product.status !== 200) {
        throw new Error('Network error');
      }
      if (reviews.status !== 200) {
        throw new Error('Network error');
      }
      const { reviews: fetchedReviews } = reviews.data.data;
      const { product: fetchedProduct } = product.data.data;

      setReviews(fetchedReviews);
      setData(fetchedProduct);
      setLoading(false);
    } catch (error) {
      console.log(error);
      addToasts({
        type: 'danger',
        body: error.message || 'Unexpected error',
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout title={'Product'}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="my-4 space-y-6">
          <section className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-md h-full">
              <h2 className="text-2xl font-bold mb-2">{data.title}</h2>
              <div className="flex items-center justify-between">
                <span className="text-blue-600 text-xl font-semibold">{data.price} EGP</span>
                <span className="mr-2 text-gray-600 text-lg">{data.deliveryFee} EGP Delivery Fee</span>
              </div>
              <div className="flex items-center justify-between mt-4">
                <div className="mr-2 text-gray-600 flex items-center gap-1">
                  <span>{data.city}</span>
                  <img className="w-6 h-6" crossOrigin="anonymous" src={location} alt="location" />
                </div>
                <div className="mr-2 text-gray-600 flex items-center gap-1">
                  <span>
                    {data.average_rating}({data.reviews_count})
                  </span>
                  <img className="w-6 h-6" crossOrigin="anonymous" src={starIcon} alt="starIcon" />
                </div>
                <div className="mr-2 text-gray-600 flex items-center gap-1">
                  <span className="mr-2 text-gray-600">{data.views_count} Viewers</span>
                  <img className="w-6 h-6" crossOrigin="anonymous" src={friendIcon} alt="friendIcon" />
                </div>
                <div className="mr-2 text-gray-600 flex items-center gap-1">
                  <span className="mr-2 text-gray-600">{data.buyers_count} Buyers</span>

                  <img className="w-6 h-6" crossOrigin="anonymous" src={friendIcon} alt="friendIcon" />
                </div>
              </div>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">Actions</h3>
              <div className="flex justify-around">
                <button
                  className={`bg-green-500 text-white p-2 rounded-lg text-2xl font-semibold ${
                    data.is_accepted ? ' cursor-not-allowed opacity-50' : 'hover:bg-green-600'
                  }`}
                  onClick={approveProductReq}
                  disabled={data.is_accepted}
                >
                  Accept
                </button>
                <button
                  className={`bg-red-500 text-white p-2 rounded-lg text-2xl font-semibold ${
                    data.is_rejected ? 'cursor-not-allowed opacity-50' : 'hover:bg-red-600'
                  }`}
                  onClick={rejectProductReq}
                  disabled={data.is_rejected}
                >
                  Reject
                </button>
              </div>
            </div>
          </section>
          <section className="grid grid-cols-1 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold my-2">About</h3>
              <p className="text-lg">{data.about}</p>
              {data.product_category.length > 0 && (
                <div className=" text-lg capitalize">
                  categories :{' '}
                  {data.product_category?.map((el, index) => {
                    return (
                      <span key={index} className="text-md mx-2 bg-slate-300 p-1 rounded-lg">
                        {el.name}
                      </span>
                    );
                  })}
                </div>
              )}

              {data.product_tags.length > 0 && (
                <div className=" text-lg capitalize">
                  Tags :
                  {data.product_tags?.map((el, index) => {
                    return (
                      <div key={index} className="text-md m-1 p-1 rounded-lg">
                        <span className="p-1 rounded-lg">{el.name} :</span>
                        {el.options.map((option, index) => {
                          return (
                            <span key={index} className="p-1 mx-2 rounded-lg bg-slate-200">
                              {option}
                            </span>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </section>
          <section className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">Product photo</h3>
              <div className="w-full flex overflow-x-auto space-x-2 no-scrollbar justify-between">
                {data.images.map((el, index) => {
                  return (
                    <img
                      key={index}
                      id={data.id}
                      src={el}
                      alt="Product"
                      className="max-h-64 p-0 m-0"
                      crossOrigin="anonymous"
                    />
                  );
                })}
              </div>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">Reviews ({data.reviews_count ?? 0})</h3>
              {reviews && reviews.length > 0 ? (
                <>
                  <div className="flex items-center gap-2">
                    <span>{data.averageRating ?? 0}</span>
                    <img src={filledStar} alt="Rating" height={25} width={25} />
                  </div>
                  {reviews?.map(review => (
                    <ReviewCard key={review.id} review={review} />
                  ))}
                </>
              ) : (
                <p>No reviews</p>
              )}
            </div>
          </section>
        </div>
      )}
    </Layout>
  );
};

export default Product;
