import { useContext, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { upload } from '../APIs/UploadAPIs';
import { updateUniversity, deleteUniversity } from '../APIs/UniversityAPIs';
import Select from 'react-select';
import Sidebar from '../Components/Sidebar';
import TopCard from '../Components/TopCard';
import uploadIcon from '../Assets/plus.png';
import '../Styles/AddUniversity.css';
import ToastContext from '../Context/ToastContext';
import BasicModal from '../Components/Modal';

export default function EditUniversity() {
  const navigate = useNavigate();
  const {
    state: { data },
  } = useLocation();
  const _id = data.id;
  const photoLink = data.photo.split('/');
  const [fileName, setFileName] = useState(photoLink[photoLink.length - 1]);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedStatus, setSelectedStatus] = useState('Public');
  const [city, setCity] = useState({
    value: data.city,
    label: data.city,
  });
  const [genre, setGenre] = useState(data.genre);
  const [name, setName] = useState(data.name);
  const [about, setAbout] = useState(data.about);
  const [founder, setFounder] = useState(data.founder);
  const [location, setLocation] = useState(data.location);
  const [link, setLink] = useState(data.link);
  const [graduates, setGraduates] = useState(data.graduates);
  const [professors, setProfessors] = useState(data.professors);
  const [colleges, setColleges] = useState(data.colleges);
  const { addToasts } = useContext(ToastContext);
  const isValid =
    fileName &&
    city &&
    genre?.trim() &&
    about?.trim() &&
    founder?.trim() &&
    link?.trim() &&
    name?.trim() &&
    graduates &&
    professors &&
    colleges &&
    location?.trim();

  const handleIconClick = () => {
    fileInputRef.current.click();
  };
  const onDelete = async () => {
    try {
      const res = await deleteUniversity(_id);
      if (res.status == 200) {
        addToasts({
          body: 'University deleted successfully',
          type: 'success',
        });
      }
    } catch (err) {
      addToasts({
        body: 'Error deleting university',
        type: 'error',
      });
    }
  };
  navigate('/universities');

  const handleFileChange = event => {
    setFile(event.target.files[0]);
    const fileSelected = event.target.files[0] && setFileName(event.target.files[0].name);
    if (fileSelected) {
      console.log('File selected:', file.name);
    }
  };

  const handleStatusChange = event => {
    setSelectedStatus(event.target.value);
  };
  const handleGenreChange = event => {
    setGenre(event.target.value);
  };

  const onClick = async event => {
    event.preventDefault();
    try {
      let tmp = {
        city: city.value,
        genre,
        name,
        about,
        location,
        founder,
        link,
        graduates,
        professors,
        colleges,
      };
      let res;
      if (file) {
        const formData = new FormData();
        formData.append('files', file);
        res = await upload(formData);
        let { fileLinks } = await res.json();
        if (!fileLinks) {
          addToasts({
            body: 'error uploading the file',
            type: 'danger',
          });
        }
        tmp.photo = fileLinks[0];
      }
      res = await updateUniversity(_id, tmp);
      addToasts({
        body: 'university updated successfully',
        type: 'success',
      });
      navigate(-1);
    } catch (err) {
      console.error(err);
      addToasts({
        body: 'unexpected error',
        type: 'danger',
      });
    }
  };

  const cities = [
    { value: 'Cairo', label: 'Cairo' },
    { value: 'Alexandria', label: 'Alexandria' },
    { value: 'Giza', label: 'Giza' },
    { value: 'Shubra El Kheima', label: 'Shubra El Kheima' },
    { value: 'Port Said', label: 'Port Said' },
    { value: 'Suez', label: 'Suez' },
    { value: 'Luxor', label: 'Luxor' },
    { value: 'al-Mansura', label: 'al-Mansura' },
    { value: 'El-Mahalla El-Kubra', label: 'El-Mahalla El-Kubra' },
    { value: 'Tanta', label: 'Tanta' },
    { value: 'Asyut', label: 'Asyut' },
    { value: 'Ismailia', label: 'Ismailia' },
    { value: 'Fayyum', label: 'Fayyum' },
    { value: 'Zagazig', label: 'Zagazig' },
    { value: 'Aswan', label: 'Aswan' },
    { value: 'Damietta', label: 'Damietta' },
    { value: 'Damanhur', label: 'Damanhur' },
    { value: 'al-Minya', label: 'al-Minya' },
    { value: 'Beni Suef', label: 'Beni Suef' },
    { value: 'Qena', label: 'Qena' },
    { value: 'Sohag', label: 'Sohag' },
    { value: 'Hurghada', label: 'Hurghada' },
    { value: '6th of October City', label: '6th of October City' },
    { value: 'Shibin El Kom', label: 'Shibin El Kom' },
    { value: 'Banha', label: 'Banha' },
    { value: 'Kafr el-Sheikh', label: 'Kafr el-Sheikh' },
    { value: 'Arish', label: 'Arish' },
    { value: 'Mallawi', label: 'Mallawi' },
    { value: '10th of Ramadan City', label: '10th of Ramadan City' },
    { value: 'Bilbais', label: 'Bilbais' },
    { value: 'Marsa Matruh', label: 'Marsa Matruh' },
    { value: 'Idfu', label: 'Idfu' },
    { value: 'Mit Ghamr', label: 'Mit Ghamr' },
  ];

  return (
    <div>
      <Sidebar></Sidebar>
      <TopCard title="Edit university"></TopCard>
      <div className="addUniversityCards">
        <div className="universityInfoCard">
          <label>Title of product</label>
          <input value={name} onChange={e => setName(e.target.value)} placeholder="Title of product"></input>
          <label>About</label>
          <input value={about} onChange={e => setAbout(e.target.value)} placeholder="About"></input>
          <label>Website link </label>
          <input value={link} onChange={e => setLink(e.target.value)} placeholder="Website link"></input>
          <label>University photo</label>
          <div className="photoUpload">
            <div className="uploadCustom" placeholder="hello">
              <span style={{ color: '#116ACC' }}>{fileName}</span>
              <input
                style={{ display: 'none' }}
                type="file"
                ref={fileInputRef}
                className="uploadInput"
                placeholder="Photo"
                onChange={handleFileChange}
              ></input>
            </div>
            <img src={uploadIcon} alt="image" onClick={handleIconClick}></img>
          </div>
          <label>University location</label>
          <input value={location} onChange={e => setLocation(e.target.value)} placeholder="location"></input>
          <label>University status</label>
          <div className="pageStatus">
            <label className={selectedStatus == 'Special' ? 'radio-label selected' : 'radio-label'}>
              <input
                type="radio"
                name="options"
                value="Special"
                checked={selectedStatus == 'Special'}
                onChange={handleStatusChange}
              />{' '}
              Special
            </label>
            <label className={selectedStatus == 'Public' ? 'radio-label selected' : 'radio-label'}>
              <input
                type="radio"
                name="options"
                value="Public"
                checked={selectedStatus == 'Public'}
                onChange={handleStatusChange}
              />{' '}
              Public
            </label>
          </div>
          <BasicModal
            buttonName="Delete university"
            question="Are you sure to delete this university?"
            onAccept={onDelete}
          ></BasicModal>

          <button disabled={!isValid} onClick={onClick} className="addButton">
            Update
          </button>
        </div>
        <div className="founderCard">
          <label>Founder</label>
          <input value={founder} onChange={e => setFounder(e.target.value)} placeholder="Founder"></input>
          <label>Genre or type of university</label>
          <label className={genre === 'Private' ? 'radio-label selected' : 'radio-label'}>
            <input
              type="radio"
              name="options"
              value="Private"
              checked={genre === 'Private'}
              onChange={handleGenreChange}
            />{' '}
            Private
          </label>
          <label className={genre === 'Public' ? 'radio-label selected' : 'radio-label'}>
            <input
              type="radio"
              name="options"
              value="Public"
              checked={genre === 'Public'}
              onChange={handleGenreChange}
            />{' '}
            Public
          </label>
          <label>Collages</label>
          <input
            value={colleges}
            onChange={e => setColleges(e.target.value)}
            type="number"
            placeholder="Collages"
          ></input>
          <label>Professors</label>
          <input
            value={professors}
            onChange={e => setProfessors(e.target.value)}
            type="number"
            placeholder="Professors"
          ></input>
          <label>Graduate students</label>
          <input
            value={graduates}
            onChange={e => setGraduates(e.target.value)}
            type="number"
            placeholder="Graduate students"
          ></input>
        </div>
        <div className="cityCard">
          <div>City</div>
          <Select options={cities} isSearchable={true} onChange={setCity} value={city} placeholder="Select an option" />
        </div>
      </div>
    </div>
  );
}
