import { getSession } from '../Utils/SessionUtils';
import { baseURL as BASE_URL } from '../Constants/baseURL';
import api from './_common/helper';

export async function getMealsByStatus(data) {
  const url = `/meals/status?skip=${data.skip}&is_accepted=${data.is_accepted}&is_rejected=${data.is_rejected}`;
  return api.get(url);
}

export async function searchMeals(data) {
  const url = `/meals/search?skip=${data.skip}&query=${data.text}&is_accepted=${data.is_accepted}&is_rejected=${data.is_rejected}`;
  return api.get(url);
}

export async function getMeals(data) {
  return api.get('/meals', data);
}

export async function getMeal(mealID) {
  return api.get(`/meal/${mealID}`);
}

export async function updateMealStatus(mealID, data) {
  return api.patch(`/meal/status/${mealID}`, data);
}
