import '../Styles/Event.css';
import starIcon from '../Assets/u_star.svg';
import interestedIcon from '../Assets/u_check-circle.svg';
import location from '../Assets/location.svg';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from '../Components/LoadingSpinner';
import Layout from '../Components/_common/Layout';
import editIcon from '../Assets/edit.png';
import deleteIcon from '../Assets/vector (5).png';
import convertDateToDateString from '../Utils/convertDateToDateString';
import useEvent from '../Hooks/useEvent';

export default function Event() {
  const {
    state: { eventId },
  } = useLocation();
  const { loading, data, handleEditEvent, handleDeleteEvent } = useEvent(eventId);

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Layout
          title={'Event'}
          Action={
            <>
              <img
                src={editIcon}
                height={45}
                width={45}
                alt="icon"
                style={{ cursor: 'pointer' }}
                onClick={handleEditEvent}
              ></img>
              <img
                src={deleteIcon}
                height={40}
                width={40}
                alt="icon"
                className="bg-slate-200 p-2 mx-2 rounded-full"
                style={{ cursor: 'pointer' }}
                onClick={handleDeleteEvent}
              ></img>
            </>
          }
        >
          <div className="bg-white w-full rounded-2xl mx-auto my-5 flex-col flex md:flex-row p-5 gap-10">
            <div className="w-full md:w-1/2">
              <div key={data.id}>
                <div className="flex-wrap md:flex justify-between">
                  <h1 className="capitalize text-3xl font-semibold my-1">{data.title}</h1>
                  <span className="text-dark_gray font-semibold text-lg capitalize">{data.type}</span>
                </div>
                <div className="text-dark_gray">{convertDateToDateString(data.date)}</div>
                <div className="flex my-2">
                  <img width={20} src={location} alt="icon" />
                  <span className="mx-3 font-medium text-lg">{data.location}</span>
                </div>
                <p className="my-5 text-lg capitalize">{data.details}</p>

                <div className="eventInfo">
                  <div className="iconAndNumber">
                    <img src={interestedIcon} alt="icon" />
                    <div>{data.interested_count} interested</div>
                  </div>
                  <div className="iconAndNumber">
                    <img src={starIcon} alt="icon" />
                    <div>{data.going_count} going</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex overflow-x-auto space-x-2 no-scrollbar justify-between">
              {data.photo.map((el, index) => {
                return <img key={index} src={el} alt="eventImage" className="md:w-1/2 rounded-2xl" />;
              })}
            </div>
          </div>
        </Layout>
      )}
    </div>
  );
}
