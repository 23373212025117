import React from 'react';
import convertDateToDateString from '../../Utils/convertDateToDateString';

export default function JobCard({ job, handleSelectJob }) {
  return (
    <div
      key={job.id}
      id={job.id}
      onClick={handleSelectJob}
      style={{ cursor: 'pointer' }}
      className="jobsCard overflow-hidden"
    >
      <img id={job.id} src={job.photo} alt="image" className="w-full" crossOrigin="anonymous"></img>
      <section className="p-3">
        <h3 className="capitalize text-xl font-bold" id={job.id}>
          {job.title}
        </h3>
        <p className="capitalize text-lg">{job.about}</p>
        <div>
          <span className="my-3 font-bold text-cyan-700">Posted at : {convertDateToDateString(job.createdAt)}</span>
        </div>
      </section>
    </div>
  );
}
