import React from 'react';
import FullNameConcat from '../../Utils/FullNameConcat';

function VerificationRequest({ data, navigate }) {
  const handleProfileClick = userId => {
    navigate('/profiles/verificationrequests/verificationrequest', { state: { id: userId } });
  };

  return (
    <div className="relative bg-white rounded-2xl mt-3 w-full">
      <div className="flex justify-between items-center p-3">
        <h2 className="text-2xl font-bold">Verification Request</h2>
        <button className="text-xl font-bold text-[#6b7280]" onClick={() => navigate('/profiles/verificationrequests')}>
          See all
        </button>
      </div>
      <div className="max-h-[350px] overflow-y-auto overflow-x-hidden mb-10">
        {data?.data?.verificationRequests?.map(profile => (
          <div className="flex justify-between items-center m-4" key={profile.id}>
            <div className="flex items-center gap-3">
              <img src={profile.user.avatar} alt="Profile" className="w-10 h-10 rounded-full" />
              <p className="font-bold text-xl">{FullNameConcat(profile.user)}</p>
            </div>
            <button className="details" onClick={() => handleProfileClick(profile.id)}>
              Details..
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default VerificationRequest;
