import { useState, useRef, useCallback, useEffect, useContext } from 'react';
import { deleteReel } from '../../APIs/ReelsAPI';
import ToastContext from '../../Context/ToastContext';
function useVideoCard() {
  const { addToasts } = useContext(ToastContext);
  const [isPlaying, setIsPlaying] = useState(false);
  const [thumbnail, setThumbnail] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const videoRef = useRef(null);
  const containerRef = useRef(null);

  const handleThumbnailClick = () => {
    setThumbnail(false);
    videoRef.current.play();
    setIsPlaying(true);
  };

  const handleDeleteReel = async reelId => {
    try {
      setShowDeleteModal(false);
      await deleteReel(reelId);
      addToasts({
        type: 'success',
        body: 'Reel deleted successfully',
      });
    } catch (error) {
      console.error(error);
      addToasts({
        type: 'danger',
        body: `${error.response.data?.msg || 'something went wrong, please try again later'}`,
      });
    }
  };

  const handleDeleteReelButton = () => {
    setShowDeleteModal(prevState => !prevState);
  };

  const handlePlayPause = useCallback(() => {
    try {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    } catch (error) {
      console.error(error);
    }
  }, [isPlaying]);

  const handleMute = useCallback(() => {
    videoRef.current.muted = !isMuted;
    setIsMuted(!isMuted);
  }, [isMuted]);

  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
  };

  const handleLoadedMetadata = () => {
    setDuration(videoRef.current.duration);
  };

  const handleSeek = event => {
    const seekTime = (event.target.value / 100) * duration;
    videoRef.current.currentTime = seekTime;
  };

  const handleFullscreen = () => {
    if (!isFullscreen) {
      if (containerRef.current.requestFullscreen) {
        containerRef.current.requestFullscreen();
      } else if (containerRef.current.mozRequestFullScreen) {
        containerRef.current.mozRequestFullScreen(); // Firefox
      } else if (containerRef.current.webkitRequestFullscreen) {
        containerRef.current.webkitRequestFullscreen(); // Chrome, Safari and Opera
      } else if (containerRef.current.msRequestFullscreen) {
        containerRef.current.msRequestFullscreen(); // IE/Edge
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen(); // Firefox
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen(); // Chrome, Safari and Opera
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen(); // IE/Edge
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []);

  return {
    isPlaying,
    thumbnail,
    isMuted,
    duration,
    currentTime,
    isFullscreen,
    videoRef,
    containerRef,
    setIsPlaying,
    setThumbnail,
    setIsMuted,
    setCurrentTime,
    setDuration,
    setIsFullscreen,
    handleThumbnailClick,
    handlePlayPause,
    handleMute,
    handleTimeUpdate,
    handleLoadedMetadata,
    handleSeek,
    handleFullscreen,
    handleDeleteReel,
    showDeleteModal,
    setShowDeleteModal,
    handleDeleteReelButton,
  };
}

export default useVideoCard;
