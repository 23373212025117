import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { getProffessors } from '../APIs/CourseAPIs';
import ToastContext from '../Context/ToastContext';
import config from '../Config/Config';

const CourseForm = ({
  formInputs,
  inputsOnChangeHandler,
  paymentTypeOnChangeHandler,
  professorOnChangeHandler,
  categoryOnChangeHandler,
  languageOnChangeHandler,
  levelOnChangeHandler,
  roleOnChangeHandler,
  fileOnChangeHandler,
  filePreview,
}) => {
  const { addToasts } = useContext(ToastContext);

  const [professorsList, setProfessorsList] = useState([]);

  const categoryOptions = [
    { value: 'Engineering', label: 'Engineering' },
    { value: 'Medicine', label: 'Medicine' },
    { value: 'Business', label: 'Business' },
    { value: 'Computer Science', label: 'Computer Science' },
    { value: 'Art', label: 'Art' },
    { value: 'Design', label: 'Design' },
    { value: 'Marketing', label: 'Marketing' },
    { value: 'Self Development', label: 'Self Development' },
    { value: 'Languages', label: 'Languages' },
    { value: 'Others', label: 'Others' },
  ];

  const roleOptions = [
    { value: 'PROFESSOR', label: 'PROFESSOR' },
    { value: 'PARENT', label: 'PARENT' },
    { value: 'STUDENT', label: 'STUDENT' },
    { value: 'GRADUATE', label: 'GRADUATE' },
    { value: 'ADMIN', label: 'ADMIN' },
    { value: 'SUPPORT', label: 'SUPPORT' },
  ];

  const levels = [
    { value: 'Beginner', label: 'Beginner' },
    { value: 'Intermediate', label: 'Intermediate' },
    { value: 'Advanced', label: 'Advanced' },
    { value: 'Expert', label: 'Expert' },
  ];

  const languages = [
    { value: 'English', label: 'English' },
    { value: 'Arabic', label: 'Arabic' },
  ];

  const { title, about, is_free, price, discount, professor, category, time, language, level, roles } = formInputs;

  const fetchProfessors = () => {
    getProffessors()
      .then(res => {
        setProfessorsList(res.data.users);
      })
      .catch(err => {
        if (config.MODE === 'development') console.error(err);
        addToasts({
          type: 'danger',
          body: `${err.response.data?.msg || 'something went wrong, please try again later'}`,
        });
      });
  };

  useEffect(() => {
    fetchProfessors();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="addPageForm lg:col-span-2">
      <label htmlFor="titleOf" className="titleOf">
        Title of course
      </label>
      <input
        value={title}
        name="title"
        onChange={inputsOnChangeHandler}
        id="titleOf"
        placeholder="Title of course"
      ></input>
      <label htmlFor="about">About</label>
      <input value={about} name="about" onChange={inputsOnChangeHandler} id="about" placeholder="About"></input>
      <label>Payment Type</label>
      <div className="paidFree my-4">
        <label className={!is_free ? 'radio-label selected' : 'radio-label'}>
          {' '}
          <input type="radio" name="paymentType" value="paid" onChange={paymentTypeOnChangeHandler} />
          paid
        </label>
        <label className={is_free ? 'radio-label selected' : 'radio-label'}>
          {' '}
          <input type="radio" name="paymentType" value="free" onChange={paymentTypeOnChangeHandler} />
          free
        </label>
      </div>
      <label htmlFor="price">Price (EGP)</label>
      <input
        type="Number"
        name="price"
        value={price}
        onChange={inputsOnChangeHandler}
        id="price"
        placeholder="Price (EGP)"
        disabled={is_free}
      ></input>
      <label htmlFor="price">Discount</label>
      <input
        value={discount}
        name="discount"
        onChange={inputsOnChangeHandler}
        type="Number"
        id="price"
        placeholder="Discount"
        disabled={is_free}
      ></input>
      <label>Course photo</label>
      <img src={filePreview} alt="file preview" className="w-1/3" />
      <input type="file" className="my-3" onChange={fileOnChangeHandler}></input>
      <label>Proffessor</label>
      <div className="category">
        <Select
          name="professor"
          options={professorsList}
          isSearchable={true}
          onChange={professorOnChangeHandler}
          value={professor}
          placeholder="Select an option"
        />
      </div>
      <label>Category</label>
      <div className="category">
        <Select
          name="category"
          options={categoryOptions}
          isSearchable={true}
          onChange={categoryOnChangeHandler}
          value={category}
          placeholder="Select an option"
        />
      </div>
      <label htmlFor="courseHours">How many hours is the course</label>
      <input
        name="time"
        value={time}
        onChange={inputsOnChangeHandler}
        type="Number"
        id="courseHours"
        placeholder="How many hours is the course"
      ></input>
      <label>Language</label>
      <div className="category">
        <Select
          name="language"
          options={languages}
          isSearchable={true}
          onChange={languageOnChangeHandler}
          value={language}
          placeholder="Select an option"
        />
      </div>
      <label>Level</label>
      <div className="category">
        <Select
          name="level"
          options={levels}
          isSearchable={true}
          onChange={levelOnChangeHandler}
          value={level}
          placeholder="Select an option"
        />
      </div>
      <label>Roles</label>
      <div className="category">
        <Select
          name="roles"
          isMulti
          options={roleOptions}
          isSearchable={true}
          onChange={roleOnChangeHandler}
          value={roles}
          placeholder="Select an option"
        />
      </div>

      {/* // !deprecated */}
      {/* <label>Course status</label>
            <div className="pageStatus">
              <label
                className={
                  courseStatus === "Special"
                    ? "radio-label selected"
                    : "radio-label"
                }
              >
                {" "}
                <input
                  type="radio"
                  name="courseStatus"
                  value="Special"
                  checked={courseStatus === "Special"}
                  onChange={inputsOnChangeHandler}
                />
                Special
              </label>
              <label
                className={
                  courseStatus === "Public"
                    ? "radio-label selected"
                    : "radio-label"
                }
              >
                {" "}
                <input
                  type="radio"
                  name="courseStatus"
                  value="Public"
                  checked={courseStatus === "Public"}
                  onChange={inputsOnChangeHandler}
                />
                Public
              </label>
            </div> */}
    </div>
  );
};

export default CourseForm;
