import Sidebar from '../Components/Sidebar';
import TopCard from '../Components/TopCard';
import '../Styles/ChangeVccPrice.css';
import { useState } from 'react';
import { updateVCC } from '../APIs/investAPIs';
import { useNavigate } from 'react-router-dom';
export default function ChangeVccPrice() {
  const [price, setprice] = useState(0);
  const isValid = price !== 0;
  const navigate = useNavigate();
  const onClick = async e => {
    e.preventDefault();
    try {
      const res = await updateVCC({ price });
      navigate(-1);
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <div className="changeVccPrice">
      <Sidebar></Sidebar>
      <div className="changeVccPriceContent">
        <TopCard title="Change VCC price"></TopCard>
        <div className="changeVccPriceCards">
          <div className="changeVccPriceCard">
            <label htmlFor="vccPrice">VCC price</label>
            <input type="number" value={price} onChange={e => setprice(e.target.value)} id="vccPrice"></input>
            <button disabled={!isValid} onClick={onClick} className="addButton">
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
