import { getSession } from '../Utils/SessionUtils';

import { baseURL as BASE_URL } from '../Constants/baseURL';
import api from './_common/helper';

export async function loginReq(data) {
  return api.post('/auth', data);
}

// export async function registerReq(data) {
//   return await fetch(`${BASE_URL}/register`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({ user: data }),
//   });
// }

export async function UserInfoReq() {
  return api.get('/user-info');
}
