import Sidebar from '../Components/Sidebar';
import TopCard from '../Components/TopCard';
import '../Styles/ChangeVccPrice.css';
import { useState, useEffect } from 'react';
import { getAllUsers } from '../APIs/UserAPIs';
import io from 'socket.io-client';
import { getSession } from '../Utils/SessionUtils';

import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
export default function ChangeVccPrice() {
  const [text, setText] = useState('');
  const [users, setUsers] = useState([]);
  const [proffessor, setProffessor] = useState([]);
  const isValid = text.trim();
  const [socket, setSocket] = useState(null);
  const navigate = useNavigate();
  const handleSendMessage = async () => {
    if (text.trim()) {
      let recivers = proffessor.filter(o => o.value !== 'All');
      socket.emit('postGroupMessage', {
        users: recivers?.map(u => u.value),
        message: {
          text: text,
          type: 'Text',
        },
      });
      navigate('/support');
    }
  };

  useEffect(() => {
    const newSocket = io('https://buddydashboard.ru/chat', {
      query: {
        token: getSession(),
      },
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const prof = await getAllUsers();
        const data = await prof.json();
        setUsers(data.users);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  const setSelectedOptions = (v, e) => {
    console.log(e);
    if (e.action === 'select-option' && e.option.value === 'All') {
      setProffessor([{ value: 'All', label: 'All' }, ...users]);
    } else if (e.action === 'remove-value' && e.removedValue.value === 'All') {
      setProffessor([]);
    } else if (e.action === 'remove-value') {
      setProffessor(v.filter(o => o.value !== 'All'));
    } else if (proffessor.length === users.length) {
      setProffessor([{ value: 'All', label: 'All' }, ...users]);
    } else {
      setProffessor(v);
    }
  };

  return (
    <div className="changeVccPrice">
      <Sidebar></Sidebar>
      <div className="changeVccPriceContent">
        <TopCard title="Send Notification"></TopCard>
        <div className="changeVccPriceCards">
          <div className="changeVccPriceCard">
            <label htmlFor="vccPrice">Send to</label>
            <div className="category">
              <Select
                isMulti
                options={[{ value: 'All', label: 'All' }, ...users]}
                isSearchable={true}
                onChange={setSelectedOptions}
                value={proffessor}
                placeholder="Select an option"
              />
            </div>

            <label htmlFor="vccPrice">Text</label>
            <input
              value={text}
              onChange={e => setText(e.target.value)}
              id="vccPrice"
              placeholder="body of the Message"
            ></input>
            <button disabled={!isValid} onClick={handleSendMessage} className="addButton">
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
