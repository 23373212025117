function RoleButton({ role, isSelected, setRole }) {
  return (
    <button
      className={`cursor-pointer p-3 rounded-xl bg-[#EEEEEE] text-[#6B7280] font-bold ${isSelected(role)}`}
      onClick={() => setRole(role)}
    >
      {role.charAt(0).toUpperCase() + role.slice(1).toLowerCase()}
    </button>
  );
}

export default RoleButton;
