import { getSession } from '../../Utils/SessionUtils';
import axios from 'axios';
import { baseURL } from '../../Constants/baseURL';

const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  config => {
    const token = getSession();
    const appVersion = '0.0.0'; // replace with your app version retrieval logic
    config.headers.client = 'ADMIN';
    if (token) {
      config.headers.token = `${token}`;
    }

    if (appVersion) {
      config.headers['app-version'] = appVersion;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export default api;
