import { RiVerifiedBadgeFill } from 'react-icons/ri';
import FullNameConcat from '../../Utils/FullNameConcat';

function UserInfo({ user }) {
  return (
    <div className="flex items-center gap-2">
      <img crossOrigin="anonymous" src={user.avatar} alt="Profile" className="w-10 h-10 rounded-full object-cover" />
      <p className="text-sm font-semibold">
        <span className="">
          {FullNameConcat(user)} <RiVerifiedBadgeFill className="text-blue-500 text-base inline-block mb-0.5" />
        </span>
        <span className="text-gray-500 block">@{user.username}</span>
      </p>
    </div>
  );
}

export default UserInfo;
