import BasicModalTwo from '../Components/ModalTwo';
import love from '../Assets/love.png';
import deleteIcon from '../Assets/vector (5).png';
import comment from '../Assets/u_comment.png';
import { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { acceptPost, deletePost, getPost, rejectPost } from '../APIs/PostAPIs';
import ToastContext from '../Context/ToastContext';
import LoadingSpinner from '../Components/LoadingSpinner';
import convertDateToDateString from '../Utils/convertDateToDateString';
import FullNameConcat from '../Utils/FullNameConcat';
import Layout from '../Components/_common/Layout';

export default function RoommatePost() {
  const navigate = useNavigate();
  const {
    state: { id },
  } = useLocation();
  const [data, setData] = useState({});
  const { addToasts } = useContext(ToastContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const post = await getPost(id);
        if (post.status !== 200) {
          addToasts({
            type: 'danger',
            body: 'network error',
          });
        }
        const data = post.data.data;
        setData(data.post);
        setLoading(false);
      } catch (err) {
        addToasts({
          type: 'danger',
          body: 'unexpected error',
        });
      }
    })();
  }, []);

  const onAccept = async () => {
    try {
      const res = await acceptPost(id);
      if (res.status === 200) {
        addToasts({
          type: 'success',
          body: 'post accepted successfully',
        });
      }
      navigate(-1);
    } catch (err) {
      addToasts({
        type: 'danger',
        body: err.msg || 'unexpected error',
      });
    }
  };

  const onReject = async () => {
    try {
      const res = await rejectPost(id);
      if (res.status === 200) {
        addToasts({
          type: 'success',
          body: 'post rejected successfully',
        });
      }
      navigate(-1);
    } catch (err) {
      addToasts({
        type: 'danger',
        body: err.msg || 'unexpected error',
      });
    }
  };

  const handleDeletePost = async () => {
    try {
      const res = await deletePost(id);
      if (res.status === 200) {
        addToasts({
          type: 'success',
          body: 'post deleted successfully',
        });
        navigate(-1);
      }
    } catch (err) {
      addToasts({
        type: 'danger',
        body: err.msg || 'unexpected error',
      });
      navigate(-1);
    }
  };

  return (
    <Layout title={'Roommate Post'} Action={<></>}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <section className="flex flex-col lg:flex-row gap-4 my-4 overflow-y-hidden h-full">
          <article className="relative w-full lg:w-2/3 bg-white rounded-md p-4 shadow-md">
            <button className="absolute top-4 right-4">
              <img onClick={handleDeletePost} src={deleteIcon} className="cursor-pointer" alt="delete post" />
            </button>
            <div>
              <div
                id={data.user.user_id}
                onClick={() =>
                  navigate('/profiles/profile', {
                    state: { userId: data.user.user_id },
                  })
                }
                className="cursor-pointer flex items-center gap-x-4"
              >
                <img
                  crossOrigin="anonymous"
                  id={data.user.user_id}
                  src={data.user.avatar}
                  className="w-10 h-10 rounded-full"
                  alt="Avatar"
                />
                <div id={data.user.user_id} className="flex flex-col gap-y-2">
                  <h3 id={data.user.user_id} className="font-bold">
                    {FullNameConcat(data.user)}
                  </h3>
                  <span id={data.user.user_id} className="text-gray-600">
                    {data.user.username}
                  </span>
                </div>
              </div>
              <span className="text-right text-gray-600">{convertDateToDateString(data.createdAt)}</span>
            </div>

            <p className="text-gray-800 py-2">{data.text}</p>
            <div className="flex overflow-x-auto space-x-2 no-scrollbar w-full lg:w-1/2 mx-auto justify-between">
              {data.images?.map((image, imgIndex) => (
                <img
                  key={imgIndex}
                  src={image}
                  crossOrigin="anonymous"
                  className="w-3/4 object-cover rounded-lg mb-2"
                  alt="postImage"
                ></img>
              ))}
            </div>
            <div className="flex justify-between mt-2">
              <div className="flex gap-x-2 text-gray-600">
                <div className="flex items-center gap-1">
                  <img src={love} alt="Love" height={20} width={20}></img>
                  <span>{data.likes.length}</span>
                </div>
                <div className="flex items-center gap-1">
                  <img src={comment} alt="Comment" height={20} width={20}></img>
                  <span>{data.comments.length}</span>
                </div>
              </div>
            </div>

            <div className="flex gap-2 mt-4">
              <BasicModalTwo
                question="Are you sure you want to accept this post?"
                buttonName="Accept"
                onAccept={onAccept}
              ></BasicModalTwo>
              <BasicModalTwo
                question="Are you sure you want to reject this post?"
                buttonName="Reject"
                onAccept={onReject}
              ></BasicModalTwo>
            </div>
          </article>

          <div className="w-full lg:w-1/3 flex flex-col gap-4">
            <div className="bg-white rounded-md p-4 overflow-y-auto h-64 shadow-md">
              <span className="text-gray-600">Likes ({data.likes.length})</span>
              <div className="flex flex-col gap-2 mt-2">
                {data.likes?.map(like => (
                  <div
                    key={like.id}
                    id={like.id}
                    onClick={e =>
                      navigate('/profiles/profile', {
                        state: { id: e.target.id },
                      })
                    }
                    className="flex justify-between items-center gap-x-4 cursor-pointer"
                  >
                    <img
                      crossOrigin="anonymous"
                      id={like.id}
                      src={like.avatar}
                      className="w-10 h-10 rounded-full"
                      alt="Avatar"
                    />
                    <div id={like.id} className="flex flex-col">
                      <h3 id={like.id} className="font-bold">
                        {FullNameConcat(like)}
                      </h3>
                      <span id={like.id} className="text-gray-600">
                        {like.username}
                      </span>
                    </div>
                    <span id={like.id} className="text-gray-600">
                      Profile...
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-white rounded-md p-4 overflow-y-auto h-64 shadow-md">
              <span className="text-gray-600">Comments ({data.comments.length})</span>
              <div className="flex flex-col gap-4 mt-2">
                {data.comments?.map((comment, index) => (
                  <div key={index} className="bg-gray-200 rounded-md p-2">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <img
                          src={comment.user.avatar}
                          width={40}
                          height={40}
                          alt="User Avatar"
                          className="rounded-full"
                        />
                        <div className="flex flex-col">
                          <h3 className="font-bold">{FullNameConcat(comment.user)}</h3>
                          <span className="text-gray-600">{comment.user.username}</span>
                        </div>
                      </div>
                      <div className="text-gray-600">{convertDateToDateString(comment.createdAt)}</div>
                    </div>
                    <div>{comment.text}</div>
                    {comment.images ? (
                      <div className="flex flex-wrap gap-x-2 mt-2">
                        {comment.images.map((image, index) => (
                          <img key={index} src={image} alt={comment.text} title={comment.text} className="w-20" />
                        ))}
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
    </Layout>
  );
}
