import React from 'react';
import FullNameConcat from '../../Utils/FullNameConcat';
export default function ProfileCard({ profile, handleSelectUser }) {
  return (
    <article
      key={profile.user_id}
      id={profile.user_id}
      onClick={handleSelectUser}
      className="cursor-pointer bg-gray-200 flex items-center justify-between rounded-lg p-2"
    >
      <div id={profile.user_id} className="flex items-center gap-2 w-full">
        <img
          id={profile.user_id}
          className="w-12 h-12 object-cover rounded-full flex-shrink-0"
          src={profile.avatar}
          crossOrigin="anonymous"
          alt="Profile"
        />
        <div id={profile.user_id} className="flex flex-col w-3/4">
          <span id={profile.user_id} className="font-bold overflow-hidden whitespace-nowrap overflow-ellipsis">
            {FullNameConcat(profile)}
          </span>
          <span id={profile.user_id} className="text-dark_gray overflow-hidden whitespace-nowrap overflow-ellipsis">
            {profile.email}
          </span>
        </div>
      </div>
      <button id={profile.user_id} className="text-blue-500 flex-shrink-0 font-semibold underline">
        Visit
      </button>
    </article>
  );
}
