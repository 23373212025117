import React, { useEffect, useState, useContext } from 'react';
import { getCourses, searchCourses } from '../../APIs/CourseAPIs';
import { searchProducts, getProducts } from '../../APIs/ProductAPIs';
import { getMeals, searchMeals } from '../../APIs/MealAPIs';
import { addHubRecommendation } from '../../APIs/HubAPIs';
import ToastContext from '../../Context/ToastContext';
function useEditModal({ setOpenModal, handleUpdateSuccess }) {
  const { addToasts } = useContext(ToastContext);
  const [productDefaultOptions, setProductDefaultOptions] = useState([]);
  const [mealDefaultOptions, setMealDefaultOptions] = useState([]);
  const [courseDefaultOptions, setCourseDefaultOptions] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const buildCourseOption = course => {
    return {
      value: course.id,
      label: course.title,
      image: course.photo,
      price: `${course.price}`,
      discount: course.discount,
      is_free: course.is_free,
      category: course.category,
    };
  };

  const courseSearchOptions = async inputValue => {
    const res = await searchCourses({ skip: 0, text: inputValue });
    return res.data?.data?.courses?.map(buildCourseOption);
  };

  const loadDefaultCourses = async () => {
    const res = await getCourses({ skip: 0, filter: 'default' });
    return res.data?.data?.courses?.map(buildCourseOption);
  };

  const buildProductOption = product => {
    return { value: product.id, label: product.title, image: product.images[0], price: `${product.price} EGP` };
  };
  const productSearchOptions = async inputValue => {
    const res = await searchProducts({ skip: 0, text: inputValue, isUsed: false });
    return res.data?.data?.products?.map(buildProductOption);
  };

  const loadDefaultProducts = async () => {
    const res = await getProducts({ skip: 0, isUsed: false });
    return res.data?.data?.products?.map(buildProductOption);
  };

  const mealSearchOptions = async inputValue => {
    const res = await searchMeals({ skip: 0, text: inputValue, is_accepted: true, is_rejected: false });
    return res.data?.data?.meals?.map(buildMealOption);
  };

  const buildMealOption = meal => {
    return { value: meal.id, label: meal.title, image: meal.image, price: `${meal.price} EGP`, type: meal.type };
  };
  const loadDefaultMeals = async () => {
    const res = await getMeals({ skip: 0 });
    return res.data?.data?.meals?.map(buildMealOption);
  };

  useEffect(() => {
    Promise.all([loadDefaultProducts(), loadDefaultMeals(), loadDefaultCourses()])
      .then(([products, meals, courses]) => {
        setProductDefaultOptions(products);
        setMealDefaultOptions(meals);
        setCourseDefaultOptions(courses);
      })
      .catch(err =>
        addToasts({
          type: 'danger',
          body: `${err.response.data?.msg || 'Something went wrong, please try again later'}`,
        }),
      );
  }, []);

  const handleSelectItem = (item, type) => {
    setSelectedItems(prevState => {
      const items = item?.map(i => {
        return { itemId: i.value, type };
      });
      return [...prevState, ...items];
    });
  };

  const handleSubmit = () => {
    addHubRecommendation({ items: selectedItems })
      .then(res => {
        handleUpdateSuccess();
        addToasts({
          type: 'success',
          body: 'Items added successfully',
        });
        setOpenModal(false);
      })
      .catch(err =>
        addToasts({
          type: 'danger',
          body: `${err.response.data?.msg || 'Something went wrong, please try again later'}`,
        }),
      );
  };

  return {
    productDefaultOptions,
    mealDefaultOptions,
    courseDefaultOptions,
    productSearchOptions,
    mealSearchOptions,
    courseSearchOptions,
    handleSelectItem,
    handleSubmit,
  };
}

export default useEditModal;
