import isArabic from '../../Utils/isArabic';
import UserInfo from './UserInfo';

function VideoInfo({ reel }) {
  return (
    <div className="absolute bottom-8 flex justify-center items-start flex-col w-full p-4 z-10">
      <UserInfo user={reel.author} />
      <h1
        className={`${
          isArabic(reel.title.trim().charAt(0)) ? 'text-right' : 'text-left'
        } w-full text-white text-base font-semibold ${reel.title.length > 20 && 'text-sm'} ${
          reel.title.length > 30 && 'text-xs'
        }
      `}
      >
        {reel.title}
      </h1>
    </div>
  );
}

export default VideoInfo;
