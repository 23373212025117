import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ToastContext from '../Context/ToastContext';
import { getMeal, updateMealStatus } from '../APIs/MealAPIs';
import config from '../Config/Config';
import { useNavigate } from 'react-router-dom';
const MealHook = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  // get meal id from url
  const {
    state: { id },
  } = useLocation();
  // manage toasts
  const { addToasts } = useContext(ToastContext);

  // define states
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  // fetch meal data
  useEffect(() => {
    fetchMealData();
    // eslint-disable-next-line
  }, []);

  // update meal status to be accepted
  const onAccept = async () => {
    // update meal request
    updateMealStatus(id, {
      is_accepted: true,
      is_rejected: false,
    })
      .then(res => {
        if (config.MODE === 'development') console.log(res, 'onAccept');
        if (res.status === 200) {
          // show success toast if response is succeed
          addToasts({
            type: 'success',
            body: 'meal accepted successfully',
          });
          // refetch meal data
          fetchMealData();
          handleBack();
        }
      })
      .catch(err => {
        if (config.MODE === 'development') console.log(err, 'onAccept');
        // show error toast if response is failed
        addToasts({
          type: 'danger',
          body: `${err.response.data.msg || 'something went wrong, please try again later'}`,
        });
      });
  };

  // update meal status to be rejected
  const onReject = async () => {
    // update meal request
    updateMealStatus(id, {
      is_accepted: false,
      is_rejected: true,
    })
      .then(res => {
        if (config.MODE === 'development') console.log(res, 'onReject');

        // show success toast if response is succeed
        addToasts({
          type: 'success',
          body: 'meal rejected successfully',
        });
        // refetch meal data
        fetchMealData();
        handleBack();
      })
      .catch(err => {
        if (config.MODE === 'development') console.log(err, 'onReject');
        // show error toast if response is failed
        addToasts({
          type: 'danger',
          body: `${err.response.data.msg || 'something went wrong, please try again later'}`,
        });
      });
  };

  // fetch meal data function
  const fetchMealData = async () => {
    // start loading
    setLoading(true);
    // get meal request
    getMeal(id)
      .then(res => {
        if (config.MODE === 'development') console.log(res, 'fetchMealData');
        // set data if response is succeed
        if (res.status === 200) {
          const { meal } = res.data.data;
          setData(meal);
        }
      })
      .catch(err => {
        if (config.MODE === 'development') console.log(err, 'fetchMealData');
        // show error toast if response is failed
        addToasts({
          type: 'danger',
          body: `${err.response.data.msg || 'something went wrong, please try again later'}`,
        });
      })
      .finally(() => {
        // stop loading with two cases success or failed
        setLoading(false);
      });
  };

  return [data, loading, onAccept, onReject];
};

export default MealHook;
