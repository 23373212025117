import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { upload } from '../APIs/UploadAPIs';
import { addProduct, addProductTags, getProductCategories } from '../APIs/ProductAPIs';
import uploadIcon from '../Assets/plus.png';
import '../Styles/AddProduct.css';
import ToastContext from '../Context/ToastContext';
import LoadingSpinner from '../Components/LoadingSpinner';
import Select from 'react-select';
import { cities } from '../Constants/egyptCitiesEnum';
import Layout from '../Components/_common/Layout';

export default function AddProduct() {
  const deliveryTimeOptions = [
    { value: '12 hours', label: '12 hours' },
    { value: '4 days', label: '4 days' },
  ];
  const [loading, setLoading] = useState(false);
  const [productCategories, setProductCategories] = useState();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]); // Store multiple files
  const [fileNames, setFileNames] = useState('No selected files');
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState('Available');
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [about, setAbout] = useState('');
  const [deliveryTime, setDeliveryTime] = useState({ value: '12 hours', label: '12 hours' });
  const [categories, setCategories] = useState();
  const [city, setCity] = useState({ value: 'Cairo', label: 'Cairo' });
  const { addToasts } = useContext(ToastContext);
  const [tagName, setTagName] = useState('');
  const [tagOptions, setTagOptions] = useState('');
  const [tags, setTags] = useState([]);

  const isValid =
    files.length > 0 && city && about.trim() && title.trim() && deliveryFee && price && categories && selectedStatus;

  const fileInputRef = useRef(null);

  const handleStatusChange = event => {
    setSelectedStatus(event.target.value);
  };

  const handleIconClick = event => {
    fileInputRef.current.click();
  };

  const handleFileChange = event => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
    const fileNames = selectedFiles.map(file => file.name).join(', ');
    setFileNames(fileNames);
  };

  const handleAddTags = () => {
    if (tagName.trim() && tagOptions.trim()) {
      // Split tag options by comma and trim spaces
      const newTag = { [tagName]: tagOptions.split(',').map(option => option.trim()) };
      setTags(prevTags => [...prevTags, newTag]);
      // Clear input fields
      setTagName('');
      setTagOptions('');
    }
  };

  const onClick = async event => {
    setLoading(true);
    event.preventDefault();
    try {
      if (!files.length) {
        addToasts({
          body: 'Please select files',
          type: 'danger',
        });
        return;
      }
      if (files.length > 10) {
        addToasts({
          body: 'too many images , max num is 10',
          type: 'danger',
        });
        return;
      }
      const formData = new FormData();
      files.forEach(file => formData.append('files', file));
      const res = await upload(formData);
      const { fileLinks } = res.data;
      if (!fileLinks) {
        addToasts({
          body: 'Error uploading the files',
          type: 'danger',
        });
        return;
      }
      const tagsRes = await addProductTags({ tags: tags });
      const tagsIds = tagsRes.data.data.tags.map(el => el.id) || [];
      await addProduct({
        status: selectedStatus,
        city: city.value,
        about,
        title,
        price,
        deliveryFee,
        images: fileLinks, // This will now contain multiple file links
        product_category: categories.map(el => el.value),
        product_tags: tagsIds,
        delivery_time: deliveryTime.value,
        isUsed: false,
      });
      addToasts({
        body: 'Product added successfully',
        type: 'success',
      });
      setLoading(false);
      navigate(-1);
    } catch (err) {
      console.error(err);
      addToasts({
        body: 'Unexpected error',
        type: 'danger',
      });
    }
  };

  async function getCategories() {
    const res = await getProductCategories();
    const category = res.data.data.categories;
    setProductCategories(category?.map(el => ({ value: el.id, label: el.name })));
  }
  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Layout title={'Add product'}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="bg-white p-5 my-5 flex flex-col rounded-lg mx-auto w-full md:w-1/2 space-y-2">
          <label>Title of product</label>
          <input
            className="border-2 p-2 rounded-lg"
            placeholder="Title of product"
            value={title}
            onChange={e => setTitle(e.target.value)}
          ></input>
          <label>Price (EGP) </label>
          <input
            className="border-2 p-2 rounded-lg"
            value={price}
            type="Number"
            onChange={e => setPrice(e.target.value)}
            placeholder="Price (EGP)"
          ></input>
          <label>Delivery fee (EGP)</label>
          <input
            className="border-2 p-2 rounded-lg"
            value={deliveryFee}
            type="Number"
            onChange={e => setDeliveryFee(e.target.value)}
            placeholder="Delivery fee (EGP)"
          ></input>

          <label>Delivery Time</label>
          <Select
            options={deliveryTimeOptions}
            isSearchable={true}
            onChange={setDeliveryTime}
            value={deliveryTime}
            placeholder="Select an option"
          />

          <label>About</label>
          <input
            className="border-2 p-2 rounded-lg"
            value={about}
            onChange={e => setAbout(e.target.value)}
            type="text"
            placeholder="About"
          ></input>
          <label>Product photos</label>
          <div className="photoUpload">
            <div className="uploadCustom" placeholder="hello">
              <span style={{ color: '#116ACC' }}>{fileNames}</span>
              <input
                style={{ display: 'none' }}
                type="file"
                ref={fileInputRef}
                className="uploadInput"
                placeholder="Photo"
                onChange={handleFileChange}
                multiple
              />
            </div>
            <img src={uploadIcon} alt="product" onClick={handleIconClick} />
          </div>

          <label>Product status</label>
          <div className="my-2 space-x-2 flex flex-wrap">
            <label className={selectedStatus === 'Available' ? 'radio-label selected' : 'radio-label'}>
              <input
                type="radio"
                name="options"
                value="Available"
                checked={selectedStatus === 'Available'}
                onChange={handleStatusChange}
              />
              Available
            </label>

            <label className={selectedStatus === 'Sold' ? 'radio-label selected' : 'radio-label'}>
              <input
                type="radio"
                name="options"
                value="Sold"
                checked={selectedStatus === 'Sold'}
                onChange={handleStatusChange}
              />
              Sold
            </label>

            <label className={selectedStatus === 'Not Available' ? 'radio-label selected' : 'radio-label'}>
              <input
                type="radio"
                name="options"
                value="Not Available"
                checked={selectedStatus === 'Not Available'}
                onChange={handleStatusChange}
              />
              Not Available
            </label>
          </div>
          <label>Cities</label>
          <Select options={cities} isSearchable={true} onChange={setCity} value={city} placeholder="Select an option" />
          <label>Categories</label>
          <Select
            options={productCategories}
            isMulti
            isSearchable={true}
            onChange={setCategories}
            value={categories}
            placeholder="Select an option"
          />

          <label>Tags</label>

          {/* Display added tags */}
          <div className="mt-4">
            {tags.map((tag, index) => (
              <div key={index} className="bg-gray-100 p-2 rounded my-1">
                <strong>{Object.keys(tag)[0]}</strong>: {Object.values(tag)[0].join(', ')}
              </div>
            ))}
          </div>
          <div className="space-y-2 w-full ">
            <div>
              <label className="text-lg font-normal mr-2">tag name</label>
              <input
                className="border-2 p-2 rounded-lg w-full"
                type="text"
                placeholder="Enter tag name"
                value={tagName}
                onChange={e => setTagName(e.target.value)}
              />
            </div>
            <div>
              <label className="text-lg font-normal mr-2">tag options</label>
              <input
                className="border-2 p-2 rounded-lg w-full"
                type="text"
                placeholder='Enter tag options split with " , " between options'
                value={tagOptions}
                onChange={e => setTagOptions(e.target.value)}
              />
            </div>

            <button
              type="submit"
              className="bg-[#116ACC] text-white p-2 rounded-xl inline-block mr-auto"
              onClick={handleAddTags}
            >
              Add
            </button>
          </div>

          <button disabled={!isValid} onClick={onClick} className="addButton">
            Submit
          </button>
        </div>
      )}
    </Layout>
  );
}
