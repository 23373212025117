import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BiRepost } from 'react-icons/bi';
import FullNameConcat from '../../../Utils/FullNameConcat';
import convertDateToDateString from '../../../Utils/convertDateToDateString';

const PostCard = ({ post, user, love, comment }) => {
  const navigate = useNavigate();
  return (
    <article className="bg-gray-100 p-3 rounded-lg shadow-md m-2" key={post.id}>
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2">
          <img crossOrigin="anonymous" src={user.avatar} alt="avatar" className="w-10 h-10 rounded-full" />
          <p className="flex flex-col gap-1 w-full overflow-hidden whitespace-nowrap overflow-ellipsis">
            <span className="font-bold overflow-hidden whitespace-nowrap overflow-ellipsis">
              {FullNameConcat(user)}
            </span>
            <span className="text-dark_gray">{user.username}</span>
          </p>
        </div>
        <span className="text-dark_gray">{convertDateToDateString(post.createdAt)}</span>
      </div>
      <div className="caption">{post.text}</div>
      <div className="postImages">
        {post.images?.map((image, index) => (
          <img key={index} src={image} alt="post" crossOrigin="anonymous" className="w-full object-cover" />
        ))}
      </div>
      <div id={post.id} className="reactionsEditDelete mt-2">
        <div
          id={post.id}
          onClick={e =>
            navigate('/profile/thepost', {
              state: { id: e.target.id },
            })
          }
          style={{ cursor: 'pointer' }}
          className="reactions"
        >
          <div id={post.id}>
            <img id={post.id} src={love} alt="" height={20} width={20}></img>
            <span id={post.id}>{post.likes_count}</span>
          </div>

          <div id={post.id}>
            <img id={post.id} src={comment} alt="" height={20} width={20}></img>
            <span id={post.id}>{post.comments_count}</span>
          </div>

          <div id={post.id}>
            <BiRepost id={post.id} className="w-6 h-6" />
            <span id={post.id}>{post.reposts_count}</span>
          </div>
        </div>
      </div>
    </article>
  );
};
export default PostCard;
