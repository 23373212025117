import React from 'react';

function HubCard({ title, children }) {
  return (
    <div className="bg-white p-4 rounded-lg shadow-md my-4">
      <h2 className="text-2xl font-bold">{title}</h2>
      <div className="flex flex-row flex-wrap">
        {React.Children?.map(
          children,
          child =>
            child && (
              <div className="box-content md:w-1/2 lg:w-1/3 xl:w-1/4">
                {React.cloneElement(child, { className: 'm-2' })}
              </div>
            ),
        )}
      </div>
    </div>
  );
}

export default HubCard;
