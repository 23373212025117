import { getMealsByStatus } from '../APIs/MealAPIs.js';
import { getVRequests } from '../APIs/VRequestAPIs.js';
import { getLoanRequests } from '../APIs/LoanAPIs';
import { getPosts } from '../APIs/PostAPIs.js';
import { getProducts } from '../APIs/ProductAPIs.js';
import { getUpdate } from '../APIs/OrderAPIs.js';
const fetchData = async () => {
  try {
    const [mealData, verificationData, roommateData, storeData, loanData, updateData] = await Promise.all([
      fetchMeals(),
      fetchVerificationData(),
      fetchRoommateData(),
      fetchStoreData(),
      fetchLoanData(),
      fetchUpdateData(),
    ]);

    return { mealData, verificationData, roommateData, storeData, loanData, updateData };
  } catch (err) {
    console.error('Error fetching data:', err);
    return {};
  }
};

const fetchMeals = async () => {
  try {
    const mealRequest = await getMealsByStatus({ is_accepted: false, is_rejected: false, skip: 0 });
    return mealRequest.data.data.meals;
  } catch (err) {
    console.error('Error fetching meals:', err);
    return [];
  }
};

const fetchVerificationData = async () => {
  try {
    const verificationDataRequest = await getVRequests({ status: 'pending' });
    return verificationDataRequest.data;
  } catch (err) {
    console.error('Error fetching verification data:', err);
    return [];
  }
};

const fetchRoommateData = async () => {
  try {
    const roommatePost = await getPosts({ skip: 0, category: 'ROOMMATE' });
    if (roommatePost.data.success === true) {
      return roommatePost.data.data.posts;
    } else {
      return [];
    }
  } catch (err) {
    console.error('Error fetching roommate data:', err);
    return [];
  }
};

const fetchStoreData = async () => {
  try {
    const productRequest = await getProducts({ skip: 0, isUsed: true, category: 'STORE' });
    return productRequest.data.data.products;
  } catch (err) {
    console.error('Error fetching store data:', err);
    return [];
  }
};

const fetchLoanData = async () => {
  try {
    const loanDataRequest = await getLoanRequests({ page: 0, status: ['pending'] });
    return loanDataRequest.data.data.loanRequests;
  } catch (err) {
    console.error('Error fetching loan data:', err);
    return [];
  }
};

const fetchUpdateData = async () => {
  try {
    const updateStatus = await getUpdate();
    return updateStatus.data.data;
  } catch (err) {
    console.error('Error fetching update data:', err);
    return [];
  }
};

export default fetchData;
