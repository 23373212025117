const Cities = [
  { value: 'Cairo', label: 'Cairo' },
  { value: 'Alexandria', label: 'Alexandria' },
  { value: 'Giza', label: 'Giza' },
  { value: 'Shubra El Kheima', label: 'Shubra El Kheima' },
  { value: 'Port Said', label: 'Port Said' },
  { value: 'Suez', label: 'Suez' },
  { value: 'Luxor', label: 'Luxor' },
  { value: 'Al-Mansura', label: 'Al-Mansura' },
  { value: 'El-Mahalla El-Kubra', label: 'El-Mahalla El-Kubra' },
  { value: 'Tanta', label: 'Tanta' },
  { value: 'Asyut', label: 'Asyut' },
  { value: 'Ismailia', label: 'Ismailia' },
  { value: 'Fayyum', label: 'Fayyum' },
  { value: 'Zagazig', label: 'Zagazig' },
  { value: 'Aswan', label: 'Aswan' },
  { value: 'Damietta', label: 'Damietta' },
  { value: 'Damanhur', label: 'Damanhur' },
  { value: 'Al-Minya', label: 'Al-Minya' },
  { value: 'Beni Suef', label: 'Beni Suef' },
  { value: 'Qena', label: 'Qena' },
  { value: 'Sohag', label: 'Sohag' },
  { value: 'Hurghada', label: 'Hurghada' },
  { value: '6th of October City', label: '6th of October City' },
  { value: 'Shibin El Kom', label: 'Shibin El Kom' },
  { value: 'Banha', label: 'Banha' },
  { value: 'Kafr el-Sheikh', label: 'Kafr el-Sheikh' },
  { value: 'Arish', label: 'Arish' },
  { value: 'Mallawi', label: 'Mallawi' },
  { value: '10th of Ramadan City', label: '10th of Ramadan City' },
  { value: 'Bilbais', label: 'Bilbais' },
  { value: 'Marsa Matruh', label: 'Marsa Matruh' },
  { value: 'Idfu', label: 'Idfu' },
  { value: 'Mit Ghamr', label: 'Mit Ghamr' },
];

export default Cities;
