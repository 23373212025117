import { useContext, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { upload } from '../APIs/UploadAPIs';
import { updateEvent, deleteEvent } from '../APIs/EventAPIs';
import Sidebar from '../Components/Sidebar';
import TopCard from '../Components/TopCard';
import uploadIcon from '../Assets/plus.png';
import '../Styles/EditEvent.css';
import ToastContext from '../Context/ToastContext';
import BasicModal from '../Components/Modal';
import DatePicker from 'react-multi-date-picker';
import Layout from '../Components/_common/Layout';
import LoadingSpinner from '../Components/LoadingSpinner';

export default function EditEvent() {
  const navigate = useNavigate();
  const {
    state: { data },
  } = useLocation();
  const id = data.id;
  const link = data.photo.split('/');
  const [fileName, setFileName] = useState(link[link.length - 1]);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [type, setType] = useState(data.type);
  const [title, setTitle] = useState(data.title);
  const [date, setDate] = useState(data.date);
  const [details, setDetails] = useState(data.details);
  const [location, setLocation] = useState(data.location);
  const { addToasts } = useContext(ToastContext);
  const isValid = type.trim() && fileName && title.trim() && details.trim() && location.trim();

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    if (selectedFile) {
      setFileName(selectedFile.name);
      console.log('File selected:', selectedFile.name);
    }
  };

  const handleTypeChange = event => {
    setType(event.target.value);
  };

  const onClick = async event => {
    event.preventDefault();
    try {
      let tmp = {
        title,
        details,
        location,
        date,
        type,
      };
      if (file) {
        const formData = new FormData();
        formData.append('files', file);
        let res = await upload(formData);
        if (res.status !== 200) {
          addToasts({
            type: 'danger',
            body: 'network error',
          });
        } else tmp.photo = res.data.fileLinks[0];
      }

      await updateEvent(id, tmp);
      addToasts({
        body: 'event updated successfully',
        type: 'success',
      });

      navigate(-1);
    } catch (err) {
      addToasts({
        body: 'unexpected error',
        type: 'danger',
      });
    }
  };
  return (
    <Layout title={'Edit Event'}>
      <div className="w-full md:w-1/2 my-6 mx-auto bg-white rounded-2xl p-5 flex flex-col space-y-3">
        <label>Type of event</label>
        <div className="typeOfEvent">
          <label className={type === 'event' ? 'radio-label selected' : 'radio-label'}>
            <input type="radio" name="options" value="event" checked={type === 'event'} onChange={handleTypeChange} />
            Event
          </label>
          <label className={type === 'conference' ? 'radio-label selected' : 'radio-label'}>
            <input
              type="radio"
              name="options"
              value="conference"
              checked={type === 'conference'}
              onChange={handleTypeChange}
            />
            Conference
          </label>
          <label className={type === 'party' ? 'radio-label selected' : 'radio-label'}>
            <input type="radio" name="options" value="party" checked={type === 'party'} onChange={handleTypeChange} />
            Party
          </label>
        </div>
        <label>Title of Event</label>
        <input
          id="eventTitle"
          placeholder="Title of Event"
          value={title}
          onChange={e => setTitle(e.target.value)}
        ></input>
        <label>Details </label>
        <textarea
          id="eventDetails"
          placeholder="Details"
          value={details}
          onChange={e => setDetails(e.target.value)}
        ></textarea>
        <label>Event photo</label>
        <div className="photoUpload">
          <div className="uploadCustom" placeholder="hello">
            <span style={{ color: '#116ACC' }}>{fileName}</span>
            <input
              style={{ display: 'none' }}
              type="file"
              ref={fileInputRef}
              className="uploadInput"
              placeholder="Photo"
              onChange={handleFileChange}
            ></input>
          </div>
          <img src={uploadIcon} alt="image" onClick={handleIconClick}></img>
        </div>
        <label>Event location</label>
        <input
          id="eventTitle"
          placeholder="Location of Event"
          value={location}
          onChange={e => setLocation(e.target.value)}
        ></input>
        <label>Select date</label>
        <DatePicker onChange={setDate} value={date} />
        <button
          className="disabled:bg-gray-400 w-1/3 bg-blue-500 p-2 my-2 text-xl mx-auto rounded-md  cursor-pointer text-white"
          disabled={!isValid}
          onClick={onClick}
        >
          Edit
        </button>
      </div>
    </Layout>
  );
}
