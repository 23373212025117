import React from 'react';
import LoadingSpinner from '../Components/LoadingSpinner';
import usePosts from '../Hooks/usePostsRequests';
import Layout from '../Components/_common/Layout';
import RoommatePostCard from '../Components/Roommate/RoommatePostCard';

const RoommatePosts = () => {
  const { loading, data, handleSelectPost } = usePosts();

  return (
    <Layout
      title={'Roommate Posts'}
      // Action={
      //   <input
      //     className="w-1/3 border p-2 rounded-lg mr-2"
      //     placeholder="Search"
      //     value={inputText}
      //     onChange={handleInputChange}
      //   ></input>
      // }
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="my-3 grid md:grid-cols-2 lg:grid-cols-3 gap-5">
          {data && data.length > 0 ? (
            data?.map(post => <RoommatePostCard key={post.id} post={post} handleSelectPost={handleSelectPost} />)
          ) : (
            <h1 className="text-2xl font-semibold text-center my-5">No Roommate Posts</h1>
          )}
        </div>
      )}
    </Layout>
  );
};

export default RoommatePosts;
