import '../Styles/Store.css';
import edit4 from '../Assets/Frame 48095608 (1).png';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../Components/LoadingSpinner';
import StoreProductCard from '../Components/StoreProductCard.js';
import '../Components/Searchbar.css';
import Layout from '../Components/_common/Layout.jsx';
import useStoreProducts from '../Hooks/useStoreProducts';

export default function Store() {
  const { data, searchData, loading, type, input, handleInputChange, setType } = useStoreProducts();
  const navigate = useNavigate();
  const handleSelectProduct = e => {
    navigate('/store/product', {
      state: { id: e.target.id },
    });
  };

  return (
    <Layout
      title={'Store'}
      Action={
        <input
          className="border rounded-lg p-1 w-2/3"
          placeholder="Search"
          value={input}
          onChange={handleInputChange}
        ></input>
      }
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div className="my-5">
              <div className="bg-white p-4 rounded-lg">
                <div className="ourStoreUsedStore">
                  <span
                    className={`${type === 'Our store' ? 'selectedStore' : ''}`}
                    onClick={() => setType('Our store')}
                  >
                    Our store
                  </span>
                  <span
                    className={`${type === 'Used store' ? 'selectedStore' : ''}`}
                    onClick={() => setType('Used store')}
                  >
                    Used store
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <h1 className="font-bold text-2xl text-gray-800">{type}</h1>
                  <button className="">
                    <img
                      src={edit4}
                      width={40}
                      height={40}
                      onClick={e => {
                        navigate('/store/addproduct');
                      }}
                      alt="Add product"
                    />
                  </button>
                </div>
              </div>
              <div className="bg-white mt-3 p-2 rounded-md">
                <header className="font-bold flex justify-between p-3 text-xl">
                  <h1>Orders</h1>
                  <button onClick={e => navigate('/store/orders')} className="cursor-pointer text-blue-600">
                    See all
                  </button>
                </header>
              </div>
              <section className="grid md:grid-cols-3 gap-5 my-3">
                {input
                  ? searchData?.map(product => (
                      <StoreProductCard key={product.id} product={product} handleSelectProduct={handleSelectProduct} />
                    ))
                  : data?.map(product => (
                      <StoreProductCard key={product.id} product={product} handleSelectProduct={handleSelectProduct} />
                    ))}
              </section>
            </div>
          )}
        </div>
      )}
    </Layout>
  );
}
