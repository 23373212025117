import Sidebar from '../Components/Sidebar';
import '../Styles/Profiles.css';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../Components/LoadingSpinner';
import '../Components/Searchbar.css';
import useProfiles from '../Hooks/useProfiles';
import ProfileCard from '../Components/Profiles/ProfileCard';
import Layout from '../Components/_common/Layout';
import RoleSelector from '../Components/Profiles/RoleSelector';
import SearchIcon from '../Assets/u_search.png';
export default function Profiles() {
  const { data, searchData, loading, role, input, handleInputChange, setRole } = useProfiles();
  const navigate = useNavigate();

  const handleSelectUser = e => {
    navigate('/profiles/profile', {
      state: { userId: e.target.id },
    });
  };

  return (
    <Layout
      back={false}
      // title="Profiles"
      Action={
        <div className="focus:outline-none focus:border-none w-full flex justify-start items-center gap-x-4 h-12">
          <img src={SearchIcon} alt="search" className="w-5" />
          <input
            className="focus:outline-none focus:border-none w-full h-full"
            placeholder="Search"
            value={input}
            onChange={handleInputChange}
          ></input>
        </div>
      }
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="bg-white p-4 rounded-lg shadow-md my-6">
            <div className="bg-gray-50 p-4 rounded-xl shadow-md mb-6">
              <div className="flex justify-between items-center">
                <RoleSelector
                  roles={['STUDENT', 'PARENT', 'PROFESSOR', 'GRADUATE', 'ADMIN', 'SUPPORT']}
                  selectedRole={role}
                  setRole={setRole}
                  textColor={'#9ca3af'}
                />
                <a
                  className="verificationRequests"
                  href="/profiles/verificationrequests"
                  onClick={() => navigate('/profiles/verificationrequests')}
                  style={{ cursor: 'pointer' }}
                >
                  Verification requests
                </a>
              </div>
            </div>
            <h3 className="text-[#9ca3af] font-bold text-xl mb-4">Users({data.length})</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              {input
                ? searchData?.map(profile => (
                    <ProfileCard key={profile.id} profile={profile} handleSelectUser={handleSelectUser} />
                  ))
                : data?.map(profile => (
                    <ProfileCard key={profile.id} profile={profile} handleSelectUser={handleSelectUser} />
                  ))}
            </div>
          </div>
        </>
      )}
    </Layout>
  );
}
