// will take user object and return full name

export default function FullNameConcat(user) {
  // MAKE EVERY LETTER LOWERCASE THEN UPPERCASE THE FIRST LETTER OF EACH WORD
  user.first_name = user.first_name.toLowerCase().replace(/^\w/, c => c.toUpperCase());
  // middle name is optional
  user.middle_name = user.middle_name?.toLowerCase().replace(/^\w/, c => c.toUpperCase()) || '';
  user.last_name = user.last_name.toLowerCase().replace(/^\w/, c => c.toUpperCase());
  return `${user.first_name} ${user.middle_name ? user.middle_name + ' ' : ''}${user.last_name}`;
}
