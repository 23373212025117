import Sidebar from '../Components/Sidebar';
import '../Styles/Orders.css';
import arrowFrame from '../Assets/arrowframe.png';
import { useNavigate } from 'react-router-dom';
import '../Components/Searchbar.css';
import LoadingSpinner from '../Components/LoadingSpinner';
import ProductOrderCard from '../Components/Product/ProductOrderCard.js';
import useStoreOrder from '../Hooks/useStoreOrder';
export default function Orders() {
  const { data, searchData, loading, type, input, handleInputChange, setType } = useStoreOrder();

  const navigate = useNavigate();
  const handleSelectOrder = e => {
    navigate('/store/orderdetails', {
      state: { id: e.target.id },
    });
  };

  return (
    <>
      <div>
        <Sidebar />
        <div className="SAS">
          <div className="content">
            <div className="search">
              <div className="searchbutton"></div>
              <input placeholder="Search" value={input} onChange={handleInputChange}></input>
            </div>
          </div>
        </div>
        <section className="topCardTwo">
          <div className="topCardTwoFirstLine">
            <img
              src={arrowFrame}
              alt="image"
              height={45}
              width={45}
              style={{ cursor: 'pointer' }}
              onClick={() => navigate(-1)}
            ></img>
            <h1>Orders</h1>
          </div>
          <div className="topCardTwoSecond">
            <button
              className={`${type == 'PENDING' ? 'selectedOrders' : ''}`}
              onClick={() => {
                setType('PENDING');
              }}
            >
              Pending Orders
            </button>
            <button
              className={`${type == 'ACCEPTED' ? 'selectedOrders' : ''}`}
              onClick={() => {
                setType('ACCEPTED');
              }}
            >
              In Progress Orders
            </button>
            <button
              className={`${type == 'DELIVERED' ? 'selectedOrders' : ''}`}
              onClick={() => {
                setType('DELIVERED');
              }}
            >
              Delivered Orders
            </button>
          </div>
        </section>
        {!searchData.length && !data.length ? (
          <h2 className="text-2xl my-10 w-full text-center">No Orders Found</h2>
        ) : (
          <main className="ordersCard">
            <section className="storecards3">
              {input
                ? searchData?.map(order => (
                    <ProductOrderCard key={order.id} order={order} handleSelectOrder={handleSelectOrder} />
                  ))
                : data?.map(order => (
                    <ProductOrderCard key={order.id} order={order} handleSelectOrder={handleSelectOrder} />
                  ))}
            </section>
          </main>
        )}
      </div>
      {loading && <LoadingSpinner />}
    </>
  );
}
