import '../Styles/Dashboardwallet.css';
import useDashboardWallet from '../Hooks/useDashboardWallet';
import Layout from '../Components/_common/Layout';
import LoadingSpinner from '../Components/LoadingSpinner';
import FullNameConcat from '../Utils/FullNameConcat';
import convertDateToDateString from '../Utils/convertDateToDateString';
import { Link } from 'react-router-dom';

export default function DashboardWallet() {
  const {
    loading,
    payments,
    withdraw,
    transaction,
    handleYesterday,
    handelToday,
    handleLastWeek,
    handleLastMonth,
    handleLastYear,
    handleCustomDate,
    show,
    showPicker,
    content,
    date,
    requests,
    status,
    handleStatus,
  } = useDashboardWallet();

  return (
    <Layout
      title={'Dashboard Wallet'}
      Action={
        <div className="w-40 text-lg">
          {new Date().toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })}
        </div>
      }
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="px-5">
          <div className="flex flex-nowrap overflow-x-auto no-scrollbar my-5 text-xl font-semibold">
            <span
              className={`${
                content === 'today' ? 'bg-blue-500 text-white' : 'bg-white'
              } rounded-xl py-2 px-3 cursor-pointer mx-2 my-1 flex-shrink-0`}
              onClick={handelToday}
            >
              Today
            </span>
            <span
              className={`${
                content === 'yesterday' ? 'bg-blue-500 text-white' : 'bg-white'
              } rounded-xl py-2 px-3 cursor-pointer mx-2 my-1 flex-shrink-0`}
              onClick={handleYesterday}
            >
              Yesterday
            </span>
            <span
              className={`${
                content === 'lastWeek' ? 'bg-blue-500 text-white' : 'bg-white'
              } rounded-xl py-2 px-3 cursor-pointer mx-2 my-1 flex-shrink-0`}
              onClick={handleLastWeek}
            >
              7 Days ago
            </span>
            <span
              className={`${
                content === 'lastMonth' ? 'bg-blue-500 text-white' : 'bg-white'
              } rounded-xl py-2 px-3 cursor-pointer mx-2 my-1 flex-shrink-0`}
              onClick={handleLastMonth}
            >
              30 Days ago
            </span>
            <span
              className={`${
                content === 'lastYear' ? 'bg-blue-500 text-white' : 'bg-white'
              } rounded-xl py-2 px-3 cursor-pointer mx-2 my-1 flex-shrink-0`}
              onClick={handleLastYear}
            >
              Year ago
            </span>
            <span
              className={`${
                content === 'custom' ? 'bg-blue-500 text-white' : 'bg-white'
              } rounded-xl py-2 px-3 cursor-pointer mx-2 my-1 flex-shrink-0`}
              onClick={showPicker}
            >
              Custom
            </span>
          </div>

          <input
            className={`${show ? 'block' : 'hidden'} md:w-1/3 p-2 rounded-xl inline-block text-xl`}
            type="date"
            value={date}
            onChange={e => handleCustomDate(e.target.value)}
          />

          <div className="my-4">
            <h2 className="text-2xl my-2 font-bold text-gray-500 capitalize">Money added from users by</h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-5 gap-5">
              {Object.entries(payments)?.map(([key, value], index) => (
                <div key={index} className="bg-white rounded-2xl p-4 font-semibold capitalize">
                  <h3 className="text-lg text-gray-500 capitalize">{key}</h3>
                  <span className="text-xl mt-2">{value} EGP</span>
                </div>
              ))}
            </div>

            <h2 className="text-2xl my-2 font-bold text-gray-500 capitalize">Money Withdrawn from users by</h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-5 gap-5">
              {Object.entries(withdraw)?.map(([key, value], index) => (
                <div key={index} className="bg-white rounded-2xl p-4 font-semibold capitalize">
                  <h3 className="text-lg text-gray-500 capitalize">{key}</h3>
                  <span className="text-xl mt-2">{value} EGP</span>
                </div>
              ))}
            </div>

            <h2 className="text-2xl my-2 font-bold text-gray-500 capitalize">Spending for</h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-5 gap-5">
              {Object.entries(transaction)?.map(([key, value], index) => (
                <div key={index} className="bg-white rounded-2xl p-4 font-semibold capitalize">
                  <h3 className="text-lg text-gray-500 capitalize">{key}</h3>
                  <span className="text-xl mt-2">{value} EGP</span>
                </div>
              ))}
            </div>

            <div className="flex flex-wrap text-xl items-center justify-between my-8">
              <h2 className="text-2xl font-bold text-gray-500 capitalize">Withdrawal Requests by vodafone cash</h2>
              <div className=" bg-white rounded-2xl p-4 space-x-3 font-semibold">
                <span
                  className={`${status === 'PENDING' ? 'text-blue-600' : 'text-gray-500'} cursor-pointer`}
                  onClick={() => {
                    handleStatus('PENDING');
                  }}
                >
                  Requests
                </span>
                <span
                  className={`${status === 'ACCEPTED' ? 'text-blue-600' : 'text-gray-500'} cursor-pointer`}
                  onClick={() => {
                    handleStatus('ACCEPTED');
                  }}
                >
                  Accepted Requests
                </span>
                <span
                  className={`${status === 'REJECTED' ? 'text-blue-600' : 'text-gray-500'} cursor-pointer`}
                  onClick={() => {
                    handleStatus('REJECTED');
                  }}
                >
                  Rejected Requests
                </span>
              </div>
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-5">
              {requests?.map((request, index) => (
                <Link
                  key={index}
                  to={`/dashboardwallet/${request.id}`}
                  className="bg-white rounded-2xl p-3 font-semibold capitalize flex items-center no-underline "
                >
                  <img
                    className="w-12 h-12 rounded-full"
                    src={request.user.avatar}
                    alt={FullNameConcat(request.user)}
                  />
                  <div className="ml-3">
                    <div className="flex items-center justify-between">
                      <h3 className="capitalize text-xl font-semibold text-black">{FullNameConcat(request.user)}</h3>
                      <span className="text-gray-500 text-sm">{convertDateToDateString(request.createdAt)}</span>
                    </div>
                    <div className="flex items-center justify-between">
                      <span className="text-black">{request.user.phone_number}</span>
                      <span className="mx-1 text-blue-500">{request.amount} EGP</span>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
