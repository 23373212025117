// ! not used file functionality added to AddLessonVideo file

import { useEffect, useRef, useState, useContext } from 'react';
import Sidebar from '../Components/Sidebar';
import cursor from '../Assets/arrowframe.png';
import '../Styles/EditCourse.css';
import uploadIcon from '../Assets/plus.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { upload } from '../APIs/UploadAPIs';
import addIcon from '../Assets/Frame 48095608 (1).png';
import {
  addArticle,
  addExam,
  addVideo,
  // editVideo,
  // editArticle,
  // editExam,
  getExam,
  getArticle,
  getVideo,
  getCourseContent,
  getWeekContent,
  getExamQuestions,
} from '../APIs/CourseAPIs';
import ToastContext from '../Context/ToastContext';
import LoadingSpinner from '../Components/LoadingSpinner';

export default function AddEditLesson() {
  const {
    state: { data },
  } = useLocation();
  const isNew = data.type;
  const { addToasts } = useContext(ToastContext);
  const [loading, setLoading] = useState(true);
  const [fileName, setFileName] = useState('No selected file');
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const fileInputRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(data.type ? data.type : 'Article');
  const [newQuestion, setNewQuestions] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [questionType, setQuestionType] = useState('One answer');
  const [questionOption, setQuestionOption] = useState('option1');
  const [questionTitle, setQuestionTitle] = useState('');
  const [answerOne, setAnswerOne] = useState('');
  const [answerTwo, setAnswerTwo] = useState('');
  const [answerThree, setAnswerThree] = useState('');
  const [answerFour, setAnswerFour] = useState('');

  const handleCheckedQuestion = event => {
    setNewQuestions(false);
    setSelectedQuestion(event.target.id + 1);
    setQuestionTitle(questions[event.target.id].title);
    setAnswerOne(questions[event.target.id].answerOne);
    setAnswerTwo(questions[event.target.id].answerTwo);
    setAnswerThree(questions[event.target.id].answerThree);
    setAnswerFour(questions[event.target.id].answerFour);
    let ans = [];
    if (questions[event.target.id].correctAnswer.includes(questions[event.target.id].answerOne)) {
      ans.push('option1');
    }
    if (questions[event.target.id].correctAnswer.includes(questions[event.target.id].answerTwo)) {
      ans.push('option2');
    }
    if (questions[event.target.id].correctAnswer.includes(questions[event.target.id].answerThree)) {
      ans.push('option3');
    }
    if (questions[event.target.id].correctAnswer.includes(questions[event.target.id].answerFour)) {
      ans.push('option4');
    }
    setQuestionOption(ans);
    setQuestionType(ans.length === 1 ? 'One answer' : 'More than 1 answer');
  };
  const lessonValid =
    ((selectedOption === 'Video' && fileName && description.trim()) ||
      (selectedOption === 'Article' && description.trim()) ||
      (selectedOption === 'Exam' && questions && description.trim() && questions.length)) &&
    title.trim();

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleQuestionOption = e => {
    if (questionType === 'One answer') {
      setQuestionOption([e.target.value]);
    } else {
      questionOption.includes(e.target.value)
        ? setQuestionOption(questionOption.filter(op => op !== e.target.value))
        : setQuestionOption([...questionOption, e.target.value]);
    }
  };

  const handleFileChange = event => {
    setFile(event.target.files[0]);
    event.target.files[0] && setFileName(event.target.files[0].name);
  };

  const navigate = useNavigate();

  const handleOptionChange = event => {
    setSelectedOption(event.target.value);
  };

  const handleQuestionType = event => {
    setQuestionType(event.target.value);
    if (event.target.value === 'One answer') {
      setQuestionOption(['option1']);
    } else {
      setQuestionOption([]);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (data.weekId) {
          const weekContentRes = await getWeekContent(data.weekId);
          const weekContent = weekContentRes.data.data;
          if (weekContent.articles) {
            setTitle(weekContent.articles[0].title);
            setDescription(weekContent.articles[0].description);
          }
          if (weekContent.videos) {
            setTitle(weekContent.videos[0].title);
            setDescription(weekContent.videos[0].description);
            // const link = weekContent.videos[0].split("/");
            // console.log(link)
            // setFileName(link[link.length - 1]);
            setFileName(weekContent.videos[0].video_link);
          }
          if (weekContent.exams) {
            const examQuestionsResponse = await getExamQuestions(weekContent.exams[0].id);
            const { exam } = examQuestionsResponse.data.data;
            setTitle(exam.title);
            setDescription(exam.description);
            let ques = exam.questions?.map(q => {
              return {
                title: q.question,
                answerOne: q.options[0],
                answerTwo: q.options[1],
                answerThree: q.options[2],
                answerFour: q.options[3],
                // correctAnswer: q.correctAnswers
              };
            });
            setQuestions(ques);
          }
          // if (data.type === "Exam") {
          // let l = await getExam(data._id);
          //   let tmp = await l.json();
          //   let ques = tmp.exam.questions?.map((q) => {
          //     return {
          //       title: q.question,
          //       answerOne: q.answers[0],
          //       answerTwo: q.answers[1],
          //       answerThree: q.answers[2],
          //       answerFour: q.answers[3],
          //       correctAnswer: q.correctAnswers
          //     };
          //   });
          //   setTitle(tmp.exam.title);
          //   setQuestions(ques);
          // } else if (data.type === "Article") {
          //   let l = await getArticle(data._id);
          //   let tmp = await l.json();
          //   setTitle(tmp.article.title);
          //   setDescription(tmp.article.description);
          // } else {
          //   let l = await getVideo(data._id);
          //   let tmp = await l.json();
          //   setTitle(tmp.video.title);
          //   setDescription(tmp.video.description);
          //   const link = tmp.video.link.split("/");
          //   setFileName(link[link.length - 1]);
          // }
        }
        setLoading(false);
      } catch (err) {
        addToasts({
          type: 'danger',
          body: 'unexpected error',
        });
      }
    })();
  }, []);

  return (
    <div className="AddLesson">
      <Sidebar></Sidebar>
      <div className="firsts">
        <div className="topCard">
          <div>
            <img
              onClick={e => navigate(-1)}
              className="cursorIcon"
              src={cursor}
              height={45}
              width={45}
              alt="image"
            ></img>
            <div>The course / Add lesson</div>
          </div>
        </div>
      </div>
      <div className="AddLessonContent">
        <div className="columnOne">
          <div className="lessonInfo">
            {/* <label>Lesson 1</label> */}
            {!isNew && (
              <div>
                <label>Type of lesson</label>
                <div className="typeOfLesson my-4">
                  <label className={selectedOption == 'Video' ? 'radio-label selected' : 'radio-label'}>
                    <i className="fa-solid fa-video"></i>
                    <input
                      type="radio"
                      name="options"
                      value="Video"
                      checked={selectedOption === 'Video'}
                      onChange={handleOptionChange}
                    />{' '}
                    Video
                  </label>
                  <label className={selectedOption == 'Article' ? 'radio-label selected' : 'radio-label'}>
                    <i className="fa-regular fa-newspaper"></i>
                    <input
                      type="radio"
                      name="options"
                      value="Article"
                      checked={selectedOption === 'Article'}
                      onChange={handleOptionChange}
                    />{' '}
                    Article
                  </label>
                  <label className={selectedOption == 'Exam' ? 'radio-label selected' : 'radio-label'}>
                    <input
                      type="radio"
                      name="options"
                      value="Exam"
                      checked={selectedOption === 'Exam'}
                      onChange={handleOptionChange}
                    />{' '}
                    Exam
                  </label>
                </div>
              </div>
            )}
            <label htmlFor="titleOfLesson"> Title of lesson</label>
            <input
              value={title}
              onChange={e => {
                setTitle(e.target.value);
              }}
              id="titleOfLesson"
              placeholder="Title of lesson"
            ></input>
            {(selectedOption === 'Video' || selectedOption === 'Article' || selectedOption == 'Exam') && (
              <>
                <label htmlFor="description">Description</label>
                <input
                  value={description}
                  onChange={e => {
                    setDescription(e.target.value);
                  }}
                  id="description"
                  placeholder="Description"
                ></input>
              </>
            )}
            {selectedOption === 'Video' && (
              <>
                {' '}
                <label htmlFor="description">Video</label>
                <div className="photoUpload">
                  <div className="uploadCustom" placeholder="hello">
                    <span style={{ color: '#116ACC' }}>{fileName}</span>
                    <input
                      style={{ display: 'none' }}
                      type="file"
                      ref={fileInputRef}
                      className="uploadInput"
                      placeholder="Photo"
                      onChange={handleFileChange}
                    ></input>
                  </div>
                  <img src={uploadIcon} alt="image" onClick={handleIconClick}></img>
                </div>
              </>
            )}
            <button
              disabled={!lessonValid}
              onClick={async e => {
                let tmp = { title };
                if (selectedOption === 'Video') {
                  if (file) {
                    let formData = new FormData();
                    formData.append('files', file);
                    let res = await upload(formData);
                    if (res.status === 200) {
                      const { fileLinks } = await res.json();
                      if (fileLinks) {
                        tmp.link = fileLinks[0];
                      }
                    }
                  }
                  tmp.weekId = data.weekId;
                  tmp.description = description;
                  if (data._id) {
                    // await editVideo(data._id, tmp);
                  } else {
                    await addVideo({
                      course: data.course,
                      weekId: data.weekId,
                      title,
                      description,
                      link: tmp.link,
                    });
                  }
                } else if (selectedOption === 'Article') {
                  tmp.description = description;
                  tmp.weekId = data.weekId;
                  if (data._id) {
                    // await editArticle(data._id, tmp);
                  } else {
                    await addArticle({
                      title,
                      description,
                      course: data.course,
                      weekId: data.weekId,
                    });
                  }
                } else {
                  let tmp = questions?.map(q => {
                    return {
                      question: q.title,
                      answers: [q.answerOne, q.answerTwo, q.answerThree, q.answerFour],
                      correctAnswers: q.correctAnswer,
                    };
                  });
                  if (data._id) {
                    // await editExam(data._id, {
                    //   title,
                    //   questions: tmp,
                    //   weekId: data.weekId
                    // });
                  } else {
                    await addExam({
                      title,
                      questions: tmp,
                      course: data.course,
                      weekId: data.weekId,
                    });
                  }
                }
                navigate(-1);
              }}
              className="addButton"
            >
              {data._id ? 'Edit' : 'Add'}
            </button>
          </div>
          {selectedOption === 'Exam' && (selectedQuestion || newQuestion) && (
            <div className="typeOfQeustion">
              <label>Type of question</label>
              <div className="typeOfQuestionOptions">
                <label>
                  <input
                    type="radio"
                    name="typeOptions"
                    value="One answer"
                    checked={questionType === 'One answer'}
                    onChange={handleQuestionType}
                    className="usedRadio"
                  />{' '}
                  One answer
                </label>
                <label>
                  <input
                    type="radio"
                    name="typeOptions"
                    value="More than 1 answer"
                    checked={questionType === 'More than 1 answer'}
                    onChange={handleQuestionType}
                    className="usedRadio"
                  />{' '}
                  More than 1 answer
                </label>
              </div>
              <label>The question</label>
              <input
                className="questionInput"
                value={questionTitle}
                onChange={e => {
                  setQuestionTitle(e.target.value);
                }}
                placeholder="The question"
              ></input>
              <label>Answer 1</label>
              <input
                className="questionInput"
                placeholder="The question"
                value={answerOne}
                onChange={e => {
                  setAnswerOne(e.target.value);
                }}
              ></input>
              <label>
                <input
                  type={questionType === 'One answer' ? 'radio' : 'checkbox'}
                  name="َquestionAnswers"
                  value="option1"
                  checked={questionOption.includes('option1')}
                  onChange={handleQuestionOption}
                  className="usedRadio"
                />{' '}
                This is the correct answer
              </label>
              <label>Answer 2</label>
              <input
                className="questionInput"
                placeholder="The question"
                value={answerTwo}
                onChange={e => {
                  setAnswerTwo(e.target.value);
                }}
              ></input>
              <label>
                <input
                  type={questionType === 'One answer' ? 'radio' : 'checkbox'}
                  name="َquestionAnswers"
                  value="option2"
                  checked={questionOption.includes('option2')}
                  onChange={handleQuestionOption}
                  className="usedRadio"
                />{' '}
                This is the correct answer
              </label>
              <label>Answer 3</label>
              <input
                className="questionInput"
                placeholder="The question"
                value={answerThree}
                onChange={e => {
                  setAnswerThree(e.target.value);
                }}
              ></input>
              <label>
                <input
                  type={questionType === 'One answer' ? 'radio' : 'checkbox'}
                  name="َquestionAnswers"
                  value="option3"
                  checked={questionOption.includes('option3')}
                  onChange={handleQuestionOption}
                  className="usedRadio"
                />{' '}
                This is the correct answer
              </label>
              <label>Answer 4</label>
              <input
                className="questionInput"
                placeholder="The question"
                value={answerFour}
                onChange={e => {
                  setAnswerFour(e.target.value);
                }}
              ></input>
              <label>
                <input
                  type={questionType === 'One answer' ? 'radio' : 'checkbox'}
                  name="َquestionAnswers"
                  value="option4"
                  checked={questionOption.includes('option4')}
                  onChange={handleQuestionOption}
                  className="usedRadio"
                />{' '}
                This is the correct answer
              </label>
              <button
                onClick={() => {
                  if (newQuestion) {
                    let ans = [];
                    if (questionOption.includes('option4')) {
                      ans.push(answerFour);
                    }
                    if (questionOption.includes('option3')) {
                      ans.push(answerThree);
                    }
                    if (questionOption.includes('option2')) {
                      ans.push(answerTwo);
                    }
                    if (questionOption.includes('option1')) {
                      ans.push(answerOne);
                    }
                    setQuestions([
                      ...questions,
                      {
                        title: questionTitle,
                        answerOne,
                        answerTwo,
                        answerThree,
                        answerFour,
                        correctAnswer: ans,
                      },
                    ]);
                  } else {
                    let tmp = [];
                    for (let i in questions) {
                      if (selectedQuestion !== i + 1) {
                        tmp.push(questions[i]);
                      } else {
                        let ans = [];
                        if (questionOption.includes('option4')) {
                          ans.push(answerFour);
                        }
                        if (questionOption.includes('option3')) {
                          ans.push(answerThree);
                        }
                        if (questionOption.includes('option2')) {
                          ans.push(answerTwo);
                        }
                        if (questionOption.includes('option1')) {
                          ans.push(answerOne);
                        }
                        tmp.push({
                          title: questionTitle,
                          answerOne,
                          answerTwo,
                          answerThree,
                          answerFour,
                          correctAnswer: ans,
                        });
                      }
                    }
                    setQuestions(tmp);
                  }
                  setNewQuestions(false);
                  setSelectedQuestion(0);
                  setQuestionTitle('');
                  setAnswerOne('');
                  setAnswerTwo('');
                  setAnswerThree('');
                  setAnswerFour('');
                  setQuestionOption(['option1']);
                  setQuestionType('One answer');
                }}
                className="addButton"
              >
                {newQuestion ? 'Add' : 'Edit'}
              </button>
            </div>
          )}
          {selectedOption === 'Exam' && (selectedQuestion || newQuestion) && (
            <div className="questionPreview">
              <label>Preview the questions</label>
              <div>the question is</div>

              <div className="previewOption">
                <input
                  id="answerOne"
                  className="usedRadio"
                  type={questionType === 'One answer' ? 'radio' : 'checkbox'}
                  checked={questionOption.includes('option1')}
                ></input>
                <label htmlFor="answerOne">Answer one</label>
              </div>
              <div className="previewOption">
                <input
                  id="answerTwo"
                  className="usedRadio"
                  type={questionType === 'One answer' ? 'radio' : 'checkbox'}
                  checked={questionOption.includes('option2')}
                ></input>
                <label htmlFor="answerTwo">Answer two</label>
              </div>
              <div className="previewOption">
                <input
                  id="answerThree"
                  className="usedRadio"
                  type={questionType === 'One answer' ? 'radio' : 'checkbox'}
                  checked={questionOption.includes('option3')}
                ></input>
                <label htmlFor="answerThree">Answer three</label>
              </div>
              <div className="previewOption">
                <input
                  id="answerFour"
                  className="usedRadio"
                  type={questionType === 'One answer' ? 'radio' : 'checkbox'}
                  checked={questionOption.includes('option4')}
                ></input>
                <label htmlFor="answerFour">Answer four</label>
              </div>
            </div>
          )}
        </div>
        {selectedOption === 'Exam' && (
          <div className="questionsColumn">
            <div>
              <div className="text-dark_gray">Questions</div>
              <img
                src={addIcon}
                alt="image"
                width={40}
                height={40}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setNewQuestions(true);
                  setSelectedQuestion(0);
                  setQuestionTitle('');
                  setAnswerOne('');
                  setAnswerTwo('');
                  setAnswerThree('');
                  setAnswerFour('');
                  setQuestionOption('option1');
                }}
              ></img>
            </div>
            {questions?.map((q, index) => (
              <div id={index} className="question" onClick={handleCheckedQuestion}>
                <div id={index}>{q.title}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
