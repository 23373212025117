import { useContext, useEffect, useState } from 'react';
import ToastContext from '../Context/ToastContext';
import { searchUsers, sendNotification } from '../APIs/UserAPIs';
import FullNameConcat from '../Utils/FullNameConcat';

const NotificationSenderHook = () => {
  // manage toasts
  const { addToasts } = useContext(ToastContext);

  // define states
  const [text, setText] = useState({
    ar: '',
    en: '',
  });
  const [selectedUsers, setSelectedUsers] = useState([]);
  // arabic text on change handler
  const arabicTextOnChangeHandler = e => {
    setText(prev => ({ ...prev, ar: e.target.value }));
  };

  // english text on change handler
  const englishTextOnChangeHandler = e => {
    setText(prev => ({ ...prev, en: e.target.value }));
  };

  // select user handler
  const setSelectedOptions = (v, e) => {
    if (e.action === 'select-option' && e.option.value === 'All') {
      setSelectedUsers([{ value: 'All', label: 'All' }]);
    } else if (e.action === 'remove-value' && e.removedValue.value === 'All') {
      setSelectedUsers([]);
    } else if (e.action === 'remove-value') {
      setSelectedUsers(v.filter(o => o.value !== 'All'));
    } else {
      setSelectedUsers(v);
    }
  };

  // send notification handler
  const onClick = async e => {
    e.preventDefault();
    let receivers;
    // search for All in selected users if found send it
    if (selectedUsers.find(user => user.value === 'All')) {
      receivers = 'All';
    } else receivers = selectedUsers.filter(user => user.value !== 'All')?.map(user => user.value);
    sendNotification({
      text,
      users: receivers,
    })
      .then(res => {
        if (res.status === 200) {
          // reset states
          setText({
            ar: '',
            en: '',
          });
          setSelectedUsers([]);

          addToasts({
            type: 'success',
            body: 'Notification sent successfully',
          });
        }
      })
      .catch(err => {
        // show error toast if response is failed
        addToasts({
          type: 'danger',
          body: `${err.response.data?.msg || 'something went wrong, please try again later'}`,
        });
      });
  };

  const userSearchOptions = async inputValue => {
    try {
      const res = await searchUsers({ skip: 0, text: inputValue });
      return res.data?.data?.users?.map(user => {
        return {
          value: user.user_id,
          label: user.first_name + ' ' + user.last_name,
          username: user.username,
          avatar: user.avatar,
          role: user.role,
        };
      });
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  return [
    setSelectedOptions,
    selectedUsers,
    text,
    onClick,
    arabicTextOnChangeHandler,
    englishTextOnChangeHandler,
    userSearchOptions,
  ];
};

export default NotificationSenderHook;
