import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ToastContext from '../Context/ToastContext';
import { getLoanRequests } from '../APIs/LoanAPIs';

const useLoanRequests = () => {
  const [data, setData] = useState([]);
  const [type, setType] = useState('Requests');
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const { addToasts } = useContext(ToastContext);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setLoading(true);
      let searchQuery;
      if (type == 'Requests') searchQuery = ['pending'];
      else if (type == 'Acceptable requests') searchQuery = ['approved'];
      else searchQuery = ['rejected'];

      const { data } = await getLoanRequests({ page, status: searchQuery });
      setData(data.data.loanRequests);
      setTotalPage(Math.ceil(data.data.numberOfLoanRequests / 20));
      setLoading(false);
    } catch (err) {
      addToasts({
        type: 'danger',
        body: 'unexpected error',
      });
    }
  };

  function handlePageChange(newPage) {
    setPage(newPage);
  }

  function handleTypeChange(type) {
    setType(type);
  }

  useEffect(() => {
    fetchData();
  }, [type, page]);

  return {
    type,
    navigate,
    loading,
    page,
    totalPages,
    handlePageChange,
    data,
    handleTypeChange,
  };
};

export default useLoanRequests;
