import React from 'react';
import FullNameConcat from '../../../Utils/FullNameConcat';
function TransferTransactionData({ transaction, user_id }) {
  return (
    <>
      <p className="font-bold text-lg mb-1">
        {transaction.sender_id === user_id ? (
          <>
            Sent to <span className="text-blue-500">{FullNameConcat(transaction.receiver)}</span>
          </>
        ) : (
          <>
            Received from <span className="text-blue-500">{FullNameConcat(transaction.sender)}</span>
          </>
        )}{' '}
      </p>
      <span className="mb-2">{transaction.description}</span>
    </>
  );
}

export default TransferTransactionData;
