import React, { useContext } from 'react';
import BasicModal from '../Components/Modal';
import UserContext from '../Context/UserContext';
import FullNameConcat from '../Utils/FullNameConcat';

function LoginSection({ onLogout }) {
  const { userData } = useContext(UserContext);

  return (
    <div
      className="flex items-center justify-between gap-2 w-1/2 md:w-4/5
    flex-shrink-0 max-w-80"
    >
      <div className="flex items-center flex-grow flex-shrink-0">
        <img
          src={userData.avatar}
          crossOrigin="anonymous"
          alt="Profile Avatar"
          className="w-12 h-12 rounded-full mr-4 "
        />
        <div className="flex-col hidden md:flex">
          <span className="text-gray-700 font-bold">{userData.email}</span>
          <span className="block text-sm text-gray-500">{FullNameConcat(userData)}</span>
        </div>
      </div>

      <BasicModal buttonName="Logout" question="Are you sure you want to logout?" onAccept={onLogout} />
    </div>
  );
}
export default LoginSection;
