import React from 'react';
import edit from '../../Assets/edit.png';

const EditButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="rounded-lg px-4 py-2 w-fit">
      <img src={edit} alt="edit" className="w-12" />
    </button>
  );
};

export default EditButton;
