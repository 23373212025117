import { useEffect, useState, useContext } from 'react';
import { getHubRecommendation } from '../../APIs/HubAPIs';
import ToastContext from '../../Context/ToastContext';

export default function useHub() {
  const { addToasts } = useContext(ToastContext);
  const [recommendedItems, setRecommendedItems] = useState({
    MEAL: [],
    PRODUCT: [],
    COURSE: [],
  });
  const [loading, setLoading] = useState(true);

  const fetchHub = async () => {
    setLoading(true);
    try {
      const { data } = await getHubRecommendation();
      setRecommendedItems(data.data);
    } catch (err) {
      addToasts({
        type: 'danger',
        body: `${err.response.data?.msg || 'something went wrong, please try again later'}`,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHub();
  }, []);

  return {
    meals: recommendedItems.MEAL,
    products: recommendedItems.PRODUCT,
    courses: recommendedItems.COURSE,
    loading,
    fetchHub,
  };
}
