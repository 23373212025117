import React, { useState, useEffect, useContext } from 'react';
import '../Styles/Home.css';
import Sidebar from '../Components/Sidebar';
import BasicModal from '../Components/Modal';
import ToastContext from '../Context/ToastContext';
import UserContext from '../Context/UserContext';
import LoadingSpinner from '../Components/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { clearSession } from '../Utils/SessionUtils';
import LoginSection from '../Components/LoginSection';
import fetchData from '../APIs/HomeAPI';
import Switch from 'react-switch';
import RoommateRequest from '../Components/RoommateRequest';
import MealRequest from '../Components/MealRequest';
import LoanRequest from '../Components/LoanRequest';
import StoreOrder from '../Components/StoreOrder';
import VerificationRequest from '../Components/VerificationRequest/VerificationRequest';
import Layout from '../Components/_common/Layout';

export default function Home() {
  const navigate = useNavigate();
  const { addToasts } = useContext(ToastContext);
  const { userData, setUserData } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [verificationData, setVerificationData] = useState([]);
  const [roommateData, setRoommateData] = useState([]);
  const [mealData, setMealData] = useState([]);
  const [loanData, setLoanData] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [updates, setUpdates] = useState(true);

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      setLoading(true);
      const data = await fetchData();
      setVerificationData(data.verificationData);
      setRoommateData(data.roommateData);
      setMealData(data.mealData);
      setLoanData(data.loanData);
      setStoreData(data.storeData);
      setUpdates(data.updates);
      console.log(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching initial data:', error);
      addToasts({
        type: 'danger',
        body: 'Unexpected error',
      });
    }
  };

  const handleLogout = () => {
    setUserData({});
    clearSession();
    navigate('/signin');
  };

  const handleProfileClick = id => {
    navigate('/profiles/verificationrequests/verificationrequest', { state: { id } });
  };

  return (
    <Layout title={'Home'} Action={<LoginSection onLogout={handleLogout} />}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div id="homepagecards">
          <RoommateRequest data={roommateData} navigate={navigate} />
          <VerificationRequest data={verificationData} navigate={navigate} />
          <MealRequest data={mealData} navigate={navigate} />
          <LoanRequest data={loanData} navigate={navigate} />
          <StoreOrder data={storeData} navigate={navigate} />
        </div>
      )}
    </Layout>
  );
}
