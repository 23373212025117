import Select from 'react-select';
import uploadIcon from '../Assets/plus.png';
import '../Styles/AddUniversity.css';
import LoadingSpinner from '../Components/LoadingSpinner';
import Cities from '../Constants/Cities';
import useAddUniversity from '../Hooks/useAddUniversity';
import Layout from '../Components/_common/Layout';

export default function AddUniversity() {
  const {
    loading,
    fileName,
    fileInputRef,
    handleIconClick,
    handleFileChange,
    handleFieldChange,
    onClick,
    handleStatusChange,
    handleGenreChange,
    setCity,
    isValid,
    about,
    city,
    colleges,
    founder,
    genre,
    graduates,
    link,
    location,
    name,
    professors,
    selectedStatus,
  } = useAddUniversity();

  return (
    <Layout title={'Add University'}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="my-5 bg-white rounded-2xl grid md:grid-cols-2 md:gap-5">
          <div className="p-5 flex flex-col gap-3">
            <label>Title of university</label>
            <input
              className="border-2 p-2 rounded-xl"
              value={name}
              onChange={e => handleFieldChange('name')(e)}
              placeholder="Title of university"
            ></input>
            <label>About</label>
            <input
              className="border-2 p-2 rounded-xl"
              value={about}
              onChange={e => handleFieldChange('about')(e)}
              placeholder="About"
            ></input>
            <label>Website link </label>
            <input
              className="border-2 p-2 rounded-xl"
              value={link}
              onChange={e => handleFieldChange('link')(e)}
              placeholder="Website link"
            ></input>
            <label>University photo</label>
            <div className="photoUpload">
              <div className="uploadCustom" placeholder="hello">
                <span style={{ color: '#116ACC' }}>{fileName}</span>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  ref={fileInputRef}
                  className="uploadInput"
                  placeholder="Photo"
                  onChange={handleFileChange}
                ></input>
              </div>
              <img src={uploadIcon} alt="image" onClick={handleIconClick}></img>
            </div>
            <label>University location</label>
            <input
              className="border-2 p-2 rounded-xl"
              value={location}
              onChange={e => handleFieldChange('location')(e)}
              placeholder="location"
            ></input>
            <label>City</label>
            <Select
              options={Cities}
              isSearchable={true}
              onChange={setCity}
              value={city}
              placeholder="Select an option"
            />
            <label>University status</label>
            <div className="pageStatus">
              <label className={selectedStatus === 'Special' ? 'radio-label selected' : 'radio-label'}>
                <input
                  type="radio"
                  name="type"
                  value="Special"
                  checked={selectedStatus === 'Special'}
                  onChange={e => handleStatusChange('Special')}
                />
                Special
              </label>
              <label className={selectedStatus === 'Public' ? 'radio-label selected' : 'radio-label'}>
                <input
                  type="radio"
                  name="type"
                  value="Public"
                  checked={selectedStatus === 'Public'}
                  onChange={e => handleStatusChange('Public')}
                />
                Public
              </label>
            </div>
          </div>
          <div className="p-5 flex flex-col gap-3">
            <label>Founder</label>
            <input
              className="border-2 p-2 rounded-xl"
              value={founder}
              onChange={e => handleFieldChange('founder')(e)}
              placeholder="Founder"
            ></input>
            <label>Genre or type of university</label>
            <label className={genre === 'Private' ? 'radio-label selected' : 'radio-label'}>
              <input
                type="radio"
                name="options"
                value="Private"
                checked={genre === 'Private'}
                onChange={e => handleGenreChange('Private')}
              />{' '}
              Private
            </label>
            <label className={genre === 'Public' ? 'radio-label selected' : 'radio-label'}>
              <input
                type="radio"
                name="options"
                value="Public"
                checked={genre === 'Public'}
                onChange={e => handleGenreChange('Public')}
              />{' '}
              Public
            </label>
            <label>Collages</label>
            <input
              className="border-2 p-2 rounded-xl"
              value={colleges}
              onChange={e => handleFieldChange('colleges')(e)}
              type="number"
              placeholder="Collages"
            ></input>
            <label>Professors</label>
            <input
              className="border-2 p-2 rounded-xl"
              value={professors}
              onChange={e => handleFieldChange('professors')(e)}
              type="number"
              placeholder="Professors"
            ></input>
            <label>Graduate students</label>
            <input
              className="border-2 p-2 rounded-xl"
              value={graduates}
              onChange={e => handleFieldChange('graduates')(e)}
              type="number"
              placeholder="Graduate students"
            ></input>
            <button disabled={!isValid} onClick={onClick} className="addButton">
              Add
            </button>
          </div>
        </div>
      )}
    </Layout>
  );
}
