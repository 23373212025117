import Sidebar from '../Components/Sidebar';
import TopCard from '../Components/TopCard';
import '../Styles/Profile.css';
import reactions from '../Assets/vector (1).png';
import love from '../Assets/love.png';
import comment from '../Assets/u_comment.png';
import { BiRepost } from 'react-icons/bi';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../Components/LoadingSpinner';
import FullNameConcat from '../Utils/FullNameConcat';
import convertDateToDateString from '../Utils/convertDateToDateString';
import { useProfile } from '../Hooks/useProfile';
import InfoCard from '../Components/Profile/InfoCard';
import InfoRow from '../Components/Profile/InfoRow';
import InfoRowWithButton from '../Components/Profile/AccountInformation/InfoRowWithButton';
import PostCard from '../Components/Profile/UserPosts/PostCard';
import UserProfileInfo from '../Components/Profile/UserProfileInfo';
import UserRoleBadge from '../Components/_common/UserRoleBadge';
import { IoMdMale, IoMdFemale } from 'react-icons/io';
import Layout from '../Components/_common/Layout';
import BasicModal from '../Components/Modal';

export default function Profile() {
  const {
    state: { userId },
  } = useLocation();
  const {
    data,
    wallet,
    ordersCount,
    loanCount,
    mealCount,
    coursesCount,
    posts,
    postsMaxCount,
    loading,
    handleBanUser,
    handleUnBanUser,
  } = useProfile(userId);

  return (
    <Layout
      title={'Profile'}
      Action={
        data.user ? (
          <div className="flex-shrink-0 max-w-80">
            {data.user.is_blocked !== true ? (
              <BasicModal
                buttonName="Ban User"
                question="Are you sure you want to ban this user?"
                onAccept={handleBanUser}
              />
            ) : (
              <BasicModal
                buttonName="Unban User"
                question="Are you sure you want to unban this user?"
                color="green"
                onAccept={handleUnBanUser}
              />
            )}
          </div>
        ) : null
      }
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {/* <Modal */}

          <div className="dataCards">
            <div className="dataInfoCard">
              <div>
                <div>
                  <img
                    crossOrigin="anonymous"
                    src={data.user.avatar}
                    alt="Avatar"
                    className="rounded-full w-24 h-24 object-cover"
                  />
                </div>
                <div className="flex flex-row justify-between items-center flex-wrap w-full py-2">
                  <div className="flex flex-col gap-2">
                    <span className="font-bold">{FullNameConcat(data.user)}</span>
                    <span className="text-dark_gray">@{data.user.username}</span>
                  </div>
                  <div className="flex flex-col gap-2">
                    <UserRoleBadge role={data.user.role} />
                    <UserProfileInfo
                      label={'Gender'}
                      value={
                        <>
                          {data.user.gender === 'MALE' ? (
                            <IoMdMale size={24} color="blue" />
                          ) : (
                            <IoMdFemale size={24} color="pink" />
                          )}
                        </>
                      }
                      className={'flex items-center'}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-row justify-between items-center flex-wrap w-full py-2">
                <UserProfileInfo
                  label={'Age'}
                  value={new Date().getFullYear() - new Date(data.user.date_of_birth).getFullYear()}
                />
                <UserProfileInfo label={'National'} value={data.user.nationality} />
                <UserProfileInfo label={'City'} value={data.user.city ? data.user.city : 'Not Registered at city'} />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <InfoCard src={reactions} alt="vector" count={postsMaxCount} label="Posts" />
                <InfoCard src={reactions} alt="vector" count={data.user.likesCount} label="Likes" />
                <InfoCard src={reactions} alt="vector" count={data.user.trusteeCount} label="Trusts" />
                <InfoCard src={reactions} alt="vector" count={data.user.visitors?.length} label="Visitors" />
              </div>
              <InfoRow
                label="University:"
                value={data.university?.name || 'Not Registered at university'}
                isStrikethrough={!data.university}
              />
              <InfoRow label="Email Address:" value={data.user.email} />
              <InfoRow label="Birth Address:" value={data.user.birthAddress} />
              <InfoRow label="Current Address:" value={data.user.currentAddress} />
              <InfoRow label="Phone Number:" value={data.user.phone_number} />
            </div>
            <div className="selfIntroduction">
              <h5 className="font-semibold text-lg text-gray-800">Self-introduction</h5>
              <div>{data.user.self_introduction}</div>
            </div>

            <div className="bg-white max-h-60 p-4 rounded-lg overflow-auto interestHobbies">
              <h5 className="font-semibold text-lg text-gray-800">Hobbies</h5>
              <div>
                {!data.hobbies ? (
                  <span className="text-gray-400">No hobbies to show</span>
                ) : (
                  data.hobbies?.map((hobby, hobbyIndex) => (
                    <span className="text-black" key={hobbyIndex}>
                      {hobby}
                    </span>
                  ))
                )}
              </div>
            </div>
            {/* FIXME: */}
            {/* {data.role === 'PARENT' && (
              <div className="parentSons">
                <div className="text-dark_gray">Sons</div>
                <div>
                  {data.sons?.map(son => {
                    return <>{son.name}</>;
                  })}
                </div>
              </div>
            )}
            {data.role === 'PARENT' && (
              <div className="parentJob">
                <div className="text-dark_gray">Job</div>
                <div>{data.job}</div>
              </div>
            )} */}
            <div className="bg-white max-h-60 p-4 rounded-lg overflow-auto flex flex-col justify-between">
              <h5 className="font-semibold text-lg text-gray-800">Account Information</h5>
              <InfoRow label=" Account created at:" value={convertDateToDateString(data.user.createdAt)} />
              <InfoRowWithButton
                label="Balance"
                value={`${wallet.balance} EGP`}
                buttonText="Wallet"
                navigateTo="/profiles/profile/wallet"
                userId={userId}
              />
              <InfoRow label="Loans taken:" value={loanCount} />
              <InfoRowWithButton
                label="Meals"
                value={mealCount}
                buttonText="View Meals"
                navigateTo="/meals/chef"
                userId={userId}
              />
              <InfoRow label="Orders" value={ordersCount} />
              <InfoRow label="Courses taken:" value={coursesCount} />
            </div>
            <div className="posts bg-white p-4 rounded-2xl mb-4">
              <h5 className="font-semibold text-lg text-gray-800">Posts</h5>
              {posts && !!posts.length ? (
                <div className="overflow-y-auto h-96">
                  {posts?.map((post, index) => (
                    <PostCard key={index} post={post} user={data.user} love={love} comment={comment} />
                  ))}
                </div>
              ) : (
                <span className="text-gray-400">No posts to show</span>
              )}
            </div>
          </div>
        </>
      )}
    </Layout>
  );
}
