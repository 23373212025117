import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './Modal.css';
import { useNavigate } from 'react-router-dom';

export default function BasicModal({ buttonName, question, onAccept, color }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const colors = {
    red: 'bg-red-600 hover:bg-red-700',
    green: 'bg-green-600 hover:bg-green-700',
  };
  const colorClass = colors[color] || colors.red;
  return (
    <>
      <button
        className={`text-white px-4 py-4 rounded text-lg font-semibold ${colorClass} whitespace-nowrap w-full`}
        onClick={handleOpen}
      >
        {buttonName}
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal">
          <Typography id="modal-modal-title" component="h2">
            {question}
          </Typography>
          <div className="yesNoButtons">
            <button
              onClick={e => {
                onAccept();
                handleClose();
              }}
            >
              Yes
            </button>
            <button onClick={handleClose}>No</button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
