import { getSession } from '../Utils/SessionUtils';
import api from './_common/helper';
import { baseURL as BASE_URL } from '../Constants/baseURL';

export async function createAccount(data) {
  return api.post('/register', data);
}

export async function getUserInfo() {
  return api.get('/user-info');
}

export async function searchUsers(data) {
  let baseURL = `/search/user?skip=${data.skip}`;
  if (data.role) {
    baseURL += `&role=${data.role}`;
  }
  if (data.text) {
    baseURL += `&text=${data.text}`;
  }
  return api.get(baseURL);
}

export async function getUsers(data) {
  return api.get(`/users?skip=${data.skip}&role=${data.role}`);
}

export async function sendNotification(data) {
  return api.post('/notification', data);
}

export async function getAllUsers() {
  return api.get('/allUsers');
}

export async function getUser(data) {
  return api.get(`/user/${data}`);
}

export async function getWallet(data) {
  return api.get(`/wallet/${data}`);
}

export async function editWalletBalance(data, balance) {
  return api.patch(`/wallet/user/${data}/balance`, { amount: balance });
}

export async function getUserLoanRequests(data) {
  return api.get(`/loanRequest/user/${data.id}?skip=${data.skip}`);
}

export async function getUserMeals(data) {
  return api.get(`/meal/user/${data.id}?skip=${data.skip}`);
}

export async function getUserMealsType(data) {
  return api.get(`/meal/user/${data.creator}?skip=${data.skip}&accepted=${data.accepted}&rejected=${data.rejected}`);
}

export async function getUserMealsOrders(data) {
  return fetch(`${BASE_URL}/user/orders/meals/${data.skip}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      token: getSession(),
    },
    body: JSON.stringify({
      chefStatus: data.chefStatus,
      chef: data.chef,
    }),
  });
}

export async function getUserOrders(data) {
  // /product/order/user/:userId
  return api.get(`/product/order/user/${data.id}?skip=${data.skip}`);
}

export async function getUserCourses(data) {
  return api.get(`/course/user/${data.id}?skip=${data.skip}`);
}

export async function getUserPosts(data) {
  // /userPosts/1?skip=
  return api.get(`/userPosts/${data.id}?skip=${data.skip}`);
}

export async function banUser(data) {
  return api.post(`/user/ban/${data}`);
}

export async function unbanUser(data) {
  return api.delete(`/user/ban/${data}`);
}
