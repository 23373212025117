import React, { useState,useRef } from 'react';

const SearchBar = ({ handleSearch, delay=5000 }) => {
  const [input, setInput] = useState('');
  const timeoutIdRef = useRef(null);
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setInput(inputValue);


    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(() => {
        handleSearch(inputValue);
      }, delay);
  
  };

  return (
    <div className="flex items-center justify-between bg-gray-200 mb-4 rounded-full">
      <input
        type="text"
        placeholder="Search"
        value={input}
        onChange={handleInputChange}
        className="border border-gray-300 px-4 py-2 rounded-lg flex-grow"
      />
    </div>
  );
};

export default SearchBar;
