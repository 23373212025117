import React from 'react';
import moment from 'moment';
import isTimeDifferenceMoreThanHour from '../../../Utils/Support/isTimeDifferenceMoreThanHour';
export default function TimeSeparator({ previousMessage, message }) {
  return (
    <>
      {previousMessage === null || isTimeDifferenceMoreThanHour(message.timestamp, previousMessage.timestamp) ? (
        <div className="w-full text-center mt-4">
          <span className="bg-orange-300 p-2 rounded-sm font-semibold text-white shadow-md">
            {moment(message.timestamp).format('MMMM Do YYYY, h:mm a')}
          </span>
        </div>
      ) : null}
    </>
  );
}
