import '../Styles/Courses.css';
import Sidebar from '../Components/Sidebar';
import LoadingSpinner from '../Components/LoadingSpinner';
import '../Components/Searchbar.css';
import addIcon from '../Assets/Frame 48095608 (1).png';
import CourseCard from '../Components/Pages/CoursesPage/CourseCard';
import CoursesHook from '../Hooks/CoursesHook';
import { Dropdown, Input, Select } from 'semantic-ui-react';
export default function Courses() {
  const [
    input,
    data,
    searchData,
    loading,
    handleInputChange,
    searchCoursesFilter,
    searchCoursesFilterHandler,
    paidStatusOptions,
    categoryOptions,
    courseFilter,
    selectCourseFilterHandler,
    addCourseHandler,
    handleSelectCourse,
  ] = CoursesHook();

  return (
    <div>
      <div>
        <div id="courses">
          <Sidebar></Sidebar>
          <div id="coursescontent">
            <div className="ml-[80px] mr-[5px] h-[53px] sticky top-0 z-200">
              <Input type="text" placeholder="Search..." action fluid className="h-full">
                <input value={input} onChange={handleInputChange} />
                <Select
                  compact
                  options={paidStatusOptions}
                  name="paidStatus"
                  value={searchCoursesFilter.paidStatus}
                  onChange={searchCoursesFilterHandler}
                  className="w-[80px]"
                />
                <Select
                  compact
                  options={categoryOptions}
                  name="category"
                  value={searchCoursesFilter.category}
                  onChange={searchCoursesFilterHandler}
                  className="w-[160px]"
                />
              </Input>
            </div>
            <div className="course-filter-card">
              <div className="flex justify-between items-center">
                <div className="flex items-center justify-between">
                  <div className="course-filter">
                    <span
                      className={`${courseFilter === 'default' ? 'selected' : ''} course-filter__item`}
                      id="default"
                      onClick={selectCourseFilterHandler}
                    >
                      Default Courses
                    </span>
                    <span
                      className={`${courseFilter === 'selling' ? 'selected' : ''} course-filter__item`}
                      id="selling"
                      onClick={selectCourseFilterHandler}
                    >
                      Selling Courses
                    </span>
                    <span
                      className={`${courseFilter === 'rating' ? 'selected' : ''} course-filter__item`}
                      id="rating"
                      onClick={selectCourseFilterHandler}
                    >
                      Rating Courses
                    </span>
                  </div>
                </div>
                <div>
                  <img
                    src={addIcon}
                    height={45}
                    width={45}
                    alt="addIcon"
                    style={{ cursor: 'pointer' }}
                    onClick={addCourseHandler}
                    crossOrigin="anonymous"
                  ></img>
                </div>
              </div>
            </div>

            {loading ? (
              <LoadingSpinner />
            ) : !searchData.length && !data.length ? (
              <h2 className="text-2xl my-10 w-full text-center">No Courses Found</h2>
            ) : (
              <div className="coursesCards">
                {searchData && searchData.length > 0
                  ? searchData?.map(course => (
                      <CourseCard key={course.id} course={course} handleSelectCourse={handleSelectCourse} />
                    ))
                  : data?.map(course => (
                      <CourseCard key={course.id} course={course} handleSelectCourse={handleSelectCourse} />
                    ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
