import React from 'react';

function StoreOrder({ data, navigate }) {
  const handleStoreOrderClick = orderId => {
    navigate('/store/orderdetails', { state: { id: orderId } });
  };

  return (
    <div className="homePageFirstCard">
      <div className="homepagecard1contentline1">
        <div>Orders from store</div>
        <div onClick={() => navigate('/store/orders')} style={{ cursor: 'pointer' }}>
          See all
        </div>
      </div>
      <div className="homePageFirstCardContent">
        {data?.map(order => (
          <div
            key={order.id}
            id={order.id}
            onClick={() => navigate('/store/orderdetails', { state: { id: order.id } })}
            style={{ cursor: 'pointer' }}
            className="storecard32"
          >
            <div className="sc3firstline">
              <div className="sc3firstlineleft">
                <img
                  src={order.seller.avatar}
                  width={60}
                  height={60}
                  style={{ borderRadius: '50%' }}
                  alt={order.title}
                />
                <div>
                  <div className="from">From</div>
                  <div>{order.seller.username}</div>
                </div>
              </div>
              <div className="sc3firstlineright">Details..</div>
            </div>
            <div className="sc3secondline">The order</div>
            <div className="sc3thirdline">
              <div className="Moscow">{order.city}</div>
              <div className="datepassed">{order.createdAt}</div>
            </div>
            <div className="sc3box2">
              <img src={order.images[0]} width={80} height={80} alt={order.title} />
              <div className="sc3boxleft">
                <div>{order.title}</div>
                <div className="storerub">{order.price} EGP</div>
                <div className="underline">
                  <div>{order.price} X 1</div>
                  <div>{order.price} EGP</div>
                </div>
              </div>
            </div>
            <div className="storecard3last">
              <div>Total:</div>
              <div>{order.price} EGP</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default StoreOrder;
