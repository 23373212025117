const convertTimeDecimalToTimeFormat = decimalTime => {
  // Convert decimal time to seconds
  const totalSeconds = decimalTime * 3600;

  // Extract hours, minutes, and seconds
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  // Format the time into "HH:MM:SS" format
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;

  return formattedTime;
};

export default convertTimeDecimalToTimeFormat;
