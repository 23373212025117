import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../Components/Sidebar';
import profilePicture from '../Assets/Ellipse 1 (1).png';
import arrowFrame from '../Assets/arrowframe.png';
import '../Styles/Trusts.css';
import { useState } from 'react';

export default function Trusts() {
  const {
    state: { data },
  } = useLocation();
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div className="trusts">
      <Sidebar></Sidebar>
      <div className="topCardTwo">
        <div className="topCardTwoFirstLine">
          <img
            src={arrowFrame}
            alt="image"
            onClick={handleGoBack}
            style={{ cursor: 'pointer' }}
            height={45}
            width={45}
          ></img>
          <div>Trusts</div>
        </div>
        <div className="topCardTwoProfile">
          <img src={data.avatar} alt="image" height={50} width={50} style={{ borderRadius: '50%' }}></img>
          <div>
            <div>{data.name}</div>
            <div>{data.email}</div>
          </div>
        </div>
      </div>
      <div className="trustsUsers">
        <div className="trustsProfiles">
          {data.trusts?.map(profile => (
            <div className="viewersProfile">
              <div>
                <img src={profile.avatar} alt="image" width={40} height={40} style={{ borderRadius: '50%' }}></img>
                <div>{profile.name}</div>
              </div>
              <div
                id={profile._id}
                onClick={e => {
                  navigate('/profiles/profile', {
                    state: { userId: e.target.id },
                  });
                }}
                style={{ cursor: 'pointer' }}
                className="viewersProfileProfile"
              >
                profile..
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
