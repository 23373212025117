import React from 'react';
import convertDateToDateString from '../../../Utils/convertDateToDateString';
import TransactionAmount from './TransactionAmount';
import CourseTransactionData from './CourseTransactionData';
import TransferTransactionData from './TransferTransactionData';
import MealTransactionData from './MealTransactionData';
import ProductTransactionData from './ProductTransactionData';

function TransactionCard({ transaction, user_id }) {
  return (
    <div className="bg-[#EEEEEE] p-2 rounded-lg flex justify-between items-center my-1">
      <div>
        {transaction.type === 'ADMIN' && <p className="font-bold text-lg mb-2">{transaction.description} </p>}
        {transaction.type === 'COURSE' && <CourseTransactionData transaction={transaction} user_id={user_id} />}
        {transaction.type === 'TRANSFER' && <TransferTransactionData transaction={transaction} user_id={user_id} />}
        {transaction.type === 'MEAL' && <MealTransactionData transaction={transaction} user_id={user_id} />}
        {transaction.type === 'STORE' && <ProductTransactionData transaction={transaction} user_id={user_id} />}
        <p className="text-[#9ca3af] text-base mb-2">{convertDateToDateString(transaction.createdAt)}</p>
      </div>
      <TransactionAmount transaction={transaction} user_id={user_id} />
    </div>
  );
}

export default TransactionCard;
