import { useContext, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ToastContext from '../Context/ToastContext';
import { deleteProduct, updateProduct } from '../APIs/ProductAPIs';
import { upload } from '../APIs/UploadAPIs';
import { cities } from '../Constants/egyptCitiesEnum';

const useProduct = () => {
  const {
    state: { data },
  } = useLocation();
  const navigate = useNavigate();
  const { addToasts } = useContext(ToastContext);

  const [fileName, setFileName] = useState(data.images[0]);
  const [title, setTitle] = useState(data.title);
  const [price, setPrice] = useState(data.price);
  const [deliveryFee, setDeliveryFee] = useState(data.deliveryFee);
  const [about, setAbout] = useState(data.about);
  const [file, setFile] = useState(null);
  const [city, setCity] = useState({ value: data.city, label: data.city });
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleChange = setter => value => setter(value);

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    }
  };

  const handleIconClick = () => fileInputRef.current.click();

  const productEdit = async event => {
    event.preventDefault();
    setLoading(true);

    try {
      const productData = {
        city: city.value,
        about,
        title,
        price,
        deliveryFee,
      };

      if (file) {
        const formData = new FormData();
        formData.append('files', file);
        const res = await upload(formData);

        if (!res.data?.fileLinks.length) {
          addToasts({ body: 'Error uploading the file', type: 'danger' });
        } else {
          productData.images = res.data.fileLinks;
        }
      }

      await updateProduct(data.id, productData);
      addToasts({ body: 'Product updated successfully', type: 'success' });
      navigate(-1);
    } catch (error) {
      console.error(error);
      addToasts({ body: 'Unexpected error', type: 'danger' });
    } finally {
      setLoading(false);
    }
  };

  const productDelete = async () => {
    try {
      await deleteProduct(data.id);
      addToasts({ body: 'Product deleted successfully', type: 'success' });
      navigate('/store');
    } catch (error) {
      console.error(error);
      addToasts({ body: 'Unexpected error', type: 'danger' });
    }
  };

  return {
    loading,
    cities,
    title,
    price,
    deliveryFee,
    about,
    fileName,
    city,
    productEdit,
    productDelete,
    handleIconClick,
    handleFileChange,
    handleAboutChange: handleChange(setAbout),
    handleCityChange: setCity,
    handleDeliveryChange: handleChange(setDeliveryFee),
    handlePriceChange: handleChange(setPrice),
    handleTitleChange: handleChange(setTitle),
    fileInputRef,
  };
};

export default useProduct;
