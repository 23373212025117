import React from 'react';

function MealTransactionData({ transaction, user_id }) {
  const meals = transaction?.item?.meals;
  if (!meals) return null;
  return (
    <>
      <p className="font-bold text-lg mb-1">
        {transaction.sender_id === user_id ? (
          'Paid for Meal Order'
        ) : (
          <>
            Received from {transaction.sender.first_name} {transaction.sender.last_name} for {transaction.item.title}{' '}
            Meal
          </>
        )}{' '}
      </p>
      <div className="space-y-2">
        {meals?.map((meal, index) => (
          <div key={index} className="border-b border-gray-200 py-2">
            <a className="font-semibold text-blue-500" href={meal.share_link}>
              {meal.title}
            </a>
            <span>
              {' '}
              - {meal.price} EGP x {meal.quantity} = {meal.price * meal.quantity} EGP
            </span>
          </div>
        ))}
      </div>
    </>
  );
}

export default MealTransactionData;
