import uploadIcon from '../Assets/plus.png';
import LoadingSpinner from '../Components/LoadingSpinner';
import Select from 'react-select';
import Layout from '../Components/_common/Layout';
import useProduct from '../Hooks/useEditProduct';

export default function EditProduct() {
  const {
    loading,
    title,
    price,
    deliveryFee,
    about,
    fileName,
    city,
    cities,
    productEdit,
    productDelete,
    handleIconClick,
    handleFileChange,
    handleAboutChange,
    handleCityChange,
    handleDeliveryChange,
    handlePriceChange,
    handleTitleChange,
    fileInputRef,
  } = useProduct();

  return (
    <Layout title={'Edit Product'}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="w-full md:w-1/2 p-5 my-5 mx-auto bg-white rounded-3xl">
          <label className="block capitalize mt-3">Title of product</label>
          <input
            className="border-[1px] border-black w-full rounded-xl p-2"
            value={title}
            onChange={e => handleTitleChange(e.target.value)}
            placeholder="Title of product"
          ></input>
          <label className="block capitalize mt-3">Price (EGP) </label>
          <input
            className="border-[1px] border-black w-full rounded-xl p-2"
            value={price}
            type="Number"
            onChange={e => handlePriceChange(e.target.value)}
            placeholder="Price (EGP)"
          ></input>
          <label className="block capitalize mt-3">Delivery fee (EGP)</label>
          <input
            className="border-[1px] border-black w-full rounded-xl p-2"
            value={deliveryFee}
            type="Number"
            onChange={e => handleDeliveryChange(e.target.value)}
            placeholder="Delivery fee (EGP)"
          ></input>
          <label className="block capitalize mt-3">About</label>
          <input
            className="border-[1px] border-black w-full rounded-xl p-2"
            value={about}
            onChange={e => handleAboutChange(e.target.value)}
            type="text"
            placeholder="About"
          ></input>
          <label className="block capitalize mt-3">Product photo</label>
          <div className="photoUpload">
            <div className="uploadCustom">
              <span className="text-[#116ACC]">{fileName}</span>
              <input
                style={{ display: 'none' }}
                type="file"
                ref={fileInputRef}
                className="uploadInput"
                placeholder="Photo"
                onChange={handleFileChange}
              ></input>
            </div>
            <img src={uploadIcon} onClick={handleIconClick} alt="upload" />
          </div>
          <label className="block capitalize mb-1">Cities</label>
          <Select
            options={cities}
            isSearchable={true}
            onChange={handleCityChange}
            value={city}
            placeholder="Select an option"
          />

          <button
            onClick={productDelete}
            className="w-full md:w-1/2 block my-5 bg-red-700 p-2 text-xl mx-auto rounded-md cursor-pointer text-white"
          >
            Delete Product
          </button>
          <button
            onClick={productEdit}
            className="w-full md:w-1/2 block bg-blue-500 p-2 text-xl mx-auto rounded-md cursor-pointer text-white"
          >
            Edit Product
          </button>
        </div>
      )}
    </Layout>
  );
}
