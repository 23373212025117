import React from 'react';
import convertDateToDateString from '../../Utils/convertDateToDateString';
import VideoInfo from './VideoInfo';
import VideoEngagementCard from './VideoEngagementCard';
import useVideoCard from '../../Hooks/Reels/useVideoCard';
import VideoControls from './VideoControls';
import DeleteReelWidget from './DeleteReelWidget';
function VideoCard({ reel, popReel }) {
  const {
    containerRef,
    videoRef,
    isPlaying,
    isMuted,
    currentTime,
    duration,
    isFullscreen,
    thumbnail,
    showDeleteModal,
    handleTimeUpdate,
    handleLoadedMetadata,
    handlePlayPause,
    setIsPlaying,
    handleMute,
    handleSeek,
    handleFullscreen,
    handleThumbnailClick,
    handleDeleteReel,
    handleDeleteReelButton,
  } = useVideoCard();
  return (
    <div className="flex flex-col items-center py-4">
      <div
        id="video-container"
        ref={containerRef}
        className={`flex items-center justify-center mb-2 max-w-80 min-w-52 max-h-[568.89px] aspect-reels text-white bg-black overflow-hidden relative ${
          isFullscreen ? 'w-full h-full' : ''
        }`}
      >
        <VideoInfo reel={reel} />
        <VideoEngagementCard reel={reel} />
        <video
          preload="none"
          crossOrigin="anonymous"
          ref={videoRef}
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={handleLoadedMetadata}
          onClick={handlePlayPause}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          className="w-full h-full"
          controls={false}
        >
          <source src={reel.video_url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {thumbnail && reel.thumbnail_url && (
          <div className="w-full h-full absolute top-0 left-0 flex justify-center items-center">
            <img
              crossOrigin="anonymous"
              src={reel.thumbnail_url}
              alt="Video Thumbnail"
              className="w-full h-full object-contain cursor-pointer"
              onClick={handleThumbnailClick}
            />
          </div>
        )}
        {(!thumbnail || !reel.thumbnail_url) && (
          <>
            <VideoControls
              {...{
                handlePlayPause,
                isPlaying,
                handleMute,
                isMuted,
                handleSeek,
                currentTime,
                duration,
                handleFullscreen,
                isFullscreen,
              }}
            />
            <DeleteReelWidget
              id={reel.id}
              handleDeleteReelButton={handleDeleteReelButton}
              handleDeleteReel={handleDeleteReel}
              showDeleteModal={showDeleteModal}
              popReel={popReel}
            />
          </>
        )}
      </div>
      <p className="text-sm text-gray-500">{convertDateToDateString(reel.createdAt)}</p>
    </div>
  );
}

export default VideoCard;
