import React from 'react';
import LoadingSpinner from '../Components/LoadingSpinner';
import { useState, useContext } from 'react';
import Sidebar from '../Components/Sidebar';
import TopCard from '../Components/TopCard';
import { GoPersonAdd } from 'react-icons/go';
import { createAccount } from '../APIs/UserAPIs';
import ToastContext from '../Context/ToastContext';
import DatePicker from 'react-multi-date-picker';
import Layout from '../Components/_common/Layout';
function CreateAccount() {
  const [loading, setLoading] = useState(true);
  const { addToasts } = useContext(ToastContext);

  const formInitialState = {
    first_name: '',
    last_name: '',
    middle_name: '',
    password: '',
    email: '',
    is_graduated: false,
    role: 'SUPPORT',
    college: '',
    gender: 'MALE',
    city: '',
    currentAddress: '',
    birthAddress: '',
    avatar: '',
    username: '',
    is_watching: false,
    working_universities: [],
    nationality: '',
    phone_number: '',
    date_of_birth: new Date(),
  };
  const [form, setForm] = useState(formInitialState);
  const resetForm = () => {
    setForm(formInitialState);
  };
  const handleChange = e => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    console.log(value);
    setForm(prevState => ({
      ...prevState,
      [e.target.name]: value,
    }));
  };
  const handleSubmit = async e => {
    e.preventDefault();
    console.log(form);
    try {
      setLoading(true);
      const res = await createAccount(form);
      if (res.status !== 200) {
        throw new Error(res.body);
      }
      console.log(res);
      setLoading(false);
      resetForm();
      addToasts({
        type: 'success',
        heading: 'Account Created Successfully',
      });
    } catch (error) {
      setLoading(false);
      console.log(error.response?.data?.errors);
      addToasts({
        type: 'danger',
        heading: 'Failed to Create Account',
        body: error.response?.data?.msg,
      });
    }
    // Here you would handle form submission, e.g. send the form data to your server
  };

  return (
    <Layout title="Create Account">
      <main>
        <div className="flex justify-center items-center py-6">
          <section className="divide-y-2 flex justify-center items-center md:w-1/2 w-full bg-white border shadow-lg rounded-xl p-7 flex-col">
            <header className="flex justify-center items-center mb-2">
              <GoPersonAdd className="text-6xl text-blue-500" />
              <h1 className="text-2xl font-bold text-gray-700">Create Account</h1>
            </header>
            <form onSubmit={handleSubmit} className="pt-2 flex flex-wrap h-fit flex-col w-full">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="first_name">
                  First Name
                </label>
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  value={form.first_name}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="last_name">
                  Last Name
                </label>
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  value={form.last_name}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="middle_name">
                  Middle Name
                </label>
                <input
                  type="text"
                  name="middle_name"
                  id="middle_name"
                  value={form.middle_name}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="Cpassword">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="Cpassword"
                  value={form.password}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="Cemail">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="Cemail"
                  value={form.email}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4 flex justify-between items-center">
                <div className="flex justify-start items-center gap-x-2">
                  <label
                    for="is_graduated"
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="is_graduated"
                  >
                    Is Graduate
                  </label>
                  <input
                    id="is_graduated"
                    type="checkbox"
                    name="is_graduated"
                    value={form.is_graduated}
                    onChange={handleChange}
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div>

                <div className="flex justify-start items-center gap-x-2">
                  <label for="is_watching" className="block text-gray-700 text-sm font-bold mb-2" htmlFor="is_watching">
                    Is Watching
                  </label>
                  <input
                    id="is_watching"
                    type="checkbox"
                    name="is_watching"
                    value={form.is_watching}
                    onChange={handleChange}
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="role">
                  Role
                </label>
                <select
                  name="role"
                  id="role"
                  value={form.role}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="SUPPORT" selected>
                    SUPPORT
                  </option>
                  <option value="ADMIN">ADMIN</option>
                  <option value="STUDENT">STUDENT</option>
                  <option value="PROFESSOR">PROFESSOR</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="college">
                  College
                </label>
                <input
                  type="text"
                  name="college"
                  id="college"
                  value={form.college}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="gender">
                  gender
                </label>
                <select
                  name="gender"
                  value={form.gender}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="MALE" selected>
                    MALE
                  </option>
                  <option value="FEMALE">FEMALE</option>
                </select>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="city">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  id="city"
                  value={form.city}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="currentAddress">
                  Current Address
                </label>
                <input
                  type="text"
                  name="currentAddress"
                  id="currentAddress"
                  value={form.currentAddress}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="birthAddress">
                  Birth Address
                </label>
                <input
                  type="text"
                  name="birthAddress"
                  id="birthAddress"
                  value={form.birthAddress}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="age">
                  Date of Birth
                </label>
                <input
                  type="date"
                  name="date_of_birth"
                  id="date_of_birth"
                  value={form.date_of_birth}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="avatar">
                  Avatar
                </label>
                <input
                  type="text"
                  name="avatar"
                  id="avatar"
                  value={form.avatar}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                  Username
                </label>
                <input
                  type="text"
                  name="username"
                  id="username"
                  value={form.username}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nationality">
                  Nationality
                </label>
                <input
                  type="text"
                  name="nationality"
                  id="nationality"
                  value={form.nationality}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone_number">
                  Phone Number
                </label>
                <input
                  type="text"
                  name="phone_number"
                  id="phone_number"
                  value={form.phone_number}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="flex items-center justify-center w-full">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Create Account
                </button>
              </div>
            </form>
          </section>
        </div>
      </main>
    </Layout>
  );
}

export default CreateAccount;
