import { useContext, useRef, useState, useReducer } from 'react';
import ToastContext from '../Context/ToastContext';
import { upload } from '../APIs/UploadAPIs';
import { addUniversity } from '../APIs/UniversityAPIs';
import { useNavigate } from 'react-router-dom';
const initialState = {
  loading: false,
  fileName: 'No selected file',
  file: null,
  type: 'Public',
  city: { value: 'Cairo', label: 'Cairo' },
  genre: 'Public',
  name: '',
  about: '',
  founder: '',
  location: '',
  link: '',
  graduates: 0,
  professors: 0,
  colleges: 0,
  isValid: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_CITY':
      return { ...state, city: action.payload, isValid: checkValidity({ ...state, city: action.payload }) };
    case 'SET_FILE':
      return {
        ...state,
        file: action.payload,
        fileName: action.payload.name,
        isValid: checkValidity({ ...state, file: action.payload }),
      };
    case 'SET_FIELD':
      return {
        ...state,
        [action.field]: action.payload,
        isValid: checkValidity({ ...state, [action.field]: action.payload }),
      };
    default:
      return state;
  }
}

function checkValidity(state) {
  const { file, city, genre, name, about, founder, location, link, graduates, professors, colleges } = state;
  return file && city && genre && name && about && founder && location && link && graduates && professors && colleges;
}

const useAddUniversity = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { addToasts } = useContext(ToastContext);
  const fileInputRef = useRef(null);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = event => {
    dispatch({ type: 'SET_FILE', payload: event.target.files[0] });
  };

  const handleFieldChange = field => event => {
    dispatch({ type: 'SET_FIELD', field, payload: event.target.value });
  };

  const handleStatusChange = selectedStatus => {
    dispatch({ type: 'SET_FIELD', field: 'type', payload: selectedStatus });
  };

  const handleGenreChange = selectedGenre => {
    dispatch({ type: 'SET_FIELD', field: 'genre', payload: selectedGenre });
  };

  const setCity = city => {
    dispatch({ type: 'SET_CITY', payload: city });
  };
  const setLoading = loading => {
    dispatch({ type: 'SET_LOADING', payload: loading });
  };

  const onClick = async event => {
    setLoading(true);
    event.preventDefault();
    try {
      if (!state.file) {
        addToasts({
          body: 'Please select a file.',
          type: 'danger',
        });
        return;
      }
      const formData = new FormData();
      formData.append('files', state.file);
      const res = await upload(formData);
      const url = res.data?.fileLinks[0];
      if (!url) {
        addToasts({
          type: 'danger',
          body: 'Error while uploading file',
        });
        return;
      }
      await addUniversity({
        city: state.city.value,
        genre: state.genre,
        name: state.name,
        about: state.about,
        founder: state.founder,
        location: state.location,
        link: state.link,
        graduates: state.graduates,
        professors: state.professors,
        colleges: state.colleges,
        photo: url,
        type: state.type,
      });
      addToasts({
        body: 'University added successfully',
        type: 'success',
      });
      setLoading(false);
      navigate(-1);
    } catch (err) {
      console.error(err);
      addToasts({
        body: err.response?.data?.msg || 'Error adding university',
        type: 'danger',
      });
    }
  };

  return {
    city: state.city,
    genre: state.genre,
    name: state.name,
    about: state.about,
    founder: state.founder,
    location: state.location,
    link: state.link,
    graduates: state.graduates,
    professors: state.professors,
    colleges: state.colleges,
    file: state.file,
    fileName: state.fileName,
    loading: state.loading,
    fileInputRef,
    selectedStatus: state.type,
    isValid: state.isValid,
    handleIconClick,
    handleFileChange,
    handleFieldChange,
    handleStatusChange,
    handleGenreChange,
    setCity,
    onClick,
  };
};

export default useAddUniversity;
