import RoleButton from './RoleButton';

function RoleSelector({ roles, selectedRole, setRole, textColor }) {
  const isSelected = role => (role === selectedRole ? 'selected_role' : '');

  return (
    <nav className={`font-bold text-xl text-[${textColor}] flex gap-4 flex-wrap `}>
      {roles?.map(role => (
        <RoleButton key={role} role={role} isSelected={isSelected} setRole={setRole} />
      ))}
    </nav>
  );
}

export default RoleSelector;
