import Sidebar from '../Components/Sidebar';
import TopCard from '../Components/TopCard';
import '../Styles/LoanRequest.css';
import BasicModalTwo from '../Components/ModalTwo';
import { useEffect, useState, useContext } from 'react';
import ToastContext from '../Context/ToastContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { acceptLoanRequest, rejectLoanRequest, getLoanRequest, getPayments } from '../APIs/LoanAPIs';
import LoadingSpinner from '../Components/LoadingSpinner';
import checkIcon2 from '../Assets/u_check-circle.svg';
import checkIcon from '../Assets/u_check-circleInstall.svg';
import FullNameConcat from '../Utils/FullNameConcat';
import convertDateToDateString from '../Utils/convertDateToDateString';
import Layout from '../Components/_common/Layout';
import useLoanRequest from '../Hooks/useLoanRequest';

export default function LoansRequest() {
  const { loading, data, onAccept, onReject, payments } = useLoanRequest();

  return (
    <Layout title={'Request Details'}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="grid md:grid-cols-2 gap-5 py-5 h-screen">
          <section className="bg-white p-5 rounded-3xl overflow-y-auto">
            <div className="items-center space-x-3 flex">
              <img src={data.user.avatar} alt="image" style={{ width: 80, borderRadius: '50%' }}></img>
              <div>
                <span className="text-lg text-gray-400">From</span>
                <h1 className="text-2xl font-semibold">{FullNameConcat(data.user)}</h1>
              </div>
            </div>
            <div>
              <h3 className="text-lg text-gray-500">Loan value </h3>
              <p className="mb-2">{Math.round((data.loan.amount / 12) * 1000) / 1000} EGP</p>
              <h3 className="text-lg text-gray-500">Full name</h3>
              <p className="mb-2">{FullNameConcat(data.user)}</p>
              <h3 className="text-lg text-gray-500">Phone number</h3>
              <p className="mb-2">{data.user.phone_number}</p>
              <h3 className="text-lg text-gray-500">Issued at</h3>
              <p className="mb-2">{convertDateToDateString(data.createdAt)}</p>
              <h3 className="text-lg text-gray-500">What do you intend to do with loan?</h3>
              <p className="mb-2">{data.cause}</p>
              <h3 className="text-lg text-gray-500">How do you pay the loan installments?</h3>
              <p className="mb-2">{data.payingMethod}</p>
              <h3 className="text-lg text-gray-500">How long have you been in?</h3>
              <p className="mb-2">{data.durationInCountry}</p>
            </div>

            {!data.approvedDate && !data.rejectedDate && (
              <div>
                <BasicModalTwo
                  question="Are you sure you want to accept this loan?"
                  buttonName="Accept"
                  onAccept={onAccept}
                ></BasicModalTwo>
                <BasicModalTwo
                  question="Are you sure you want to reject this loan?"
                  buttonName="Reject"
                  onAccept={onReject}
                ></BasicModalTwo>
              </div>
            )}
            {data.approvedDate && !data.rejectedDate && (
              <div className="mt-3">
                <div className="text-2xl font-bold">Installments</div>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-5">
                  {payments?.map((user, index) => (
                    <div className="text-center bg-gray-200 rounded-3xl p-3">
                      <img src={user.payed ? checkIcon : checkIcon2} alt="image" width={40} height={40}></img>
                      <h4 className="text-xl">{index + 1}</h4>
                      <span className="text-lg text-gray-600">
                        {Math.round((data.loan.amount / 12) * 1000) / 1000} EGP
                      </span>
                      <div className="text-dark_gray">Paid on</div>
                      <span>{convertDateToDateString(user.createdAt)}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {!data.approvedDate && data.rejectedDate && (
              <p className="text-3xl mt-1 font-semibold text-red-800 text-center">The loan was already rejected</p>
            )}
          </section>

          <section className="bg-white p-5 rounded-3xl overflow-y-scroll">
            <h3 className="text-2xl text-gray-500">User photo</h3>
            <img src={data.userPhoto} alt="User" className="w-full" />

            <h3 className="text-2xl text-gray-500 mt-2">Passport photo</h3>
            <img src={data.passportPhoto} alt="Passport" />

            <h3 className="text-2xl text-gray-500 mt-2">All visas that are in the passport</h3>
            <div className=" overflow-x-scroll flex gap-2">
              {data.visaPhotos?.map((photo, index) => (
                <img key={index} src={photo} alt={`Visa ${index + 1}`} className="w-2/3" />
              ))}
            </div>
            <h3 className="text-2xl text-gray-500 mt-2">User hold his passport</h3>
            <img src={data.userWithPassportPhoto} alt="User with Passport" className="w-full" />

            <h3 className="text-2xl text-gray-500 mt-2">Proof of identity for user father, mother or older brother</h3>
            <img src={data.houseOwnershipPhoto} alt="House Owner" className="w-full" />

            <h3 className="text-2xl text-gray-500 mt-2">The contract</h3>
            <img src={data.contract} alt="Contract" className="w-full" />
          </section>
        </div>
      )}
    </Layout>
  );
}
