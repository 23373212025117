import { useReducer, useEffect, useCallback } from 'react';
import { getVRequests } from '../APIs/VRequestAPIs';

const initialState = {
  data: [],
  maxCount: 0,
  loading: false,
  error: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_INIT':
      return { ...state, loading: true, error: null };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        data: [...state.data, ...action.payload.result],
        maxCount: action.payload.numberOfVerfications,
      };
    case 'FETCH_FAILURE':
      return { ...state, loading: false, error: action.payload };
    default:
      throw new Error('Error in useVerificationRequests reducer');
  }
}

export default function useVerificationRequests() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchMoreData = useCallback(async () => {
    dispatch({ type: 'FETCH_INIT' });
    try {
      const searchQuery = { skip: state.data.length, status: 'pending' };
      const vRequest = await getVRequests(searchQuery);
      const result = vRequest.data.data.verificationRequests;
      const count = vRequest.data.data.count;
      dispatch({ type: 'FETCH_SUCCESS', payload: { result, numberOfVerfications: count } });
    } catch (err) {
      dispatch({ type: 'FETCH_FAILURE', payload: err.response?.msg || 'Failed to fetch data' });
    }
  }, [state.data.length]);

  useEffect(() => {
    fetchMoreData();
  }, [fetchMoreData]);

  const isNearBottom = (innerHeight, scrollTop, offsetHeight) => {
    const threshold = 100;
    return innerHeight + scrollTop + threshold >= offsetHeight;
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        !isNearBottom(window.innerHeight, document.documentElement.scrollTop, document.documentElement.offsetHeight) ||
        state.loading ||
        state.data.length >= state.maxCount
      )
        return;
      fetchMoreData();
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [state.loading, state.data, state.maxCount, fetchMoreData]);

  return { ...state, fetchMoreData };
}
