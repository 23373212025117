import React from 'react';
import { FaTrash } from 'react-icons/fa6';

function DeleteReelWidget({ popReel, id, handleDeleteReelButton, handleDeleteReel, showDeleteModal }) {
  return (
    <>
      <button className="absolute top-2 right-4 z-20" onClick={handleDeleteReelButton}>
        <FaTrash size={24} className="text-red-500 hover:text-red-600" />
      </button>
      {showDeleteModal && (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-30">
          <div className="bg-white p-4 rounded shadow-lg mx-4">
            <p className="text-lg text-gray-800">Are you sure you want to delete this reel?</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={handleDeleteReelButton}
                className="px-4 py-2 text-white bg-gray-400 hover:bg-gray-500 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleDeleteReel(id);
                  popReel(id);
                }}
                className="px-4 py-2 text-white bg-red-500 hover:bg-red-600 rounded"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DeleteReelWidget;
