import React from 'react';
import Layout from '../Components/_common/Layout';
import useReels from '../Hooks/Reels/useReels';
import VideoCard from '../Components/Reels/VideoCard';

function Reels() {
  const { reels, isFetching, popReel } = useReels();

  return (
    <Layout title="Reels">
      <div className="grid grid-cols-1 bg-white rounded my-4 p-4 divide-y-2">
        {reels?.map(reel => (
          <VideoCard key={reel.id} reel={reel} popReel={popReel} />
        ))}
      </div>
      {isFetching && <p className="text-center">Loading more reels...</p>}
      <div id="scroll-sentinel"></div>
    </Layout>
  );
}

export default Reels;
