import Sidebar from '../Components/Sidebar.js';
import '../Styles/OrdersToDo.css';
import arrowFrame from '../Assets/arrowframe.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { getUserMealsOrders } from '../APIs/UserAPIs.js';
import ToastContext from '../Context/ToastContext.js';
import LoadingSpinner from '../Components/LoadingSpinner.js';

export default function ChefOrders() {
  const {
    state: { userId },
  } = useLocation();
  const [data, setData] = useState([]);
  const { addToasts } = useContext(ToastContext);
  const [loading, setLoading] = useState(false);
  const [chefStatus, setChefStatus] = useState('PENDING');
  const [maxCount, setMaxCount] = useState(0);
  const navigate = useNavigate();
  const handleSelectOrder = e => {
    navigate('/meals/order', {
      state: { id: e.target.id },
    });
  };

  //  handle fetch data
  const fetchMoreData = async () => {
    try {
      setLoading(true);
      let searchQuery = { skip: 0, chefStatus, chef: userId };

      if (data.length && data[0].chefStatus != searchQuery.chefStatus) {
        searchQuery.skip = 0;
      }

      const meal = await getUserMealsOrders(searchQuery);
      if (meal.status !== 200) {
        addToasts({
          type: 'danger',
          body: 'network error',
        });
      }
      const { result, numberOfMealOrders } = await meal.json();
      console.log(result, numberOfMealOrders);
      setMaxCount(numberOfMealOrders);
      if (searchQuery.skip === 0) {
        setData([...result]);
      } else {
        setData([...data, ...result]);
      }
      setLoading(false);
    } catch (err) {
      addToasts({
        type: 'danger',
        body: 'unexpected error',
      });
    }
  };

  useEffect(() => {
    fetchMoreData();
  }, [chefStatus]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
        if (loading || data.length >= maxCount) return;
        fetchMoreData();
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, data, maxCount]);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <Sidebar></Sidebar>
          <div className="topCardTwo">
            <div className="topCardTwoFirstLine">
              <img
                src={arrowFrame}
                alt="image"
                width={45}
                height={45}
                onClick={() => navigate(-1)}
                style={{ cursor: 'pointer' }}
              ></img>
              <div>Orders</div>
            </div>
            <div className="topCardTwoSecondRow">
              <span
                className={`${chefStatus == 'PENDING' ? 'selected' : ''}`}
                onClick={() => {
                  setChefStatus('PENDING');
                }}
              >
                To do (Not accepted)
              </span>
              <span
                className={`${chefStatus == 'PREPARING' ? 'selected' : ''}`}
                onClick={() => {
                  setChefStatus('PREPARING');
                }}
              >
                Doing
              </span>
              <span
                className={`${chefStatus == 'Delivered' ? 'selected' : ''}`}
                onClick={() => {
                  setChefStatus('Delivered');
                }}
              >
                Done
              </span>
              <span
                className={`${chefStatus == 'REJECTED' ? 'selected' : ''}`}
                onClick={() => {
                  setChefStatus('REJECTED');
                }}
              >
                Rejected
              </span>
            </div>
          </div>
          <div className="ordersToDoCards">
            {data?.map(order => (
              <div
                key={order._id}
                id={order._id}
                onClick={handleSelectOrder}
                style={{ cursor: 'pointer' }}
                className="ordersToDoCard"
              >
                <div id={order._id} className="sc3firstline">
                  <div id={order._id} className="sc3firstlineleft">
                    <img id={order._id} src={order.chef.avatar} width={50} height={50} alt="image"></img>
                    <div id={order._id}>
                      <div id={order._id} className="from">
                        From
                      </div>
                      <div id={order._id}>{order.chef.name}</div>
                    </div>
                  </div>
                  <div id={order._id} className="sc3firstlineleft">
                    <img src={order.chef.avatar} width={50} height={50} alt="image"></img>
                    <div id={order._id}>
                      <div id={order._id} className="from">
                        To (chef)
                      </div>
                      <div id={order._id}>{order.user.name}</div>
                    </div>
                  </div>
                </div>
                <div id={order._id} className="sc3secondline">
                  The order
                </div>
                <div id={order._id} className="sc3thirdline">
                  <div id={order._id} className="Moscow">
                    {order.address}
                  </div>
                  <div id={order._id} className="text-dark_gray">
                    {order.createdAt}
                  </div>
                </div>
                {order.meals?.map(meal => (
                  <div key={meal._id} className="sc3box2">
                    <img src={meal.item.photo} width={80} height={80} alt="meal image"></img>
                    <div className="sc3boxleft">
                      <div>{meal.item.title}</div>
                      <div className="storerub">{meal.item.price} EGP</div>
                      <div className="underline">
                        <div>
                          {meal.price} X {meal.quantity}
                        </div>
                        <div>{meal.price * meal.quantity} EGP</div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="storecard3last">
                  <div>Total:</div>
                  <div>{order.totalPrice} EGP</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
