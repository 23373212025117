import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ToastContext from '../Context/ToastContext';
import { getEvents, searchEvents } from '../APIs/EventAPIs';

const useEvents = () => {
  const [data, setData] = useState([]);
  const [input, setInput] = useState('');
  const { addToasts } = useContext(ToastContext);
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState('event');
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();

  const handleSelectEvent = e => {
    navigate('/events/event', {
      state: { eventId: e.target.id },
    });
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      if (input !== '') {
        const { data } = await searchEvents(type, input, page);
        setData(data?.data?.events);
        setTotalPages(Math.ceil(data?.data?.count / 10));
      } else {
        const { data } = await getEvents(type, page);
        setData(data?.data?.events);
        setTotalPages(Math.ceil(data?.data?.count / 10));
      }
      setLoading(false);
    } catch (err) {
      addToasts({
        type: 'danger',
        body: 'unexpected error',
      });
    }
  };

  function handlePageChange(page) {
    setPage(page);
  }

  function handleInputChange(input) {
    setInput(input);
  }
  function handleTypeChange(type) {
    setType(type);
  }

  useEffect(() => {
    fetchData();
  }, [type, input, page]);

  return {
    loading,
    data,
    input,
    type,
    page,
    totalPages,
    handleSelectEvent,
    handlePageChange,
    handleInputChange,
    navigate,
    handleTypeChange,
  };
};

export default useEvents;
