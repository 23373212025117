import { useContext, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ToastContext from '../Context/ToastContext';
import { getMealsByStatus, searchMeals } from '../APIs/MealAPIs';
// /* eslint-disable */
const MealsHook = () => {
  // navigate
  const navigate = useNavigate();
  // manage toasts
  const { addToasts } = useContext(ToastContext);
  const [isFetching, setIsFetching] = useState(false);

  // search input
  const [input, setInput] = useState('');
  // navigation bar meals requests type used to make cliked tab active and filter meals data
  const [type, setType] = useState('Requests');

  // fetched data upon selected meals requests type
  const [data, setData] = useState([]);
  // fetched data upon search
  const [searchData, setSearchData] = useState([]);
  // general loading state with every request
  const [loading, setLoading] = useState(false);
  // take numberOfMeals from backend for pagination to verify end of list data
  const [maxCount, setMaxCount] = useState(0);

  // state to clear timeout used with debouncing search
  const [timeoutId, setTimeoutId] = useState(null);

  // search input on change handler
  const handleInputChange = e => {
    // reset search data to allow user to search again if he scrolled with pagination
    setSearchData([]);

    // set search input
    setInput(e.target.value);

    // clear timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    //  fetch all meals data if search input is empty
    if (e.target.value.length === 0) {
      // fetch all meals data
      fetchMealsData();
      return;
    }

    // debouncing search
    const newTimeoutId = setTimeout(() => {
      searchMealsData(e.target.value);
    }, 500);

    // set timeout id to clear timeout
    setTimeoutId(newTimeoutId);
  };

  // set meal requests type when selected from meals navigation bar
  const mealsNavigationSelectHandler = e => {
    // if same tab is clicked do nothing
    if (e.target.id === type) return;

    // reset data and search data when changing requests type to avoid duplicated data
    setData([]);
    setSearchData([]);

    // set meal requests type
    setType(e.target.id);
  };

  // navigate to meal page so we can access the meal id from url using useLocation()
  const handleSelectMeal = e => {
    navigate('/meals/meal', {
      state: { id: e.target.id },
    });
  };

  // fetch all meals data with filter
  const fetchMealsData = useCallback(async () => {
    // filter search query
    const searchQuery = {
      skip: data.length || 0,
      is_accepted: type === 'Acceptable requests',
      is_rejected: type === 'Rejected requests',
    };

    // start loading spinner only if data is empty to avoid flashing with pagination
    if (!data.length) setLoading(true);

    // reset search data to not interfere with meals data
    setSearchData([]);

    setIsFetching(true);
    // fetch data
    getMealsByStatus(searchQuery)
      .then(response => {
        if (response.status === 200) {
          // get meals and numberOfMeals
          const { meals, numberOfMeals } = response.data.data;
          // set max count to verify end of list with pagination
          setMaxCount(numberOfMeals);
          // set only data for first fetch
          if (!data.length) {
            setData(meals);
            return;
          }
          // set previous data with new data
          setData(prevState => [...prevState, ...meals]);
        }
      })
      .catch(err => {
        // show error toast
        addToasts({
          body: `${err.response.data.msg || 'something went wrong, please try again later'}`,
          type: 'danger',
        });
      })
      .finally(() => {
        setIsFetching(false);
        // stop loading spinner
        if (!data.length) setLoading(false);
      });
  }, [addToasts, data.length, type]);

  // fetch meals data upon search
  const searchMealsData = useCallback(
    async searchText => {
      // filter search query
      const searchQuery = {
        skip: searchData.length || 0,
        is_accepted: type === 'Acceptable requests',
        is_rejected: type === 'Rejected requests',
        text: searchText,
      };

      // start loading spinner only if search data is empty to avoid flashing with pagination
      if (!searchData.length) setLoading(true);

      // reset data to not interfere with search data
      setData([]);
      setIsFetching(true);
      // fetch data
      searchMeals(searchQuery)
        .then(response => {
          if (response.status === 200) {
            // get meals and numberOfMeals
            const { meals, numberOfMeals } = response.data.data;
            // set max count to verify end of list with pagination
            setMaxCount(numberOfMeals);
            // set only search data for first fetch
            if (!searchData.length) {
              setSearchData(meals);
              return;
            }
            // set previous search data with new data
            setSearchData(prevState => [...prevState, ...meals]);
          }
        })
        .catch(err => {
          // show error toast
          addToasts({
            body: `${err.response.data.msg || 'something went wrong, please try again later'}`,
            type: 'danger',
          });
        })
        .finally(() => {
          setIsFetching(false);
          // stop loading spinner
          if (!searchData.length) setLoading(false);
        });
    },
    [addToasts, searchData.length, type],
  );

  // fetch data on page load and on search
  useEffect(() => {
    // fetch data on page load
    if (!input) {
      setSearchData([]);
      fetchMealsData();
      return;
    }
    // fetch data on search
    setData([]);
    searchMealsData(input);
    // eslint-disable-next-line
  }, [type]);

  useEffect(() => {
    const isReachedEndOfList = (dataLength, searchDataLength, maxCount) => {
      return dataLength >= maxCount || searchDataLength >= maxCount;
    };
    const handleScroll = () => {
      const windowInnerHeight = window.innerHeight;
      const documentElementScrollTop = document.documentElement.scrollTop;
      const documentElementOffsetHeight = document.documentElement.offsetHeight;
      const scroll = windowInnerHeight + documentElementScrollTop;
      const offset = documentElementOffsetHeight - 200;
      const dataLength = data.length;
      const searchDataLength = searchData.length;

      if (scroll >= offset && !isReachedEndOfList(dataLength, searchDataLength, maxCount) && !isFetching) {
        window.removeEventListener('scroll', handleScroll);
        // if there is no search input, fetch meals data
        if (!input) {
          fetchMealsData();
          return;
        }
        // if there is search input, fetch search data
        searchMealsData(input);
      }
    };
      window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [data, searchData, maxCount, input, fetchMealsData, searchMealsData, isFetching]);

  return [
    input,
    type,
    loading,
    data,
    searchData,
    handleInputChange,
    mealsNavigationSelectHandler,
    navigate,
    handleSelectMeal,
  ];
};

export default MealsHook;
