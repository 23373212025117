import React from 'react';

const InfoRow = ({ label, value, isStrikethrough }) => (
  <div className="flex flex-row gap-4 items-center">
    <label className="text-[#6b7280] font-medium text-base">{label}</label>
    <span className={isStrikethrough ? 'line-through text-gray-400' : ''}>{value}</span>
  </div>
);

export default InfoRow;
