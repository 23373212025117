import api from './_common/helper';

export async function getVersionReq(data) {
  return api.get('/update/version', data);
}

export async function updateVersionReq(data) {
  return api.put('/update/version', data);
}

