import TopCard from '../Components/TopCard';
import Sidebar from '../Components/Sidebar';
import { getVCC, getVccStats } from '../APIs/investAPIs';
import dollar from '../Assets/u_dollar-sign-alt.png';
import { useState, useEffect, useContext } from 'react';
import ToastContext from '../Context/ToastContext';
import '../Styles/VccPrices.css';
import convertDateToDateString from '../Utils/convertDateToDateString.js';
import { AgChartsReact } from 'ag-charts-react';

export default function VccPrices() {
  const [loading, setLoading] = useState(true);
  const { addToasts } = useContext(ToastContext);
  const [VCCdata, setVCCData] = useState([]);
  const [maxCount, setMaxCount] = useState(0);
  const [VCCStatistics, setVCCStatistics] = useState([]);
  const [options, setOptions] = useState({
    title: {
      text: 'Vcc Prices Over Last 30 Days',
    },
    series: [
      {
        type: 'area',
        xKey: 'date',
        yKey: 'price',
        yName: 'Price',
      },
    ],
    data: [],
  });

  const fetchMoreData = async () => {
    try {
      setLoading(true);
      const vccdata = await getVCC(VCCdata.length);
      const statistics = await getVccStats('month');
      setVCCStatistics(statistics.data.data);
      setOptions(prevState => {
        return {
          ...prevState,
          data: statistics.data.data.vccs,
        };
      });
      const { numberOfVccs, vccs } = vccdata.data.data;
      setVCCData([...VCCdata, ...vccs]);
      setMaxCount(numberOfVccs);
      setLoading(false);
    } catch (err) {
      addToasts({
        type: 'danger',
        body: 'unexpected error',
      });
    }
  };

  useEffect(() => {
    fetchMoreData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
        if (loading || VCCdata.length >= maxCount) return;
        fetchMoreData();
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, VCCdata, maxCount]);

  return (
    <div className="vccPrices">
      <Sidebar></Sidebar>
      <div className="vccPricesContent">
        <TopCard title="VCC prices"></TopCard>
      </div>
      <main className="flex justify-center items-start gap-4 pt-2">
        <section className="vccPricesCards flex flex-col p-4 bg-white w-1/3 h-full">
          {VCCdata?.map(vcc => (
            <div className="flex justify-between items-center" key={vcc.id}>
              <div className="flex gap-x-1">
                <span className="text-xl font-semibold">{vcc.price}</span>
                <img src={dollar} width={25} height={25} alt="dollar" />
              </div>
              <span className="text-base text-gray-500">{convertDateToDateString(vcc.createdAt)}</span>
            </div>
          ))}
        </section>
        <section className="flex justify-center items-center">
          <AgChartsReact options={options} />
        </section>
      </main>
    </div>
  );
}
