import { useEffect, useReducer, useContext } from 'react';
import { getProductOrders, searchOrders } from '../APIs/ProductAPIs';
import ToastContext from '../Context/ToastContext';
const initialState = {
  data: [],
  searchData: [],
  maxCount: 0,
  loading: false,
  type: 'PENDING',
  input: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_DATA':
      return { ...state, data: action.payload };
    case 'SET_SEARCH_DATA':
      return { ...state, searchData: action.payload };
    case 'SET_MAX_COUNT':
      return { ...state, maxCount: action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_TYPE':
      return { ...state, type: action.payload };
    case 'SET_INPUT':
      return { ...state, input: action.payload };
    default:
      throw new Error();
  }
}

export default function useStoreOrder() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { addToasts } = useContext(ToastContext);

  const searchOrdersByType = async (input, skip, type) => {
    // (`/product/order/search?skip=${data.skip}&query=${data.text}&status=${data.status}`);
    return await searchOrders({ text: input, skip, status: type });
  };

  const handleSearch = async () => {
    if (state.input) {
      try {
        const product = await searchOrdersByType(state.input, 0, state.type);
        const { orders, totalOrders } = product.data.data;
        dispatch({ type: 'SET_MAX_COUNT', payload: totalOrders });
        dispatch({ type: 'SET_SEARCH_DATA', payload: [...orders] });
      } catch (err) {
        addToasts({
          type: 'danger',
          body: 'unexpected error',
        });
      }
    } else {
      dispatch({ type: 'SET_SEARCH_DATA', payload: [] });
      fetchMoreData();
    }
  };

  const handleMoreSearch = async () => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const product = await searchOrdersByType(state.input, state.searchData.length, state.type);
      const { orders, totalOrders } = product.data.data;
      dispatch({ type: 'SET_MAX_COUNT', payload: totalOrders });
      dispatch({ type: 'SET_SEARCH_DATA', payload: [...state.searchData, ...orders] });
      dispatch({ type: 'SET_LOADING', payload: false });
    } catch (err) {
      addToasts({
        type: 'danger',
        body: 'unexpected error',
      });
    }
  };

  const fetchMoreData = async () => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const searchQuery = {
        skip: state.data.length,
        status: state.type,
      };

      const productResponse = await getProductOrders(searchQuery);
      const { orders, totalOrders } = productResponse.data.data;
      dispatch({ type: 'SET_MAX_COUNT', payload: totalOrders });
      dispatch({ type: 'SET_DATA', payload: [...state.data, ...orders] });
      dispatch({ type: 'SET_LOADING', payload: false });
    } catch (err) {
      addToasts({
        type: 'danger',
        heading: 'Unexpected error',
        body: err.response.data.msg || 'Internal server error',
      });
    }
  };

  useEffect(() => {
    handleSearch();
  }, [state.input, state.type]);

  useEffect(() => {
    fetchMoreData();
  }, [state.type]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight ||
        state.loading ||
        state.data.length >= state.maxCount
      )
        return;
      !state.input ? fetchMoreData() : handleMoreSearch();
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [state.loading, state.data, state.maxCount]);

  const handleInputChange = e => {
    dispatch({ type: 'SET_INPUT', payload: e.target.value });
  };

  const setType = type => {
    dispatch({ type: 'SET_DATA', payload: [] });
    dispatch({ type: 'SET_SEARCH_DATA', payload: [] });
    dispatch({ type: 'SET_TYPE', payload: type });
  };

  return {
    data: state.data,
    searchData: state.searchData,
    maxCount: state.maxCount,
    loading: state.loading,
    type: state.type,
    input: state.input,
    dispatch,
    handleSearch,
    fetchMoreData,
    handleInputChange,
    setType,
  };
}
