import { useContext, useEffect, useState } from 'react';
import ToastContext from '../Context/ToastContext';
import { useNavigate } from 'react-router-dom';
import { getJobs, searchJobs } from '../APIs/JobAPIs';

const useJobs = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [input, setInput] = useState('');
  const { addToasts } = useContext(ToastContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setLoading(true);
      if (input !== '') {
        const { data } = await searchJobs(input, page);
        setData(data?.data?.jobs);
        setTotalPages(Math.ceil(data?.data?.count / 20));
      } else {
        const { data } = await getJobs(page);
        setData(data?.data?.jobs);
        setTotalPages(Math.ceil(data?.data?.count / 20));
      }
      setLoading(false);
    } catch (err) {
      addToasts({
        type: 'danger',
        body: 'unexpected error',
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, input]);

  const handleSelectJob = e => {
    navigate('/jobs/job', {
      state: { jobId: e.target.id },
    });
  };

  function handlePageChange(page) {
    setPage(page);
  }
  const handleInputChange = e => {
    setInput(e.target.value);
  };

  return {
    loading,
    input,
    data,
    page,
    totalPages,
    handleSelectJob,
    handlePageChange,
    handleInputChange,
    navigate,
  };
};

export default useJobs;
