import React from 'react';
import { FaDownload, FaPassport } from 'react-icons/fa6';

export default function JobRequest({ request }) {
  return (
    <div className="bg-gray-200 p-5 rounded-lg" key={request.id}>
      <div className="flex items-center my-2">
        <img crossOrigin="anonymous" className="rounded-full w-28 h-28" src={request.photo} alt="user photo" />
        <h1 className="mx-5 text-3xl font-semibold capitalize">{request.name}</h1>
      </div>
      <section className="grid grid-cols-2 gap-3">
        <div>
          <h4 className="text-gray-500 text-lg">Phone Number</h4>
          <span className="text-md">{request.phone_number}</span>
          <h4 className="text-gray-500 text-lg">City</h4>
          <span className="text-md">{request.city}</span>
        </div>
        <div>
          <a href={request.cv} target="_blank" download className="flex items-center ">
            <h4 className="text-gray-500 text-xl mr-2 ">CV</h4>
            <FaDownload />
          </a>
          <a href={request.passport} target="_blank" download className="mt-1 flex items-center">
            <h4 className="text-gray-500 text-xl capitalize mr-2">IDE</h4>
            <FaPassport />
          </a>
        </div>
        <div>
          <h4 className="text-gray-500 text-lg">Proposal</h4>
          <span className="text-md font-medium capitalize">{request.proposal}</span>
        </div>
      </section>
    </div>
  );
}
