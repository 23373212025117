import React from 'react';
import Sidebar from '../Sidebar';
import { useNavigate } from 'react-router-dom/dist';
import cursor from '../../Assets/arrowframe.png';
function Layout({ children, title, Action, back }) {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <>
      <Sidebar />
      <div className="w-full h-full flex flex-col pl-24 pt-5 pr-2">
        <header className="flex justify-between items-center bg-white p-4 w-full rounded-lg">
          {title && back !== false && (
            <div className="flex items-center justify-start gap-x-4 w-full">
              {back !== false && (
                <img onClick={handleBack} className="cursor-pointer md:w-12 md:h-12 w-8" src={cursor} alt="cursor" />
              )}
              {title && <h1 className="md:text-3xl font-bold text-xl">{title}</h1>}
            </div>
          )}
          {Action && Action}
        </header>
        {children}
      </div>
    </>
  );
}

export default Layout;
