import Sidebar from '../Components/Sidebar';
import '../Styles/Investing.css';
import vector from '../Assets/vector.png';
import dollar from '../Assets/u_dollar-sign-alt.png';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import ToastContext from '../Context/ToastContext';
import LoadingSpinner from '../Components/LoadingSpinner';
import * as investingAPI from '../APIs/investAPIs.js';
import Layout from '../Components/_common/Layout.jsx';
import InvestCard from '../Components/Investing/InvestCard.js';

export default function Investing() {
  const [lastVCCPrice, setLastVCCPrice] = useState(0);
  const [vccData, setVCCData] = useState([]);
  const [totalMoney, setTotalMoney] = useState(0);
  const [investsData, setInvestsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [remainingCount, setRemainingCount] = useState(0);
  const [soldCount, setSoldCount] = useState(0);
  const [investsFilter, setInvestsFilter] = useState('purchase');
  const [sellInvestsData, setSellInvestsData] = useState([]);
  const { addToasts } = useContext(ToastContext);
  const navigate = useNavigate();
  const currentDate = new Date();

  const fetchInitialData = async () => {
    try {
      setIsLoading(true);
      const [
        investsRes,
        lastVCCResponse,
        vccDataResponse,
        totalMoneyResponse,
        remainingCountResponse,
        soldCountResponse,
      ] = await Promise.all([
        investingAPI.getInvests({ skip: 0 }),
        investingAPI.getLastVCC(),
        investingAPI.getVCC(0),
        investingAPI.getAllMoney(),
        investingAPI.getRemainingCount(),
        investingAPI.getSoldCount(),
      ]);

      const { invests, sellInvests } = investsRes.data.data;
      setInvestsData(invests);
      setSellInvestsData(sellInvests);

      const lastVCCPrice = lastVCCResponse.data.data?.vcc?.price || 0;
      setLastVCCPrice(lastVCCPrice);

      const { vccs: vccData } = vccDataResponse.data.data;
      setVCCData(vccData);

      const { allMoney: totalMoney } = totalMoneyResponse.data.data;
      setTotalMoney(totalMoney);

      const { result: remainingCount } = remainingCountResponse.data.data;
      setRemainingCount(remainingCount);

      const { result: soldCount } = soldCountResponse.data.data;
      setSoldCount(soldCount);

      setIsLoading(false);
    } catch (err) {
      console.error(err);
      addToasts({
        type: 'danger',
        body: 'Unexpected error',
      });
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  return (
    <Layout
      title={
        <>
          Investing <span className="text-xl font-normal text-gray-500">{currentDate.toDateString()}</span>
        </>
      }
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="investing">
          <main className="investcards">
            <div className="investingcard1 p-6">
              <div className="flex justify-between items-center">
                <h2 className="text-2xl font-bold">
                  VCC price {<span className=" text-blue-600">{lastVCCPrice}</span>}
                </h2>
                <button
                  className="bg-blue-500 text-white text-xl px-4 py-2 rounded-md hover:bg-blue-600"
                  onClick={() => navigate('/investing/changevccprice')}
                  style={{ cursor: 'pointer' }}
                >
                  Change
                </button>
              </div>
              <div className="investingcard1det flex flex-wrap my-2 gap-x-5">
                <div className="flex flex-row items-center gap-x-2">
                  <h3 className="investingcard1det2">Shares with user now</h3>
                  <div className="investingcard1det3">
                    <div>
                      <img src={vector} width={20} height={20} alt="sold" />
                      <span>{remainingCount}</span>
                    </div>
                    <div className="dollarandvalue">
                      <img src={dollar} width={25} height={25} alt="dollar" />
                      <span>{remainingCount * lastVCCPrice}</span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row items-center gap-x-2">
                  <h3>Shares sold from app beginning</h3>
                  <div className="investingcard1det3">
                    <div>
                      <img src={vector} width={20} height={20} alt="sold" />
                      <span>{soldCount}</span>
                    </div>
                    <div className="dollarandvalue">
                      <img src={dollar} width={25} height={25} alt="dollar" />
                      <span>{soldCount * lastVCCPrice}</span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row items-center gap-x-2">
                  <h3>All money in the app</h3>
                  <div className="investingcard1det3">
                    <img src={dollar} width={25} height={25} alt="dollar" />
                    <span>{totalMoney}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="investingcard3">
              <div className="investcard3det1">
                <div className="priceofvcc">Price of vcc</div>
                {vccData?.map(vcc => (
                  <div key={vcc.id}>{new Date(vcc.createdAt).toDateString()}</div>
                ))}
              </div>
              <div className="investcard3det2">
                <div className="Seeall" onClick={() => navigate('/investing/vccprices')} style={{ cursor: 'pointer' }}>
                  See all
                </div>
                {vccData?.map(vcc => (
                  <div key={vcc.id}>{vcc.price}</div>
                ))}
              </div>
            </div>
            <section className="investingcard2">
              <div className="investingcard2det1">
                <span
                  style={{ cursor: 'pointer' }}
                  className={`${investsFilter === 'purchase' ? 'selected' : ''}`}
                  onClick={() => setInvestsFilter('purchase')}
                >
                  Purchasing operations
                </span>
                <span
                  style={{ cursor: 'pointer' }}
                  className={`${investsFilter === 'sale' ? 'selected' : ''}`}
                  onClick={() => setInvestsFilter('sale')}
                >
                  Sales operations
                </span>
              </div>
              <div className="bg-white p-4 rounded-lg shadow-md">
                {investsFilter === 'purchase' ? (
                  <>
                    {investsData?.map(invest => (
                      <InvestCard key={invest.id} invest={invest} lastVCCPrice={lastVCCPrice} type="purchase" />
                    ))}
                  </>
                ) : (
                  <>
                    {sellInvestsData?.map(invest => (
                      <InvestCard key={invest.id} invest={invest} lastVCCPrice={lastVCCPrice} type="sell" />
                    ))}
                  </>
                )}
              </div>
            </section>
          </main>
        </div>
      )}
    </Layout>
  );
}
