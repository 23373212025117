import { useState, useEffect, useContext } from 'react';
import {
  getUser,
  getUserPosts,
  getWallet,
  getUserMeals,
  getUserOrders,
  getUserCourses,
  getUserLoanRequests,
  banUser,
  unbanUser,
} from '../APIs/UserAPIs';
import ToastContext from '../Context/ToastContext';

export const useProfile = userId => {
  const [data, setData] = useState({});
  const [wallet, setWallet] = useState({});
  const [ordersCount, setOrdersCount] = useState(0);
  const [loanCount, setLoanCount] = useState(0);
  const [mealCount, setMealCount] = useState(0);
  const [coursesCount, setCoursesCount] = useState(0);
  const [posts, setPosts] = useState([]);
  const [postsMaxCount, setPostsMaxCount] = useState();
  const { addToasts } = useContext(ToastContext);
  const [loading, setLoading] = useState(true);

  const fetchMoreData = async () => {
    try {
      const postRes = await getUserPosts({ id: userId, skip: 0 });
      const { posts, numberOfPosts } = postRes.data;
      setPosts(posts);
      setPostsMaxCount(numberOfPosts);
    } catch (err) {
      addToasts({
        type: 'danger',
        heading: err.response.data.msg || 'Something went wrong',
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const user = await getUser(userId);
        setData(user.data.data);
        const [walletRes, loansRes, coursesRes, mealsRes, ordersRes] = await Promise.all([
          getWallet(userId),
          getUserLoanRequests({ skip: 0, id: userId }),
          getUserCourses({ skip: 0, id: userId }),
          getUserMeals({ skip: 0, id: userId }),
          getUserOrders({ skip: 0, id: userId }),
        ]);
        setCoursesCount(coursesRes.data.data.count);
        setLoanCount(loansRes.data.data.numberOfLoanRequests);
        setWallet(walletRes.data.data.wallet);
        setMealCount(mealsRes.data.data.numberOfMeals);
        setOrdersCount(ordersRes.data.data.count);

        fetchMoreData();
        setLoading(false);
      } catch (err) {
        console.error(err);
        addToasts({
          type: 'danger',
          heading: err.response.data.msg || 'Something went wrong',
        });
      }
    })();
  }, [userId]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
        if (loading || data.length >= postsMaxCount) return;
        fetchMoreData();
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, posts, postsMaxCount]);

  const handleBanUser = async () => {
    try {
      setLoading(true);
      await banUser(userId);
      addToasts({
        type: 'success',
        heading: 'User has been banned',
      });
      const user = await getUser(userId);
      setData(user.data.data);
      setLoading(false);
    } catch (error) {
      addToasts({
        type: 'danger',
        heading: error.response.data.msg || 'Something went wrong',
      });
    }
  };

  const handleUnBanUser = async () => {
    try {
      setLoading(true);
      await unbanUser(userId);
      addToasts({
        type: 'success',
        heading: 'User has been unbanned',
      });
      const user = await getUser(userId);
      setData(user.data.data);
      setLoading(false);
    } catch (error) {
      addToasts({
        type: 'danger',
        heading: error.response.data.msg || 'Something went wrong',
      });
    }
  };
  return {
    data,
    wallet,
    ordersCount,
    loanCount,
    mealCount,
    coursesCount,
    posts,
    postsMaxCount,
    loading,
    fetchMoreData,
    handleBanUser,
    handleUnBanUser,
  };
};
