import './ButtonLoadingSpinner.css';

const ButtonLoadingSpinner = ({ loading, topPosition, leftPosition }) => {
  return (
    <div
      className={`button-spinner ${!loading && 'invisible'} `}
      style={{ top: `${topPosition ? topPosition : '0'}`, left: `${leftPosition ? leftPosition : '0'}` }}
    ></div>
  );
};

export default ButtonLoadingSpinner;
