import '../Styles/VerificationRequests.css';
import { useNavigate } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import ToastContext from '../Context/ToastContext';
import LoadingSpinner from '../Components/LoadingSpinner';
import VerificationRequestCard from '../Components/VerificationRequest/VerificationRequestCard.jsx';
import useVerificationRequests from '../Hooks/useVerificationRequests.js';
import Layout from '../Components/_common/Layout.jsx';
export default function VerificationRequests() {
  const { data, loading, error, fetchMoreData } = useVerificationRequests();
  const { addToasts } = useContext(ToastContext);
  const navigate = useNavigate();

  const handleProfileClick = e => {
    navigate('/profiles/verificationrequests/verificationrequest', {
      state: { id: e.target.id },
    });
  };
  useEffect(() => {
    if (error) {
      addToasts({ heading: 'Failed to fetch data', type: 'danger' });
    }
  }, [error]);

  const renderVerificationRequests = () => {
    if (loading) {
      return <LoadingSpinner />;
    }

    if (!data || data.length === 0) {
      return <h2 className="text-2xl py-10 w-full text-center">No Verification Requests Found</h2>;
    }

    return data?.map(vRequest => (
      <VerificationRequestCard key={vRequest.id} data={vRequest} handleProfileClick={handleProfileClick} />
    ));
  };

  return (
    <Layout title="Verification Requests">
      <main>
        <section className="flex flex-row flex-wrap items-center justify-start w-full h-full gap-4">
          {renderVerificationRequests()}
        </section>
      </main>
    </Layout>
  );
}
