import React, { useState, useEffect } from 'react';
import FullNameConcat from '../../Utils/FullNameConcat';

const ChatCard = ({ chat }) => {
  const [waitingTime, setWaitingTime] = useState('');

  useEffect(() => {
    const updateWaitingTime = () => {
      const currentTime = new Date();
      const chatTime = new Date(chat.updatedAt);
      const diffInMinutes = Math.floor((currentTime - chatTime) / 60000);
      if (diffInMinutes < 1) setWaitingTime('Just now');
      else if (diffInMinutes <= 60) setWaitingTime(`${diffInMinutes} min`);
      else if (diffInMinutes <= 1440) setWaitingTime(`${Math.floor(diffInMinutes / 60)} hr`);
      else setWaitingTime(`${Math.floor(diffInMinutes / 1440)} days`);
    };
    const interval = setInterval(() => {
      updateWaitingTime();
    }, 60000); // update every minute
    updateWaitingTime(); // initial update
    return () => clearInterval(interval); // cleanup on unmount
  });
  const user = chat.users.filter(user => user.user_id === chat.userId)[0];
  return (
    <article className="flex items-center justify-between bg-white p-4 rounded-lg shadow-md">
      <div className="flex items-center gap-4">
        <img src={user?.avatar} alt="profile" className="w-12 h-12 rounded-full" />
        <h3 className="font-bold text-xl">{FullNameConcat(user)}</h3>
      </div>
      <span className="bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded border-blue-400">
        <svg
          className="w-2.5 h-2.5 me-1.5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm3.982 13.982a1 1 0 0 1-1.414 0l-3.274-3.274A1.012 1.012 0 0 1 9 10V6a1 1 0 0 1 2 0v3.586l2.982 2.982a1 1 0 0 1 0 1.414Z" />
        </svg>
        {waitingTime}
      </span>
    </article>
  );
};

export default ChatCard;
