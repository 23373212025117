import Sidebar from '../Components/Sidebar';
import '../Components/AddPage.css';
import { useLocation, useNavigate } from 'react-router-dom';
import cursor from '../Assets/arrowframe.png';
import '../Styles/EditCourse.css';
import CourseForm from '../Components/CourseForm';
import CourseContentCard from '../Components/CourseContentCard';
import useAddCourse from '../Hooks/useAddCourse';
import blankDefaultImage from '../Assets/blankDefaultImage.png';
import Layout from '../Components/_common/Layout';
export default function AddCourse() {
  const {
    state: { sections: weekContents, course },
  } = useLocation();
  const {
    formInputs,
    inputsOnChangeHandler,
    paymentTypeOnChangeHandler,
    professorOnChangeHandler,
    categoryOnChangeHandler,
    languageOnChangeHandler,
    levelOnChangeHandler,
    roleOnChangeHandler,
    fileOnChangeHandler,
    filePreview,
    sections,
    addSectionsHandler,
    editSectionTitle,
    addToSectionHandler,
    editSectionContent,
    deleteSectionHandler,
    deleteSectionContent,
    onClick,
    navigate,
    signatureOnChangeHandler,
    signaturePreview,
  } = useAddCourse({ course, weekContents });
  return (
    <Layout title="Add Course" back>
      <div className="py-6">
        <div className="grid lg:grid-cols-3 mr-2">
          <CourseForm
            formInputs={formInputs}
            inputsOnChangeHandler={inputsOnChangeHandler}
            paymentTypeOnChangeHandler={paymentTypeOnChangeHandler}
            professorOnChangeHandler={professorOnChangeHandler}
            categoryOnChangeHandler={categoryOnChangeHandler}
            languageOnChangeHandler={languageOnChangeHandler}
            levelOnChangeHandler={levelOnChangeHandler}
            roleOnChangeHandler={roleOnChangeHandler}
            fileOnChangeHandler={fileOnChangeHandler}
            filePreview={filePreview}
          />
          <div className="overflow-hidden ml-2">
            {/* upload professor signture photo */}
            <div className="flex justify-center items-center w-full">
              <div className="flex flex-col items-center w-full bg-white rounded-2xl p-3">
                <label htmlFor="professor-signature" className="cursor-pointer">
                  <p className="text-lg">Upload Professor Signature</p>
                  <img
                    src={
                      formInputs.professorSignature
                        ? formInputs.professorSignature
                        : signaturePreview || blankDefaultImage
                    }
                    alt="professor signature"
                    className="w-full h-auto object-cover"
                  />
                </label>
                <input type="file" id="professor-signature" className="hidden" onChange={signatureOnChangeHandler} />
              </div>
            </div>
            <CourseContentCard
              courseContentsData={sections}
              addSectionsHandler={addSectionsHandler}
              editSectionTitle={editSectionTitle}
              addToSectionHandler={addToSectionHandler}
              editSectionContent={editSectionContent}
              deleteSectionHandler={deleteSectionHandler}
              deleteSectionContent={deleteSectionContent}
            />
            <div className="flex justify-center items-center">
              <button onClick={onClick} className="addButton my-5 max-lg:ms-[90px]">
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
