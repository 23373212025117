import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getRequest, updateRequests } from '../APIs/DashboardWalletAPIs';
import ToastContext from '../Context/ToastContext';

const useWithdrawRequest = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { addToasts } = useContext(ToastContext);
  const [data, setData] = useState();
  const [comment, setComment] = useState();
  const navigator = useNavigate();

  async function fetchRequest() {
    try {
      setLoading(true);
      const { data } = await getRequest(id);
      setData(data.data.withdraw_request);
      if (data.data.withdraw_request.comment) setComment(data.data.withdraw_request.comment);
      setLoading(false);
    } catch (err) {
      console.error(err);
      addToasts({
        type: 'danger',
        body: 'Internal server error',
      });
    }
  }
  useEffect(() => {
    fetchRequest();
  }, [id]);

  async function updateRequest(status) {
    try {
      setLoading(true);
      const data = {
        status,
        comment,
      };
      await updateRequests(id, data);
      addToasts({
        body: 'Request updated successfully',
        type: 'success',
      });
      navigator(`/dashboardwallet`);
      setLoading(false);
    } catch (err) {
      console.error(err);
      addToasts({
        type: 'danger',
        body: 'Internal server error',
      });
    }
  }

  function handleComment(e) {
    setComment(e.target.value);
  }

  return {
    loading,
    data,
    comment,
    handleComment,
    updateRequest,
  };
};

export default useWithdrawRequest;
