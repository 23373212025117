import React from 'react';

export const UniversityCard = ({ university, handleSelectUniversity }) => (
  <article
    id={university.id}
    onClick={handleSelectUniversity}
    className="flex items-start justify-start p-6 bg-white rounded-lg shadow-lg cursor-pointer hover:shadow-xl transition-shadow duration-300 max-w-2xl w-full mb-4"
  >
    <img
      id={university.id}
      src={university.photo}
      alt="University"
      crossOrigin="anonymous"
      className="w-24 h-24 mr-4 rounded-lg object-cover"
    />
    <div id={university.id} className="flex flex-col justify-start w-full mb-2">
      <h1 id={university.id} className="text-xl font-bold mb-2">
        {university.name}
      </h1>
      <div id={university.id} className="flex items-center justify-between flex-wrap gap-3">
        <p id={university.id} className="text-sm text-gray-600 mb-1 w-24 h-6">
          City: {university.city}
        </p>
        <p id={university.id} className="text-sm text-gray-600 mb-1 w-24 h-6">
          Colleges: {university.colleges}
        </p>
        <p id={university.id} className="text-sm text-gray-600 mb-1 w-24 h-6">
          Founders: {university.founder}
        </p>
        <p id={university.id} className="text-sm text-gray-600 mb-1 w-24 h-6">
          Genre: {university.genre}
        </p>
        <p id={university.id} className="text-sm text-gray-600 mb-1 w-24 h-6">
          Professors: {university.professors}
        </p>
        <p id={university.id} className="text-sm text-gray-600 mb-1 w-24 h-6">
          Students Count: {university.studentsCount}
        </p>
        <a
          href={university.location}
          id={university.id}
          className="text-sm text-blue-500 hover:underline mb-1 w-24 h-6"
        >
          Location
        </a>
        <p id={university.id} className="text-sm text-gray-600 mb-1 w-24 h-6">
          Graduates: {university.graduates}
        </p>
        <a href={university.link} className="text-sm text-blue-500 hover:underline mt-2 inline-block">
          Visit Website
        </a>
      </div>
    </div>
  </article>
);
