
import { useEffect } from "react";
const useScrollUniversity = (fetchMoreData, loading, dataLength, maxCount, input) => {
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
          document.documentElement.offsetHeight &&
        !loading &&
        dataLength < maxCount
      ) {
        if (!input) {
          fetchMoreData();
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [fetchMoreData, loading, dataLength, maxCount, input]);
};

export default useScrollUniversity;
