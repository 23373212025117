import {  useState } from "react";
import Sidebar from "../Components/Sidebar";
import "../Styles/AddSection.css";
import { useNavigate, useLocation } from "react-router-dom";
import cursor from "../Assets/arrowframe.png";

export default function AddSection() {
  const {
    state: { sections, course, sectionTitle, sectionIndex }
  } = useLocation();

  const navigate = useNavigate();
  
  const [title, setTitle] = useState(sectionTitle ? sectionTitle : "");

  return (
    <div className="addSection">
      <Sidebar></Sidebar>
      <div className="addSectionContent">
        <div className="firsts">
          <div className="topCard">
            <div>
              <img
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/courses/addcourse", {
                    state: {
                      sections: sections,
                      course
                    }
                  });
                }}
                className="cursorIcon"
                src={cursor}
                height={45}
                width={45}
                alt="cursor icon"
              ></img>
              <div>Add Course Section</div>
            </div>
            <div></div>
          </div>
        </div>
        <div className="titleOfSection">
          <label htmlFor="titleOfSectionInput">Title of section</label>
          <input
            id="titleOfSectionInput"
            placeholder="Title of section"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          ></input>
          <button
            onClick={(e) => {
              e.preventDefault();
              if (sectionTitle) {
                sections[sectionIndex].title = title
                navigate("/courses/addcourse", {
                  state: {
                    sections: sections,
                      course
                  }
                });
                return
              }
              navigate("/courses/addcourse", {
                state: {
                  sections: [
                    ...sections,
                    {
                      title,
                      content: []
                    }
                  ],
                  course
                }
              });
            }}
            disabled={!title.trim()}
            className="addButton"
          >
            {sectionTitle ? "Save" : "Add"}
          </button>
        </div>
      </div>
    </div>
  );
}
