import { Navigate, Route, Routes } from 'react-router-dom';
import { useState, useEffect } from 'react';
import AddEditLessonVideo from './Pages/AddEditLessonVideo.js';
import AddEditSection from './Pages/AddEditSection.js';
import { getSession } from './Utils/SessionUtils';
import { UserInfoReq } from './APIs/AuthAPIs';
import ToastContext from './Context/ToastContext';
import UserContext from './Context/UserContext.js';
import './App.css';
import Signin from './Pages/Signin';
import Home from './Pages/Home';
import SupportChat from './Pages/Supportchat.js';
import Support from './Pages/Support';
import DashboardWallet from './Pages/Dashboardwallet.js';
import Loans from './Pages/Loans';
import Investing from './Pages/Investing';
import Courses from './Pages/Courses';
import Store from './Pages/Store';
import Events from './Pages/Events';
import Meals from './Pages/Meals';
import Jobs from './Pages/Jobs';
import Universities from './Pages/Unviersities';
import Profiles from './Pages/Profiles';
import Course from './Pages/Course';
import AddSection from './Pages/AddSection';
import AddLessonVideo from './Pages/AddLessonVideo';
import AddCourse from './Pages/AddCourse';
import VerificationRequest from './Pages/VerificationRequest';
import VerificationRequests from './Pages/VerificationRequests';
import LoansRequests from './Pages/LoansRequests';
import LoansRequest from './Pages/LoansRequest';
import Commissons from './Pages/Commissons';
import AddLoan from './Pages/AddLoan';
import EditLoan from './Pages/EditLoan';
import ChangeVccPrice from './Pages/ChangeVccPrice';
import VccPrices from './Pages/VccPrices';
import Viewers from './Pages/Viewers';
import Students from './Pages/Students';
import Profile from './Pages/Profile';
import Trusts from './Pages/Trusts';
import EditCourse from './Pages/EditCourse';
import AddProduct from './Pages/AddProduct';
import EditProduct from './Pages/EditProduct';
import OrderDetails from './Pages/OrderDetails';
import Product from './Pages/Product';
import Meal from './Pages/Meal';
import Event from './Pages/Event';
import EditEvent from './Pages/EditEvent';
import EditPost from './Pages/EditPost';
import ProfilePost from './Pages/ProfilePost';
import University from './Pages/University';
import AddUniversity from './Pages/AddUniversity';
import ToastList from './Components/ToastList';
import Job from './Pages/Job';
import AddJob from './Pages/AddJob';
import AddEvent from './Pages/AddEvent';
import LoadingSpinner from './Components/LoadingSpinner';
import Order from './Pages/Order';
import Wallet from './Pages/Wallet';
import Orders from './Pages/Orders';
import EditUniversity from './Pages/EditUniversity';
import EditJob from './Pages/EditJob';
import Visitors from './Pages/Visitors';
import OrdersToDo from './Pages/OrdersToDo';
import Buyers from './Pages/Buyers';
import Chef from './Pages/Chef.js';
import ChefOrders from './Pages/ChefOrders.js';
import NotificationSender from './Pages/NotificationSender.js';
import Broadcast from './Pages/Broadcast.js';
import Report from './Pages/Report.js';
import UpdateVersion from './Pages/UpdateVersion.js';
import CreateAccount from './Pages/CreateAccount.js';
import Hub from './Pages/Hub';
import WithdrawRequest from './Pages/WithdrawRequest.js';
import Reels from './Pages/Reels.js';
import { SocketProvider } from './Context/SupportChatContext.js';
import RoommatePosts from './Pages/RoommatePosts.js';
import RoommatePost from './Pages/RoommatePost.js';

function App() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [toasts, setToasts] = useState([]);
  const redirectLink = '/signin';
  const isAuth = userData !== null;
  const addToasts = data => {
    setToasts(prev => [...prev, { id: (prev.at(-1) || 0) + 1, ...data }]);
  };

  useEffect(() => {
    (async () => {
      try {
        if (getSession()) {
          const res = await UserInfoReq();

          if (res.status !== 200) {
            addToasts({
              type: 'danger',
              body: jsonData.msg || 'Internal Server Error',
            });
            return;
          }

          const jsonData = res.data.data;
          if (jsonData.success === false) {
            addToasts({
              type: 'danger',
              body: 'Your session has expired, please login again',
            });
            return;
          }
          setUserData(jsonData.user);
        }
      } catch (err) {
        addToasts({
          type: 'danger',
          body: err.response.data?.msg || 'Internal Server Error',
        });
      }

      setLoading(false);
    })();
  }, []);

  return (
    <SocketProvider>
      <ToastContext.Provider value={{ toasts, setToasts, addToasts }}>
        <UserContext.Provider
          value={{
            userData,
            setUserData,
          }}
        >
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div className="App">
              <Routes>
                <Route path="/signin" element={<Signin />} />
                <Route path="/" element={isAuth ? <Home /> : <Navigate to={redirectLink} />} />

                <Route path="/support" element={isAuth ? <Support /> : <Navigate to={redirectLink} />} />
                <Route path="/support/chat/:id" element={isAuth ? <SupportChat /> : <Navigate to={redirectLink} />} />
                <Route
                  path="/dashboardwallet"
                  element={isAuth ? <DashboardWallet /> : <Navigate to={redirectLink} />}
                />
                <Route
                  path="/dashboardwallet/:id"
                  element={isAuth ? <WithdrawRequest /> : <Navigate to={redirectLink} />}
                />
                <Route
                  path="/dashboardwallet/commissons"
                  element={isAuth ? <Commissons /> : <Navigate to={redirectLink} />}
                />
                <Route path="/loans" element={isAuth ? <Loans /> : <Navigate to={redirectLink} />} />
                <Route
                  path="/loans/loansrequests/loansrequest"
                  element={isAuth ? <LoansRequest /> : <Navigate to={redirectLink} />}
                />
                <Route
                  path="/loans/loansrequests"
                  element={isAuth ? <LoansRequests /> : <Navigate to={redirectLink} />}
                />
                <Route path="/loans/addloan" element={isAuth ? <AddLoan /> : <Navigate to={redirectLink} />} />
                <Route path="/loans/editloan" element={isAuth ? <EditLoan /> : <Navigate to={redirectLink} />} />
                <Route path="/investing" element={isAuth ? <Investing /> : <Navigate to={redirectLink} />} />
                <Route path="/investing/vccprices" element={isAuth ? <VccPrices /> : <Navigate to={redirectLink} />} />
                <Route
                  path="/investing/changevccprice"
                  element={isAuth ? <ChangeVccPrice /> : <Navigate to={redirectLink} />}
                />
                <Route path="/courses" element={isAuth ? <Courses /> : <Navigate to={redirectLink} />} />
                <Route path="/courses/viewers" element={isAuth ? <Viewers /> : <Navigate to={redirectLink} />} />
                <Route path="/courses/buyers" element={isAuth ? <Buyers /> : <Navigate to={redirectLink} />} />
                <Route path="/courses/course" element={isAuth ? <Course /> : <Navigate to={redirectLink} />} />
                <Route path="/courses/addcourse" element={isAuth ? <AddCourse /> : <Navigate to={redirectLink} />} />
                <Route
                  path="/courses/course/editcourse"
                  element={isAuth ? <EditCourse /> : <Navigate to={redirectLink} />}
                />
                <Route path="/store/product/viewers" element={isAuth ? <Viewers /> : <Navigate to={redirectLink} />} />
                <Route path="/store/product/buyers" element={isAuth ? <Buyers /> : <Navigate to={redirectLink} />} />
                <Route path="/courses/course/viewers" element={isAuth ? <Viewers /> : <Navigate to={redirectLink} />} />
                <Route
                  path="/courses/course/students"
                  element={isAuth ? <Students /> : <Navigate to={redirectLink} />}
                />
                <Route path="/store" element={isAuth ? <Store /> : <Navigate to={redirectLink} />} />
                <Route path="/store/product" element={isAuth ? <Product /> : <Navigate to={redirectLink} />} />
                <Route path="/store/addproduct" element={isAuth ? <AddProduct /> : <Navigate to={redirectLink} />} />
                <Route path="/store/editproduct" element={isAuth ? <EditProduct /> : <Navigate to={redirectLink} />} />
                <Route
                  path="/store/orderdetails"
                  element={isAuth ? <OrderDetails /> : <Navigate to={redirectLink} />}
                />
                <Route path="/store/orders" element={isAuth ? <Orders /> : <Navigate to={redirectLink} />} />
                <Route path="/events" element={isAuth ? <Events /> : <Navigate to={redirectLink} />} />
                <Route path="/events/addevent" element={isAuth ? <AddEvent /> : <Navigate to={redirectLink} />} />
                <Route path="/events/event" element={isAuth ? <Event /> : <Navigate to={redirectLink} />} />
                <Route
                  path="/events/event/editevent"
                  element={isAuth ? <EditEvent /> : <Navigate to={redirectLink} />}
                />
                <Route path="/meals" element={isAuth ? <Meals /> : <Navigate to={redirectLink} />} />
                <Route path="/meals/orderstodo" element={isAuth ? <OrdersToDo /> : <Navigate to={redirectLink} />} />
                <Route path="/meals/chef/orders" element={isAuth ? <ChefOrders /> : <Navigate to={redirectLink} />} />
                <Route path="/meals/chef" element={isAuth ? <Chef /> : <Navigate to={redirectLink} />} />
                <Route path="/meals/meal" element={isAuth ? <Meal /> : <Navigate to={redirectLink} />} />

                <Route path="/roommate" element={isAuth ? <RoommatePosts /> : <Navigate to={redirectLink} />} />
                <Route path="/roommate/post" element={isAuth ? <RoommatePost /> : <Navigate to={redirectLink} />} />

                <Route path="/profile/thepost" element={isAuth ? <ProfilePost /> : <Navigate to={redirectLink} />} />
                <Route path="/universities" element={isAuth ? <Universities /> : <Navigate to={redirectLink} />} />
                <Route
                  path="/universities/adduniversity"
                  element={isAuth ? <AddUniversity /> : <Navigate to={redirectLink} />}
                />
                <Route
                  path="/universities/university"
                  element={isAuth ? <University /> : <Navigate to={redirectLink} />}
                />
                <Route
                  path="/universities/university/edituniversity"
                  element={isAuth ? <EditUniversity /> : <Navigate to={redirectLink} />}
                />
                <Route path="/jobs" element={isAuth ? <Jobs /> : <Navigate to={redirectLink} />} />
                <Route path="/jobs/addjob" element={isAuth ? <AddJob /> : <Navigate to={redirectLink} />} />
                <Route path="/jobs/job" element={isAuth ? <Job /> : <Navigate to={redirectLink} />} />
                <Route path="/jobs/job/editjob" element={isAuth ? <EditJob /> : <Navigate to={redirectLink} />} />
                <Route path="/profiles" element={isAuth ? <Profiles /> : <Navigate to={redirectLink} />} />
                <Route
                  path="/profiles/verificationrequests"
                  element={isAuth ? <VerificationRequests /> : <Navigate to={redirectLink} />}
                />
                <Route
                  path="/profiles/verificationrequests/verificationrequest"
                  element={isAuth ? <VerificationRequest /> : <Navigate to={redirectLink} />}
                />
                <Route path="/profiles/profile" element={isAuth ? <Profile /> : <Navigate to={redirectLink} />} />
                <Route path="/profiles/profile/trusts" element={isAuth ? <Trusts /> : <Navigate to={redirectLink} />} />
                <Route
                  path="/profiles/profile/visitors"
                  element={isAuth ? <Visitors /> : <Navigate to={redirectLink} />}
                />
                <Route path="/profiles/editpost" element={isAuth ? <EditPost /> : <Navigate to={redirectLink} />} />
                <Route path="/profiles/profile/wallet" element={isAuth ? <Wallet /> : <Navigate to={redirectLink} />} />
                <Route
                  path="/verificationrequest"
                  element={isAuth ? <VerificationRequest /> : <Navigate to={redirectLink} />}
                />
                <Route
                  path="/courses/addlesson"
                  element={isAuth ? <AddLessonVideo /> : <Navigate to={redirectLink} />}
                />
                <Route
                  path="/courses/addeditsection"
                  element={isAuth ? <AddEditSection /> : <Navigate to={redirectLink} />}
                />
                <Route
                  path="/courses/addeditlesson"
                  element={isAuth ? <AddEditLessonVideo /> : <Navigate to={redirectLink} />}
                />
                <Route path="/courses/addsection" element={isAuth ? <AddSection /> : <Navigate to={redirectLink} />} />
                <Route path="/meals/order" element={isAuth ? <Order /> : <Navigate to={redirectLink} />} />
                <Route
                  path="/notification"
                  element={isAuth ? <NotificationSender /> : <Navigate to={redirectLink} />}
                />
                <Route path="/broadcast" element={isAuth ? <Broadcast /> : <Navigate to={redirectLink} />} />
                <Route path="/report" element={isAuth ? <Report /> : <Navigate to={redirectLink} />} />
                <Route path="/create_account" element={isAuth ? <CreateAccount /> : <Navigate to={redirectLink} />} />
                <Route path="/update-version" element={isAuth ? <UpdateVersion /> : <Navigate to={redirectLink} />} />
                <Route path="/hub" element={isAuth ? <Hub /> : <Navigate to={redirectLink} />} />
                <Route path="/reels" element={isAuth ? <Reels /> : <Navigate to={redirectLink} />} />
              </Routes>
              <ToastList />
            </div>
          )}
        </UserContext.Provider>
      </ToastContext.Provider>
    </SocketProvider>
  );
}

export default App;
