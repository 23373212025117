import api from './_common/helper';

export async function getTotalPayments(date) {
  return api.get(`/totalPayments?date=${date}`);
}

export async function getTotalTransactions(date) {
  return api.get(`/totalTransactions?date=${date}`);
}

export async function getTotalWithdraw(date) {
  return api.get(`/totalWithdraw?date=${date}`);
}

export async function getRequests(status, date) {
  return api.get(`/withdraw_request/all?status=${status.toUpperCase()}&date=${date}`);
}

export async function getRequest(id) {
  return api.get(`/withdraw_request/${id}`);
}

export async function updateRequests(id, data) {
  return api.patch(`/withdraw_request/status/${id}`, data);
}
