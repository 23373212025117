import Sidebar from "../Components/Sidebar";
import TopCard from "../Components/TopCard";
import "../Styles/Commissons.css";

export default function Commissons() {
  return (
    <div className="Commissons">
      <Sidebar></Sidebar>
      <div className="commissonsContent">
        <TopCard title="Commissons"></TopCard>
        <div className="commissonsCards">
          <div className="mealFromChef">
            <label htmlFor="mealFromChef">Meal from chef address (%)</label>
            <input id="mealFromChef"></input>
            <button>Update</button>
          </div>
          <div className="mealFromChef">
            <label htmlFor="mealFromChef">Meal by delivery (%)</label>
            <input id="mealFromChef"></input>
            <button>Update</button>
          </div>
          <div className="mealFromChef">
            <label htmlFor="mealFromChef">
              Transfer money from account to another (%)
            </label>
            <input id="mealFromChef"></input>
            <button>Update</button>
          </div>
        </div>
      </div>
    </div>
  );
}
