import React from 'react';
import useTimeCollapsed from '../../../Hooks/Support/useTimeCollapsed';
import TimeSeparator from './TimeSeparator';
import SentMessage from './SentMessage';
import ReceivedMessage from './ReceivedMessage';
const MessageCard = ({ message, previousMessage, user, support }) => {
  const timeCollapsed = useTimeCollapsed(message);
  return (
    <React.Fragment>
      <TimeSeparator previousMessage={previousMessage} message={message} />
      {message.sender === user.user_id ? (
        <SentMessage user={user} timeCollapsed={timeCollapsed}>
          {message.isImage ? (
            <img src={message.message} crossOrigin="anonymous" alt="Sent" className="w-40 h-40" />
          ) : (
            message.message
          )}
        </SentMessage>
      ) : (
        <ReceivedMessage user={support} timeCollapsed={timeCollapsed}>
          {message.isImage ? (
            <img src={message.message} crossOrigin="anonymous" alt="Received" className="max-w-56 min-w-16 w-full" />
          ) : (
            message.message
          )}
        </ReceivedMessage>
      )}
    </React.Fragment>
  );
};

export default MessageCard;
