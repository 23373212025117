import { useRef, useEffect, useContext, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { upload } from '../../APIs/UploadAPIs';
import ToastContext from '../../Context/ToastContext';
import { useSocket } from '../../Context/SupportChatContext';

const initialState = {
  socket: null,
  messages: [],
  maxMessages: 0,
  newMessage: '',
  file: null,
  chat: null,
  user: null,
  support: null,
  isNavigating: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SOCKET':
      return {
        ...state,
        socket: action.payload,
      };
    case 'SET_MESSAGES':
      return {
        ...state,
        messages: action.payload,
      };
    case 'SET_MAX_MESSAGES':
      return {
        ...state,
        maxMessages: action.payload,
      };
    case 'PUSH_MESSAGE':
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };

    case 'SET_NEW_MESSAGE':
      return {
        ...state,
        newMessage: action.payload,
      };
    case 'SET_FILE':
      return {
        ...state,
        file: action.payload,
      };
    case 'SET_CHAT':
      return {
        ...state,
        chat: action.payload,
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'SET_SUPPORT':
      return {
        ...state,
        support: action.payload,
      };

    case 'SET_NAVIGATING':
      return {
        ...state,
        isNavigating: action.payload,
      };
    default:
      return state;
  }
};
function useSupportChat({ id }) {
  const { addToasts } = useContext(ToastContext);
  const [data, dispatch] = useReducer(reducer, initialState);
  const socketContext = useSocket();
  const inputRef = useRef(null);
  const windowRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (!socketContext) {
      return;
    }
    const socket = socketContext;
    console.log(socket);

    socket.on('error', error => {
      addToasts({
        type: 'danger',
        body: error?.message || 'Something went wrong',
      });
    });
    dispatch({ type: 'SET_SOCKET', payload: socket });
    socket.emit('join-support', id);
    const handleSupportJoined = data => {
      console.log('sasdas', data);
      let messages = data.chat.messages;
      messages.push({
        message: data.message,
        sender: data.chat.supportId,
        timestamp: new Date(),
      });

      dispatch({ type: 'SET_MESSAGES', payload: messages });
      dispatch({ type: 'SET_CHAT', payload: data.chat });
      const user = data.chat.users.find(user => user.user_id === data.chat.userId);
      dispatch({ type: 'SET_USER', payload: user });
      const support = data.chat.users.find(user => user.user_id === data.chat.supportId);
      dispatch({ type: 'SET_SUPPORT', payload: support });
    };
    socket.on('support-joined', handleSupportJoined);
    // socket.on('chat-updated', data => {
    //   console.log(data);
    // });
    // message-added
    const handleNewMessage = data => {
      dispatch({ type: 'PUSH_MESSAGE', payload: data.message });
    };
    socket.on('message-added', handleNewMessage);
    // on chat-closed
    return () => {
      if (socketContext) {
        socketContext.off('message-added', handleNewMessage);
        socketContext.off('support-joined', handleSupportJoined);
      }
    };
  }, [socketContext]);

  const handleSendMessage = async () => {
    if (data.newMessage === '') return;
    data.socket.emit('add-message', {
      chatId: data.chat._id,
      text: data.newMessage,
      isImage: false,
    });

    dispatch({ type: 'SET_NEW_MESSAGE', payload: '' });
    inputRef.current.focus();
  };

  const handleChange = e => {
    dispatch({ type: 'SET_NEW_MESSAGE', payload: e.target.value });
  };

  const handleBack = () => {
    data.socket?.emit('end-chat', id);
    if (data.isNavigating) return;
    navigate(-1);
    dispatch({ type: 'SET_NAVIGATING', payload: true });
  };

  const handleUploadImage = async e => {
    try {
      const file = e.target.files[0];
      // FILE IS IMAGE ONLY AND MAX SIZE IS 5MB
      if (!file.type.includes('image')) {
        addToasts({
          type: 'danger',
          body: 'Only images are allowed',
        });
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        addToasts({
          type: 'danger',
          body: 'Image size should be less than 5MB',
        });
        return;
      }

      const formData = new FormData();
      formData.append('files', file);
      const res = await upload(formData);
      const url = res.data?.fileLinks[0];
      if (!url) {
        addToasts({
          type: 'danger',
          body: 'Error while uploading file',
        });
        return;
      }
      data.socket.emit('add-message', { chatId: data.chat._id, text: url, isImage: true });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    data,
    inputRef,
    windowRef,
    handleSendMessage,
    handleChange,
    handleBack,
    handleUploadImage,
  };
}

export default useSupportChat;
