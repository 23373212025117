import React from 'react';
import { FaLocationDot } from 'react-icons/fa6';
function AddressCard({ id, address }) {
  return (
    <div id={id}>
      <div className="flex flex-row gap-2 mt-2 px-2">
        <span className="text-blue-500 mt-1">
          <FaLocationDot />{' '}
        </span>
        <ul className="text-sm text-gray-600">
          <li className="font-bold">
            {address.address} - {address.street},
          </li>
          <li>
            Building: <span className="font-bold">{address.building}</span>,
          </li>
          <li>
            Floor: <span className="font-bold">{address.floor}</span>,
          </li>
          <li>
            Flat: <span className="font-bold">{address.flat}</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default AddressCard;
