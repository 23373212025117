import React from 'react';
import filledStar from '../Icons/star-fill.svg';
import emptyStar from "../Assets/star-empty'.svg";

const ReviewCard = ({ review }) => {
  return (
    <div className="reviewCard">
      <div className="flex justify-start items-center gap-x-3">
        <div className="flex justify-start items-center gap-x-3">
          <img className="h-12 w-12 rounded-full" src={review.user.avatar} alt="User" />
          <h3>
            {review.user.first_name} {review.user.last_name}
          </h3>
        </div>
        <div className="flex justify-start items-center gap-x-3">
          <span>{review.rating}</span>
          <img src={filledStar} alt="Rating" height={25} width={25} />
        </div>
      </div>
      <div className="flex justify-start items-center gap-x-3">
        <p>{review.review_text}</p>
        <span>{review.createdAt}</span>
      </div>
    </div>
  );
};

export default ReviewCard;
