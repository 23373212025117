import { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ToastContext from '../Context/ToastContext';
import { getCourse, getCourseContent } from '../APIs/CourseAPIs';
import config from '../Config/Config';

const CourseHook = () => {
  const {
    state: { courseId },
  } = useLocation();

  const navigate = useNavigate();
  const { addToasts } = useContext(ToastContext);

  const [data, setData] = useState({});
  const [courceContent, setCourceContent] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchCourseData = () => {
    setLoading(true);
    getCourse(courseId)
      .then(async res => {
        if (res.status === 200) {
          const { course } = res.data.data;
          setData(course);
          const courceContentResponse = await getCourseContent(courseId);
          setCourceContent(courceContentResponse.data.data);
        }
      })
      .catch(err => {
        if (config.MODE === 'development') console.error(err);
        addToasts({
          type: 'danger',
          body: `${err.response.data?.msg || 'something went wrong, please try again later'}`,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // fetch course data
  useEffect(() => {
    fetchCourseData();
    // eslint-disable-next-line
  }, []);

  return [data, courceContent, loading, navigate];
};

export default CourseHook;
