import { useNavigate } from 'react-router-dom';
import Sidebar from '../Components/Sidebar';
import io from 'socket.io-client';
import '../Styles/Support.css';
import { useState, useReducer, useEffect } from 'react';
import { getSession } from '../Utils/SessionUtils';
import { socketURL } from '../Constants/baseURL';
import { useSocket } from '../Context/SupportChatContext';
import cursor from '../Assets/arrowframe.png';
import ChatCard from '../Components/Support/ChatCard';

const initialState = {
  chats: [],
  chatsCount: 0,
  socket: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SOCKET':
      return {
        ...state,
        socket: action.payload,
      };
    case 'SET_CHATS':
      return {
        ...state,
        chats: action.payload,
        chatsCount: action.payload.length,
      };
    default:
      return state;
  }
};

export default function Support() {
  const [data, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const socketContext = useSocket();
  useEffect(() => {
    if (socketContext) {
      dispatch({ type: 'SET_SOCKET', payload: socketContext });
    }
  }, [socketContext]);

  useEffect(() => {
    const socket = data.socket;
    if (socket) {
      const handleQueuedChats = data => {
        const chats = data.chats;
        console.log(chats);
        dispatch({ type: 'SET_CHATS', payload: chats });
      };

      socket.emit('get-queue');
      socket.on('queued-chats', handleQueuedChats);

      // Cleanup function to remove the event listener
      return () => {
        if (socket) socket.off('queued-chats', handleQueuedChats);
      };
    }
  }, [data.socket]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleStartChat = () => {
    if (data.chatsCount > 0) {
      // navigate to support/chat and use the first chat _id to make the page dynamic
      navigate(`/support/chat/${data.chats[0]._id}`);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="w-full h-full flex flex-col pl-24 pt-5 pr-10">
        <header className="flex justify-between items-center w-full">
          <div className="flex items-center bg-white rounded-lg justify-start p-4 gap-x-4 w-full">
            <img onClick={handleBack} className="cursor-pointer w-12 h-12" src={cursor} alt="cursor" />
            <h1 className="text-3xl font-bold">Support Chat</h1>
          </div>
        </header>
        {/* this section will have the queued chats and the counter also the first chat should be clickable and */}
        <main className="flex flex-col gap-4 mt-4">
          <div className="flex items-center justify-between bg-white p-4 rounded-lg shadow-md">
            <h3 className="text-[#9ca3af] font-bold text-xl">Queued chats({data?.chatsCount})</h3>
            <button
              onClick={handleStartChat}
              className={`
              text-white font-bold px-4 py-2 rounded-lg
              transition duration-200 ease-in-out
              ${
                data?.chatsCount === 0
                  ? 'cursor-not-allowed bg-[#9ca3af] hover:bg-[#9ca3af]'
                  : 'cursor-pointer bg-[#3b82f6] hover:bg-[#2563eb]'
              }
            `}
            >
              Start chat
            </button>
          </div>
          <section className="flex flex-col gap-4">
            {data?.chats?.map(chat => (
              <ChatCard key={chat._id} chat={chat} />
            ))}
          </section>
        </main>
      </div>
    </>
  );
}
