import React from 'react';
import convertDateToDateString from '../../Utils/convertDateToDateString';
import FullNameConcat from '../../Utils/FullNameConcat';
export default function InvestCard({ invest, lastVCCPrice, type }) {
  return (
    <div key={invest.id} className="flex justify-between items-center border-b border-gray-200 py-2">
      <div className="flex gap-x-4">
        <img src={invest.user.avatar} alt="avatar" className="w-12 h-12 rounded-full" />
        <div className="flex flex-col">
          <span className="text-lg font-semibold">{FullNameConcat(invest.user)}</span>
          <span className="text-sm text-gray-500">{invest.user.username}</span>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex items-center justify-between gap-x-2">
          <span className="text-lg font-semibold">{invest.amount} Shares</span>
          <span className="text-md text-gray-500">{invest.remaining} Remaining shares</span>
        </div>
        <span className="text-sm text-gray-500">{convertDateToDateString(invest.createdAt)}</span>
      </div>
      {type === 'purchase' ? (
        <div className="flex flex-col items-start">
          <span className="text-lg font-semibold">Current value: {invest.amount * lastVCCPrice}</span>
          <span className="text-sm text-gray-500">Value at the time of purchase: {invest.amount * invest.price}</span>
        </div>
      ) : (
        <div className="flex flex-col items-start">
          <div className="flex items-center justify-start gap-x-2">
            <span className="text-lg font-semibold">
              Sell value:
              {invest.amount * invest.price}
            </span>
            <span className="text-md text-gray-500">
              Difference: {invest.amount * invest.price - invest.amount * invest.vcc.price}
            </span>
          </div>
          <span className="text-sm text-gray-500">
            Value at the time of purchase: {invest.amount * invest.vcc.price}
          </span>
        </div>
      )}
    </div>
  );
}
