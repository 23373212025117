import React from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

export default function Pagination({ page, totalPages, handlePageChange }) {
  return (
    <div className="flex justify-center my-10 w-full">
      <button
        className={`${page !== 0 ? 'bg-blue-500' : 'bg-gray-400'} text-white rounded-lg px-4 py-2 mr-2`}
        onClick={() => handlePageChange(Math.max(page - 1, 0))}
        disabled={page === 0}
      >
        <BiChevronLeft />
      </button>
      <button
        className={`${page <= totalPages - 1 ? 'bg-blue-500' : 'bg-gray-400'} text-white rounded-lg px-4 py-2 mr-2`}
        onClick={() => handlePageChange(page === totalPages - 1 ? page : page + 1)}
        disabled={page >= totalPages - 1}
      >
        <BiChevronRight />
      </button>
    </div>
  );
}
