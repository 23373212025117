import edit from '../Assets/Frame 48095608.png';
import add from '../Assets/Frame 48095608 (1).png';

import LoadingSpinner from '../Components/LoadingSpinner';
import Layout from '../Components/_common/Layout';
import Pagination from '../Components/_common/Pagination';
import FullNameConcat from '../Utils/FullNameConcat';
import useLoans from '../Hooks/useLoans';

export default function Loans() {
  const { page, totalPages, handlePageChange, loading, loanData, loanRequests, navigate } = useLoans();

  return (
    <Layout
      title={'Loans'}
      Action={
        <img
          src={add}
          alt="Add Loan"
          width={40}
          height={40}
          onClick={() => navigate('/loans/addloan')}
          style={{ cursor: 'pointer' }}
        />
      }
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <section>
            <section className=" bg-white rounded-3xl my-5 p-5 space-y-2 ">
              <div className=" flex items-start justify-between">
                <h2 className="text-2xl font-semibold">Loans requests</h2>
                <button
                  className="text-dark_gray"
                  onClick={() => navigate('/loans/loansrequests')}
                  style={{ cursor: 'pointer' }}
                >
                  See all
                </button>
              </div>
              <section className="grid grid-cols-1 md:grid-cols-3 gap-3">
                {loanRequests?.map(loanRequest => (
                  <article
                    className="bg-gray-200 rounded-2xl flex items-center justify-between p-3"
                    key={loanRequest.id}
                  >
                    <div className="flex items-center gap-2">
                      <figure>
                        <img
                          src={loanRequest.user.avatar}
                          height={50}
                          width={40}
                          className="rounded-full"
                          alt="User Avatar"
                        />
                      </figure>
                      <div>
                        <p className="text-gray-500">From</p>
                        <h3>{FullNameConcat(loanRequest.user)}</h3>
                        <p className="text-gray-500">{Math.round((loanRequest.loan.amount / 12) * 1000) / 1000} EGP</p>
                      </div>
                    </div>
                    <button
                      onClick={e =>
                        navigate('/loans/loansrequests/loansrequest', {
                          state: { id: e.target.id },
                        })
                      }
                      id={loanRequest.id}
                      style={{ cursor: 'pointer' }}
                      className="text-xl font-semibold text-gray-500"
                    >
                      Details..
                    </button>
                  </article>
                ))}
              </section>
            </section>

            {!loanData.length ? (
              <h2 className="text-2xl my-10 w-full text-center capitalize">No loans Found</h2>
            ) : (
              <section className="grid grid-cols-1 md:grid-cols-3 gap-3">
                {loanData?.map(loan => (
                  <article className="p-5 bg-white rounded-3xl" key={loan.id}>
                    <div className="flex items-center justify-between">
                      <p className="value2">{loan.amount}</p>
                      <img
                        src={edit}
                        width={40}
                        height={40}
                        alt="Edit Loan"
                        id={loan.id}
                        onClick={e =>
                          navigate('/loans/editloan', {
                            state: { id: e.target.id },
                          })
                        }
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                    <p className="text-md text-gray-500">12 installments over a year</p>
                    <h3 className="text-xl font-medium text-gray-400">About</h3>
                    <p className="capitalize">{loan.about}</p>
                    <h3 className="text-xl font-medium text-gray-400">Conditions</h3>
                    <div className="capitalize">
                      <p>User must have a minimum of {loan.preAmount} Credit in his account to be able to</p>
                      <p>{loan.accountPeriod} days must pass from the creation of the account</p>
                    </div>
                  </article>
                ))}
              </section>
            )}
          </section>

          <Pagination page={page} totalPages={totalPages} handlePageChange={handlePageChange} />
        </>
      )}
    </Layout>
  );
}
