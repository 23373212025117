import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { getSession } from '../Utils/SessionUtils';
import { socketURL } from '../Constants/baseURL';
// Create a context
const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Initialize socket connection
    const newSocket = io(`${socketURL}supportChat`, {
      transports: ['websocket'],
      query: {
        token: getSession(),
      },
    });

    setSocket(newSocket);

    return () => {
      console.log('Disconnecting socket');
      newSocket.disconnect();
    };
  }, []);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
