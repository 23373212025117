import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ToastContext from '../Context/ToastContext';
import { getPosts, searchPosts } from '../APIs/PostAPIs';

const usePosts = () => {
  const navigate = useNavigate();
  const { addToasts } = useContext(ToastContext);
  const [loading, setLoading] = useState(true);
  const [input, setInput] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [data, setData] = useState([]);
  const [maxCount, setMaxCount] = useState(20);

  const handleSelectPost = postId => {
    navigate('/roommate/post', { state: { id: postId } });
  };

  const handleInputChange = e => {
    setInput(e.target.value);
  };

  const handleSearch = async () => {
    try {
      const searchQuery = {
        skip: 0,
        place: 'ROOMMATE',
        text: input,
      };

      const response = await searchPosts(searchQuery);
      if (!response.ok) {
        throw new Error('Network error');
      }

      const { posts, numberOfPosts } = await response.json();
      setMaxCount(numberOfPosts);
      setSearchData(posts);
    } catch (err) {
      addToasts({ type: 'danger', body: err.message || 'Unexpected error' });
    }
  };

  const fetchPosts = async query => {
    try {
      const response = await getPosts(query);

      const { posts } = response.data.data;
      if (posts.length === 0) {
        setMaxCount(data.length);
      }
      setData(prevData => [...prevData, ...posts]);
      setLoading(false);
    } catch (err) {
      addToasts({ type: 'danger', body: err.message || 'Unexpected error' });
    }
  };

  useEffect(() => {
    fetchPosts({ filter: 'date', category: 'ROOMMATE', skip: 0 });
  }, []);

  useEffect(() => {
    if (input.length>1) handleSearch();
  }, [input]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
        if (loading || data.length <= maxCount) return;
        handleMoreSearch();
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, data, maxCount]);

  const handleMoreSearch = async () => {
    try {
      const searchQuery = {
        skip: searchData.length,
        place: 'ROOMMATE',
        text: input,
      };

      const response = await searchPosts(searchQuery);
      if (!response.ok) {
        throw new Error('Network error');
      }

      const { posts, numberOfPosts } = await response.json();
      setMaxCount(numberOfPosts);
      setSearchData(prevData => [...prevData, ...posts]);
    } catch (err) {
      addToasts({ type: 'danger', body: err.message || 'Unexpected error' });
    }
  };

  return {
    loading,
    input,
    setInput,
    searchData,
    data,
    handleInputChange,
    handleSearch,
    handleSelectPost,
  };
};

export default usePosts;
