import { useState } from 'react';
import Sidebar from '../Components/Sidebar';
import TopCard from '../Components/TopCard';
import '../Styles/AddSection.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { addWeekContent, updateWeekContent } from '../APIs/CourseAPIs';
import config from '../Config/Config';
export default function AddEditSection() {
  const {
    state: { data },
  } = useLocation();

  const navigate = useNavigate();

  const [title, setTitle] = useState(data.title ? data.title : '');

  return (
    <div className="addSection">
      <Sidebar></Sidebar>
      <div className="addSectionContent">
        <TopCard title="Add course / Add section"></TopCard>
        <div className="titleOfSection">
          <label htmlFor="titleOfSectionInput">Title of section</label>
          <input
            id="titleOfSectionInput"
            placeholder="Title of section"
            value={title}
            onChange={e => {
              setTitle(e.target.value);
            }}
          ></input>
          <button
            onClick={async e => {
              e.preventDefault();
              try {
                if (!data.title) {
                  await addWeekContent({
                    title,
                    course_id: data.courseID,
                  });
                } else {
                  await updateWeekContent(data.contentID, { title });
                }
              } catch (err) {
                if (config.MODE === 'development') console.error(err);
              }
              navigate(-1);
            }}
            disabled={!title.trim()}
            className="addButton"
          >
            {data.contentID ? 'Edit' : 'Add'}
          </button>
        </div>
      </div>
    </div>
  );
}
