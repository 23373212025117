import Sidebar from '../Components/Sidebar';
import '../Styles/OrdersToDo.css';
import arrowFrame from '../Assets/arrowframe.png';
import '../Components/Searchbar.css';
import LoadingSpinner from '../Components/LoadingSpinner';
import OrderCard from '../Components/Pages/OrdersToDoPage/OrderCard/OrderCard.js';
import OrdersToDoHook from '../Hooks/OrdersToDoHook.js';

export default function OrdersToDo() {
  const [
    input,
    chefStatus,
    loading,
    data,
    searchData,
    handleInputChange,
    ordersStatusNavigationSelectHandler,
    navigate,
    handleSelectOrder,
  ] = OrdersToDoHook();
  
  return (
    <>
      <section>
        <Sidebar></Sidebar>
        <div className="SAS">
          <div className="content">
            <div className="search">
              <div className="searchbutton"></div>
              <input placeholder="Search" value={input} onChange={handleInputChange}></input>
            </div>
          </div>
        </div>
        <div className="topCardTwo">
          <div className="topCardTwoFirstLine">
            <img
              src={arrowFrame}
              alt="arrow"
              width={45}
              height={45}
              onClick={() => navigate(-1)}
              style={{ cursor: 'pointer' }}
            ></img>
            <div>Orders</div>
          </div>
          <div className="topCardTwoSecondRow">
            <span
              className={`${chefStatus === 'pending' ? 'selected' : ''}`}
              id="pending"
              onClick={ordersStatusNavigationSelectHandler}
            >
              To do (Not accepted)
            </span>
            <span
              className={`${chefStatus === 'preparing' ? 'selected' : ''}`}
              id="preparing"
              onClick={ordersStatusNavigationSelectHandler}
            >
              Doing
            </span>
            <span
              className={`${chefStatus === 'delivered' ? 'selected' : ''}`}
              id="delivered"
              onClick={ordersStatusNavigationSelectHandler}
            >
              Done
            </span>
            <span
              className={`${chefStatus === 'rejected' ? 'selected' : ''}`}
              id="rejected"
              onClick={ordersStatusNavigationSelectHandler}
            >
              Rejected
            </span>
          </div>
        </div>
        {loading ? (
          <LoadingSpinner />
        ) : !searchData.length && !data.length ? (
          <h2 className="text-2xl my-10 w-full text-center">No Orders Found</h2>
        ) : (
          <div className="ordersToDoCards">
            {input
              ? searchData?.map(order => (
                  <OrderCard key={order.id} order={order} handleSelectOrder={handleSelectOrder} />
                ))
              : data?.map(order => <OrderCard key={order.id} order={order} handleSelectOrder={handleSelectOrder} />)}
          </div>
        )}
      </section>
    </>
  );
}
