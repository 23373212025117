import React from 'react';
import '../Styles/Wallet.css';
import useProfileWallet from '../Hooks/useProfileWallet';
import Layout from '../Components/_common/Layout';
import FullNameConcat from '../Utils/FullNameConcat';
import TransactionCard from '../Components/Profile/Wallet/TransactionCard';
export default function Wallet() {
  const {
    handleAddBalance,
    handleWithdrawBalance,
    userData,
    wallet,
    transactions,
    addValue,
    withdrawValue,
    setAddValue,
    setWithdrawValue,
  } = useProfileWallet();
  return (
    <Layout
      title={'Wallet'}
      Action={
        <div className="flex justify-end gap-x-1 w-full">
          <img src={userData?.user?.avatar} alt="avatar" className="w-12 rounded-full" />
          <p className="flex flex-col">
            <span className="font-bold text-lg">{userData.user && FullNameConcat(userData.user)}</span>
            <span>{userData?.user?.username}</span>
          </p>
        </div>
      }
    >
      <main className="grid grid-cols-2 my-4 gap-2">
        <section className="flex flex-col gap-y-4 ">
          <div className="bg-white rounded-lg p-4">
            <h2 className="text-[#9ca3af] font-semibold text-lg mb-2">Balance</h2>
            <p className="font-bold text-xl">{wallet.balance} EGP</p>
          </div>
          <div className="bg-white rounded-lg p-4 max-h-96 overflow-y-scroll">
            <h2 className="text-[#9ca3af] font-semibold text-lg mb-2">Wallet history ({transactions?.length})</h2>
            {transactions?.map(transaction => (
              <TransactionCard key={transaction.id} transaction={transaction} user_id={userData.user.id} />
            ))}
          </div>
        </section>

        <section className="flex flex-col gap-y-4">
          <div className="bg-white rounded-lg p-4 flex flex-col gap-y-2">
            <label htmlFor="addValue" className="font-extrabold text-2xl mb-2">
              Add EGP
            </label>
            <input
              type="number"
              name="addValue"
              id="addValue"
              value={addValue}
              onChange={e => setAddValue(e.target.value)}
              className="border-2 border-gray-300 rounded-lg p-4 w-full"
              placeholder="Amount (EGP)"
            />
            <label name="reasonOfAdd" id="reasonOfAdd" className="font-extrabold text-2xl mb-2">
              Reason of add
            </label>
            <input
              className="border-2 border-gray-300 rounded-lg p-4 w-full"
              type="text"
              name="reasonOfAdd"
              id="reasonOfAdd"
              placeholder="Reason of add"
            />
            <button
              onClick={handleAddBalance}
              className="bg-[#3b82f6] text-white font-bold py-4 px-4 rounded-lg mt-2"
              disabled={addValue < 0}
            >
              Add
            </button>
          </div>
          <div className="bg-white rounded-lg p-4 flex flex-col gap-y-2">
            <label htmlFor="withdrawValue" className="font-extrabold text-2xl mb-2">
              Withdraw EGP
            </label>
            <input
              type="number"
              name="withdrawValue"
              id="withdrawValue"
              value={withdrawValue}
              onChange={e => setWithdrawValue(e.target.value)}
              placeholder="Amount (EGP)"
              className="border-2 border-gray-300 rounded-lg p-4 w-full"
            />
            <button
              disabled={withdrawValue < 0}
              onClick={handleWithdrawBalance}
              className="bg-[#3b82f6] text-white font-bold py-4 px-4 rounded-lg mt-2"
            >
              Withdraw
            </button>
          </div>
        </section>
      </main>
    </Layout>
  );
}
