import '../Styles/University.css';
import location from '../Assets/location.svg';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from '../Components/LoadingSpinner';
import Layout from '../Components/_common/Layout';
import EditButton from '../Components/Hub/EditButton';
import FullNameConcat from '../Utils/FullNameConcat';
import useUniversity from '../Hooks/useUniversity';
export default function University() {
  const {
    state: { universityId },
  } = useLocation();

  const { data, students, loading, lastStudentRef, navigate } = useUniversity(universityId);

  return (
    <Layout
      title={'University'}
      Action={<EditButton onClick={() => navigate('/universities/adduniversity', { state: { universityId } })} />}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="mt-4 space-y-6">
          <section className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-md h-full">
              <h2 className="text-2xl font-bold mb-2">{data.name}</h2>
              <div className="flex items-center justify-between">
                <span className="text-gray-600">{data.studentsCount} Students from app</span>
                <span className="text-gray-600">{data.city}</span>
              </div>
              <div className="flex items-center mt-4">
                <a
                  className="mr-2 text-blue-500 hover:underline"
                  href={data.location}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Go to Location
                </a>
                <img
                  onClick={() => window.open(`${data.location}`, '_blank')}
                  className="w-6 h-6 cursor-pointer"
                  crossOrigin="anonymous"
                  src={location}
                  alt="location"
                />
              </div>
              <a className="text-blue-500 hover:underline mt-2 block" target="_blank" href={data.link} rel="noreferrer">
                Website link
              </a>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md h-full">
              <h3 className="text-xl font-semibold mb-4">About</h3>
              <p>{data.about}</p>
            </div>
          </section>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">Information about university</h3>
              <p className="mb-2">
                <span className="font-bold">Founder: </span>
                <span>{data.founder}</span>
              </p>
              <p className="mb-2">
                <span className="font-bold">Genre: </span>
                <span>{data.genre}</span>
              </p>
              <p className="mb-2">
                <span className="font-bold">Colleges: </span>
                <span>{data.colleges}</span>
              </p>
              <p className="mb-2">
                <span className="font-bold">Professors: </span>
                <span>{data.professors}</span>
              </p>
              <p className="mb-2">
                <span className="font-bold">Graduate students: </span>
                <span>{data.graduates}</span>
              </p>
              <p className="mb-2">
                <span className="font-bold">University Type: </span>
                <span>{data.type}</span>
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">University photo</h3>
              <img className="w-full h-auto rounded" crossOrigin="anonymous" src={data.photo} alt="University" />
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4">Students</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 overflow-y-auto max-h-96">
              {students?.map((student, index) => (
                <div
                  key={student.user_id}
                  ref={index === students.length - 1 ? lastStudentRef : null}
                  onClick={() =>
                    navigate('/profiles/profile', {
                      state: { userId: student.user_id },
                    })
                  }
                  className="cursor-pointer p-4 bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition duration-200"
                >
                  <div className="flex items-center">
                    <img className="w-16 h-16 rounded-full mr-4 object-cover" src={student.avatar} alt="avatar" />
                    <div className="flex flex-col">
                      <p className="font-semibold">{FullNameConcat(student)}</p>
                      <p className="font-medium">{student.username}</p>
                    </div>
                  </div>
                  <span className="text-blue-500 hover:underline mt-2 block">Visit profile</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
