import React from 'react';
import FullNameConcat from '../../Utils/FullNameConcat';
function ItemSellerCard({ id, user }) {
  return (
    <div id={id} className="flex justify-start items-center gap-x-2 mt-2 px-2">
      <img id={id} src={user.avatar} alt="User" className="rounded-full w-12 h-12" />
      <span id={id} className="text-gray-600 font-semibold">
        {FullNameConcat(user)}
      </span>
    </div>
  );
}

export default ItemSellerCard;
