import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../Components/Sidebar';
import LoadingSpinner from '../Components/LoadingSpinner';
import ToastContext from '../Context/ToastContext';
import useScrollUniversity from '../Hooks/useScrollUniversity.js';
import useSearchUniversity from '../Hooks/useSearchUniversity.js';
import '../Styles/Universities.css';
import edit4 from '../Assets/Frame 48095608 (1).png';
import { UniversityCard } from '../Components/UniversityCard.js';
import SearchBar from '../Components/SearchBar.js';
import cursor from '../Assets/arrowframe.png';

const Universities = () => {
  const navigate = useNavigate();
  const [input, setInput] = useState('');
  const { loading, searchData, data, maxCount, fetchMoreData, handleSearch, handleInputChange } =
    useSearchUniversity(input);
  useScrollUniversity(fetchMoreData, loading, data.length, maxCount, input);

  const handleSelectUniversity = e => {
    navigate('/universities/university', {
      state: { universityId: e.target.id },
    });
  };

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <main className="universities">
          <Sidebar />
          <section className="w-full h-full flex flex-col pl-24 pt-5 pr-10">
            <SearchBar handleSearch={handleSearch} />
            <header className="flex justify-between items-center w-full bg-white rounded-lg p-4">
              <div className="flex items-center justify-start gap-x-4 w-full">
                <img onClick={handleBack} className="cursor-pointer w-12 h-12" src={cursor} alt="cursor" />
                <h1 className="text-3xl font-bold">Universities</h1>
              </div>
              <img
                src={edit4}
                alt="Edit Icon"
                width={40}
                height={40}
                onClick={() => navigate('/universities/adduniversity')}
                style={{ cursor: 'pointer' }}
              />
            </header>
            {!searchData.length && !data.length ? (
              <h2 className="text-2xl my-10 w-full text-center">No University Found</h2>
            ) : (
              <div className="flex flex-wrap gap-4 mt-4">
                {input
                  ? searchData?.map(university => (
                      <UniversityCard
                        key={university.id}
                        university={university}
                        handleSelectUniversity={handleSelectUniversity}
                      />
                    ))
                  : data?.map(university => (
                      <UniversityCard
                        key={university.id}
                        university={university}
                        handleSelectUniversity={handleSelectUniversity}
                      />
                    ))}
              </div>
            )}
          </section>
        </main>
      )}
    </>
  );
};

export default Universities;
