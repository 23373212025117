import Sidebar from '../Components/Sidebar';
import TopCard from '../Components/TopCard';
import '../Styles/Meal.css';
import location from '../Assets/location.svg';
import LoadingSpinner from '../Components/LoadingSpinner';
import BasicModalTwo from '../Components/ModalTwo';
import MealHook from '../Hooks/MealHook';
import convertTimeDecimalToTimeFormat from '../Utils/convertTimeDecimalToTimeFormat';

export default function Meal() {
  const [data, loading, onAccept, onReject] = MealHook();
  return (
    <>
      <div>
        <Sidebar></Sidebar>
        <TopCard title="Meal"></TopCard>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div className="mealCards pb-4">
            <div className="titleOfMeal shadow-md">
              <h1 className="meal-title">{data.title}</h1>
              <div className="priceDelivery">
                <span>{data.price} EGP</span>
                <p className="text-dark_gray">40 EGP delivery fee</p>
              </div>
              <div className="nameProfile">
                <img src={data.chef.avatar} className="w-10 h-10 rounded-full mr-2" alt="chef-avatar"></img>
                <p>{data.chef.first_name}</p>
              </div>
              <p className="text-dark_gray">{data.type}</p>
              <div className="text-dark_gray">
                Meal will be reached after:{' '}
                <span className="text-blue-500">
                  {convertTimeDecimalToTimeFormat(data.deliveryTime + data.preparationTime)}
                </span>
              </div>
            </div>
            <div className="mealIngredients shadow-md">
              <h5 className="text-dark_gray">Ingredients</h5>
              <span>{data.ingredients}</span>
            </div>
            <div className="mealNotes shadow-md">
              <h5 className="text-dark_gray">Notes</h5>
              <span>{data.notes}</span>
            </div>
            <div className="mealLocation shadow-md">
              <div className="location">
                <p>{data.address.address}</p>
                <img src={location} alt="location"></img>
              </div>
              <div className="locationInfo">
                <p className="text-dark_gray">Meal will be received:</p>
                <span>{data.receive_method}</span>
              </div>
              <div className="locationInfo">
                <p className="text-dark_gray">Flat:</p>
                <span>{data.address.flat}</span>
              </div>
              <div className="locationInfo">
                <p className="text-dark_gray">Floor:</p>
                <span>{data.address.floor}</span>
              </div>
              <div className="locationInfo">
                <p className="text-dark_gray">Location:</p>
                <span>{data.address.location}</span>
              </div>
            </div>
            <div className="mealOrderInfo shadow-md">
              <div className="orderInfo">
                <p className="text-dark_gray">How long to prepare your meal?:</p>
                <span className="text-blue-500">{convertTimeDecimalToTimeFormat(data.preparationTime)}</span>
              </div>
              <div className="orderInfo">
                <p className="text-dark_gray">How long for your meal to be delivered?:</p>
                <span className="text-blue-500">{convertTimeDecimalToTimeFormat(data.deliveryTime)}</span>
              </div>
              <div className="orderInfo">
                <p className="text-dark_gray">Price(EGP):</p>
                <span>{data.price}</span>
              </div>
              <div className="orderInfo">
                <p className="text-dark_gray">Delivery fee:</p>
                <span>{data.deliveryFee}</span>
              </div>
            </div>

            <div className="mealStatus flex flex-col shadow-md">
              <h3 className="font-semibold text-xl text-gray-800 w-full text-center">
                Meal status is {data.is_accepted ? 'Accepted' : data.is_rejected ? 'Rejected' : 'Pending'}
              </h3>
              <div className="flex justify-between gap-4">
                <BasicModalTwo
                  question="Are you sure you want to accept this order?"
                  buttonName="Accept"
                  onAccept={onAccept}
                ></BasicModalTwo>
                <BasicModalTwo
                  question="Are you sure you want to reject this order?"
                  buttonName="Reject"
                  onAccept={onReject}
                ></BasicModalTwo>
              </div>
            </div>

            <div className="mealPhotoCard shadow-md">
              <h3 className="mealPhotoCard-title">Meal photo</h3>
              <img src={data.image} alt="meal" className="w-full h-auto" />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
