import { getSession } from '../Utils/SessionUtils';
import api from './_common/helper';
import { baseURL as BASE_URL } from '../Constants/baseURL';

export async function getVRequests(data) {
  const queryParams = new URLSearchParams(data).toString();
  const url = `/verifications?${queryParams}`;
  return api.get(url);
}
export async function updateStatus(status, data) {
  return api.patch(`/verifications/${data}/status`, { status: status });
}

export async function getVRequest(data) {
  return api.get(`/verifications/${data}`);
}
