import React from 'react';
import Layout from '../Components/_common/Layout';
import useWithdrawRequest from '../Hooks/useWithdrawRequests';
import LoadingSpinner from '../Components/LoadingSpinner';
import FullNameConcat from '../Utils/FullNameConcat';
import convertDateToDateString from '../Utils/convertDateToDateString';

export default function WithdrawRequest() {
  const { loading, data, comment, handleComment, updateRequest } = useWithdrawRequest();

  return (
    <Layout title={'Withdraw Requests'}>
      {loading ? (
        <LoadingSpinner />
      ) : data ? (
        <div className="w-full md:w-1/2 bg-white rounded-3xl my-5 mx-auto p-5">
          <div className="flex justify-between">
            <img className="w-24 h-24 rounded-full m-5" src={data.user.avatar} alt={FullNameConcat(data.user)} />
            <span className="text-black">{convertDateToDateString(data.createdAt)}</span>
          </div>

          <h3 className="text-xl text-gray-500">{FullNameConcat(data.user)}</h3>
          <p className="text-lg text-black">Phone : {data.user.phone_number}</p>
          <p className="text-lg text-black">Amount : {data.amount} EGP</p>
          <label className="mt-8">Comment</label>
          <input
            value={comment}
            onChange={handleComment}
            className="border p-2 rounded-2xl border[1px] w-full"
            placeholder="comment"
          />
          <button
            onClick={() => updateRequest('ACCEPTED')}
            className="my-3 w-full text-white bg-green-800 text-xl p-2 rounded-3xl"
          >
            Accept
          </button>
          <button
            onClick={() => updateRequest('REJECTED')}
            className="w-full text-white bg-red-800 text-xl p-2 rounded-3xl"
          >
            Reject
          </button>
        </div>
      ) : (
        <div className="w-full md:w-1/2 bg-white rounded-3xl my-5 mx-auto p-5">
          <p className="text-center text-lg text-gray-500">No withdraw requests found.</p>
        </div>
      )}
    </Layout>
  );
}
