import '../Styles/Events.css';
import edit4 from '../Assets/Frame 48095608 (1).png';
import LoadingSpinner from '../Components/LoadingSpinner';
import '../Components/Searchbar.css';
import Layout from '../Components/_common/Layout';
import Pagination from '../Components/_common/Pagination';
import useEvents from '../Hooks/useEvents';
import EventCard from '../Components/Events/EventCard';

export default function Events() {
  const {
    loading,
    data,
    input,
    type,
    page,
    totalPages,
    handleSelectEvent,
    handlePageChange,
    handleInputChange,
    navigate,
    handleTypeChange,
  } = useEvents();
  return (
    <Layout
      title={'Events'}
      Action={
        <>
          <input
            className="w-1/3 border p-2 rounded-lg mr-2"
            placeholder="Search"
            value={input}
            onChange={e => {
              handleInputChange(e.target.value);
            }}
          ></input>
          <img
            alt="add event"
            src={edit4}
            width={40}
            height={40}
            onClick={() => navigate('/events/addevent')}
            style={{ cursor: 'pointer' }}
          ></img>
        </>
      }
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div id="courses" className="flex flex-col">
            <div className="bg-white rounded-2xl  p-2 space-x-1 mt-3">
              <div className="text-base md:text-2xl font-medium flex justify-between items-center px-2">
                <span
                  className={`cursor-pointer ${type === 'event' ? 'text-[#116acc]' : ''}`}
                  onClick={() => handleTypeChange('event')}
                >
                  Events
                </span>
                <span
                  className={`cursor-pointer ${type === 'conference' ? 'text-[#116acc]' : ''}`}
                  onClick={() => handleTypeChange('conference')}
                >
                  Conferences
                </span>
                <span
                  className={`cursor-pointer ${type === 'party' ? 'text-[#116acc]' : ''}`}
                  onClick={() => handleTypeChange('party')}
                >
                  Parties
                </span>
              </div>
            </div>

            {!data.length ? (
              <h2 className="text-2xl my-10 w-full text-center capitalize">No {type} Found</h2>
            ) : (
              <>
                <div className="mt-3 grid md:grid-cols-3 gap-6">
                  {data?.map(event => (
                    <EventCard event={event} handleSelectEvent={handleSelectEvent} />
                  ))}
                </div>
                <Pagination page={page} totalPages={totalPages} handlePageChange={handlePageChange} />
              </>
            )}
          </div>
        </div>
      )}
    </Layout>
  );
}
