import LoadingSpinner from '../Components/LoadingSpinner';
import Layout from '../Components/_common/Layout';
import Pagination from '../Components/_common/Pagination';
import FullNameConcat from '../Utils/FullNameConcat';
import useLoanRequests from '../Hooks/useLoanRequests';

export default function LoansRequests() {
  const { navigate, type, loading, page, totalPages, handlePageChange, data, handleTypeChange } = useLoanRequests();

  return (
    <Layout title={'Loans Requests'}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="bg-white rounded-3xl my-5 p-5 space-y-2">
            <div className="flex items-center md:text-xl font-medium mb-8 space-x-5">
              <span
                className={`${type === 'Requests' ? 'text-blue-700 ' : ''} text-center cursor-pointer`}
                onClick={() => handleTypeChange('Requests')}
              >
                Requests
              </span>
              <span
                className={`${type === 'Acceptable requests' ? 'text-blue-700 ' : ''} text-center cursor-pointer`}
                onClick={() => handleTypeChange('Acceptable requests')}
              >
                Acceptable requests
              </span>
              <span
                className={`${type === 'Rejected requests' ? 'text-blue-700 ' : ''} text-center cursor-pointer`}
                onClick={() => handleTypeChange('Rejected requests')}
              >
                Rejected requests
              </span>
            </div>

            {!data.length ? (
              <h2 className="text-2xl my-10 w-full text-center capitalize">No Loans Requests Found</h2>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                {data?.map(loan => (
                  <article className="bg-gray-200 rounded-2xl flex items-center justify-between p-3" key={loan.id}>
                    <div className="flex items-center gap-2">
                      <figure>
                        <img
                          src={loan.user.avatar}
                          height={60}
                          width={60}
                          style={{ borderRadius: '50%' }}
                          alt="User Avatar"
                        />
                      </figure>
                      <div>
                        <p className="text-gray-500">From</p>
                        <h3 className="capitalize">{FullNameConcat(loan.user)}</h3>
                        <p className="text-gray-500">{Math.round((loan.loan.amount / 12) * 1000) / 1000} EGP</p>
                      </div>
                    </div>
                    <button
                      onClick={e =>
                        navigate('/loans/loansrequests/loansrequest', {
                          state: { id: e.target.id },
                        })
                      }
                      id={loan.id}
                      style={{ cursor: 'pointer' }}
                      className="text-xl font-semibold text-gray-500"
                    >
                      Details..
                    </button>
                  </article>
                ))}
              </div>
            )}
          </div>
          <Pagination page={page} totalPages={totalPages} handlePageChange={handlePageChange} />
        </>
      )}
    </Layout>
  );
}
