import { useContext, useEffect, useRef, useState } from "react"
import { getVersionReq, updateVersionReq } from "../APIs/updateVersionAPIs"
import ToastContext from '../Context/ToastContext';

const UpdateVersionHook = () => {
  
  // define toast context
  const { addToasts } = useContext(ToastContext);

  // define refs
  const currentVersionRef = useRef(null)
  const requiredVersionRef = useRef(null) 

  // define inputs states
  const [inputValues, setInputValues] = useState({
    currentVersion: '',
    requiredVersion: ''
  })

  // define loading state
  const [loading, setLoading] = useState(false)

  // get current and required version
  useEffect(() => {
    getVersionReq()
      .then(res => {
        if (res.status === 200) {
          setInputValues({
            currentVersion: res.data.versionData.currentVersion,
            requiredVersion: res.data.versionData.requiredVersion
          })
        }
      })
      .catch(err => {
        addToasts({
          body: `${err.response.data.message || 'Unexpected error'}`,
          type: 'danger',
        })
      })
      // eslint-disable-next-line
  }, [])

  // input values on change handler
  const inputsOnChangeHandler = (e) => {
    const { name, value } = e.target
    setInputValues(prev => ({ ...prev, [name]: value }))
  }

  // input values on key down handler
  const inputsOnKeyDownHandler = (e) => {
    if (e.key === 'Enter') {
      updateVersionOnclickHandler()
    }
  }

  // update version on click handler
  const updateVersionOnclickHandler = async () => {
    // inputs validations
    if (!inputValues.currentVersion && !inputValues.requiredVersion) {
      addToasts({
        body: 'both fields are required',
        type: 'warning',
      });
      return
    }
    if (!inputValues.currentVersion) {
      currentVersionRef.current.focus()
      addToasts({
        body: 'Please enter current version',
        type: 'warning',
      });
      return
    }
    if (!inputValues.requiredVersion) {
      requiredVersionRef.current.focus()
      addToasts({
        body: 'Please enter required version',
        type: 'warning',
      });
      return
    }
    if (inputValues.currentVersion === inputValues.requiredVersion) {
      addToasts({
        body: 'Current version and required version cannot be same',
        type: 'warning',
      });
      return
    }
    if (!inputValues.currentVersion.match(/^\d\.\d\.\d$/) || !inputValues.requiredVersion.match(/^\d\.\d\.\d$/)) {
      addToasts({
        body: `Version format is invalid, Valid format: x.x.x`,
        type: 'warning',
      });
      return
    }
    // set loading to true to show spinner inside button and disable button
    setLoading(true)
    // update version request
    updateVersionReq(inputValues).then(res => {
      setLoading(false)
      if (res.status === 200) {
        addToasts({
          body: 'Version updated successfully',
          type: 'success',
        });
      }
    }).catch(err => {
      setLoading(false)
      addToasts({
        body: `${err.response.data.message || 'Unexpected error'}`,
        type: 'danger',
      })
    })
  }
  return [inputValues, inputsOnChangeHandler, inputsOnKeyDownHandler, updateVersionOnclickHandler, currentVersionRef, requiredVersionRef, loading]
}

export default UpdateVersionHook