import React from 'react';

function RoommateRequest({ data, navigate }) {
  const handleRoommateClick = postId => {
    navigate('/find_roommate/post', { state: { id: postId } });
  };

  return (
    <div className="homePageFirstCard">
      <div className="homepagecard1contentline1">
        <div>Roommate page request</div>
        <div className="seeAllRoommate" onClick={() => navigate('/roommate')}>
          See all
        </div>
      </div>
      <div className="homePageFirstCardContent">
        {data?.map(post => (
          <div className="homepagecard2content" key={post.post_id}>
            <div className="homepagecard2contentleft">
              <img src={post.user.avatar} width={40} height={40} style={{ borderRadius: '50%' }} alt="image" />
              <div className="fromandname">
                <span>From</span>
                <span>{`${post.user.first_name} ${post.user.last_name}`}</span>
              </div>
            </div>
            <div
              id={post.post_id}
              className="details"
              onClick={e =>
                navigate('/roommate/post', {
                  state: { id: e.target.id },
                })
              }
              style={{ cursor: 'pointer' }}
            >
              See post..
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RoommateRequest;
